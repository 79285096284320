@import '~@Styles/vars';

$zindex-tooltip: 10000;
$zindex-popover: 1001;
$popover-width: 445px;
$popover-shadow: -5px 0 15px 5px $white;
$tooltip-width: 250px;
$tooltip-shadow: -5px 0 15px 5px rgba($black, 0.1);

.popoverContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .popover {
    position: absolute;
    z-index: $zindex-popover;
    display: inline-block;
    min-width: $popover-width;
    padding: 25px 21px;
    background: $white;
    border-radius: $border-radius-toggle;
    box-shadow: 0 4px 23px 4px $shadowColor;

    &:before {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }

    &.top {
      bottom: 100%;
      left: 78%;
      margin-bottom: 15px;
      transform: translateX(-50%);

      &:before {
        bottom: -15px;
        left: calc(50% - 15px);
        border-color: $white $transparent $white $transparent;
        border-width: 15px 15px 0 15px;
      }

      @media (max-width: $mobile) {
        left: 50%;
      }
    }

    &.bottom {
      top: 100%;
      left: 50%;
      margin-top: 15px;
      transform: translateX(-50%);

      &:before {
        top: -15px;
        left: calc(50% - 15px);
        border-color: $white $transparent $white $transparent;
        border-width: 0 15px 15px 15px;
      }
    }

    &.right {
      top: 50%;
      left: 100%;
      margin-left: 15px;
      transform: translateY(-50%);

      &:before {
        top: calc(50% - 15px);
        left: -10px;
        border-top: 10px solid transparent; 
        border-right:10px solid $white;
        border-bottom: 10px solid transparent; 
        border-left: none;
      }
    }

    &.left {
      top: 50%;
      right: calc(100% + 15px);
      transform: translateY(-50%);

      &:before {
        top: calc(50% - 15px);
        left: 100%;
        border-color: $white $transparent $white $transparent;
        border-width: 15px 0 15px 15px;
      }
    }
    @media (max-width: $mobile) {
      min-width: 355px;
    }
  }
}

.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  min-width: $tooltip-width;
  padding: 15px;
  background: $buttonTextColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;

  &:before {
    position: absolute;
    width: 0;
    height: 0;
    border-color:$transparent $transparent $black $transparent;
    border-style: solid;
    content: '';
  }

  &.top {
    bottom: 100%;
    left: 50%;
    margin-bottom: 15px;
    transform: translateX(-50%);

    &:before {
      bottom: -15px;
      left: calc(50% - 15px);
      border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
      border-width: 15px 15px 0 15px;
    }
  }

  &.bottom {
    top: 100%;
    left: 50%;
    margin-top: 15px;
    transform: translateX(-50%);

    &:before {
      top: -15px;
      left: calc(50% - 15px);
      border-width: 0 15px 15px 15px;
    }
  }

  &.right {
    top: 50%;
    left: 100%;
    margin-left: 15px;
    transform: translateY(-50%);

    &:before {
      top: calc(50% - 15px);
      left: -10px;
      border-top: 10px solid transparent; 
      border-right:10px solid $buttonTextColor;
      border-bottom: 10px solid transparent; 
      border-left: none;
    }
  }

  &.left {
    top: 50%;
    right: calc(100% + 15px);
    transform: translateY(-50%);

    &:before {
      top: calc(50% - 15px);
      left: 100%;
      border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
      border-width: 15px 0 15px 15px;
    }
  }

  .text {
    line-height: 24px;
    text-align: left;
  }
}

.tooltipContent {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: $fontSize-14;
}
