@import '~@Styles/vars';

.caseBox{
  padding: 25px;
  border: 1px solid $dividerColor;
  border-radius: $border-radius-card;

  @media (max-width: $tablet) {
    padding: 25px 11px 25px 8px;
  }

  @media (max-width: $mobile) {
    padding: unset;
    border: unset;
  }
}

.caseBoxSpacing{
  margin-top: 30px;
}


.icon {
  margin-top: 8px;
  margin-right: 10px;
}

.buttonSpacing {
  margin: 16px 0 24px 0;
}

.missingInfoBox { 
  padding: 16px;
  background: $missingInfoBackground;
  border: 1px solid $red;
  border-radius: $border-radius-toggle;
  @media (max-width: $tablet) {
    width:95% !important;
  }
  @media (max-width: $mobile) {
    width: unset !important;
  }
}

.infoIcon {
  margin-top: 2px;
  padding-right: 10px;
  path {
    fill: $buttonTextColor;
  }
}

.topSpacing {
  margin-top: 3px;
}

.missingItems {
  margin-top: 7px;
  path {
    fill: $red;
  }
}

.handSymbol {
  cursor: pointer;
}

.sent {
  gap: 18px;
}

.missingInfoContainer {
  @media (max-width: $mobile) {
    padding-left: 40px;
  }
}

.dashboardContainer {
  padding: 62px 0 16px 36px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $tablet) {
    padding: 55px 0 16px 16px;
  }

  @media (max-width: $mobile) {
    padding: 0 8px 16px 8px;
  }
}

.mainContainer {
  padding: 22px 0 22px 36px;
  @media (max-width: $tablet) {
    padding: 22px 16px 22px 16px;
  }

  @media (max-width: $mobile) {
    padding: 22px 8px 22px 8px;
  }
}

.newCaseBox {
  flex-basis: 0;
  flex-grow: 1;
  padding: 24px 24px 32px 24px;
  border: 1px solid $dividerColor;
  border-radius: $border-radius-card;
  cursor: pointer;
}

.pb8{
  padding-bottom: 8px;
}

.gp8{
  gap: 8px;
}

.op8 {
  cursor: auto;
  opacity: 0.5;
}

.referBox {
  flex-basis: 0;
  flex-grow: 1;
}

.tooltipFlex {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
}

.newCaseContainer {
  position: relative;
  margin-top: 48px;
  padding: 32px 24px;
  background-color: $menuBackgrounndColor;
  border-radius: $border-radius-bigger;

  &:after {
    position: absolute;
    bottom: 100%;
    width: 50px;
    height: 50px;
    margin-bottom: -25px;
    margin-left: 0;
    background: $menuBackgrounndColor;
    border: 1px solid $menuBackgrounndColor;
    transform: rotate(45deg);
    content: '';
  }

  @media (max-width: $mobile) {
    padding: 32px 8px;
  } 
}

.lifeCaseBackground {
  background: url('../../../../../images/parabola.png');
  background-color: $menuBackgrounndColor;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.addAnother {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 16px; 
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px dashed $footerColor;
  border-radius: $border-radius;
  cursor: pointer;
  path {
    fill: $footerColor;
  }
  &:hover {
    background: $white;
  }
}

.selectedCard {
  border: 2px solid $pineGreen;
  box-shadow: $case-card;
  cursor: auto;
}

.dcArrowPointer {
  &:after {
    left: 15%;
  }
}

.lcArrowPointer {
  &:after {
    left: 50%;
    @media (max-width: $mobile) {
      left: 44%;
    }
  }
}
