@import '~@Styles/vars';

$grid-mobile-side-space: 5%;

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;

  &.fluid {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: $desktop) {
    width: $desktop;

    &.fluid {
      width: 100%;
    }
  }

  @media (max-width: $tablet - 1) {
    padding-right: $grid-mobile-side-space;
    padding-left: $grid-mobile-side-space;
  }
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}
