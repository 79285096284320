@import '~@Styles/vars';
$zindex-hamburger: 100;

.hamburger {
  position: relative;
  z-index: $zindex-hamburger;
  align-self: center;
  cursor: pointer;

  span {
    display: block;
    width: 18px;
    height: 2px;
    margin: 4px auto;
    background-color: $pineGreen;
    transition: all 0.3s ease-in-out;
  }

  &.isOpen {
    transform: rotate(45deg);
    transition: all 0.3s ease-in-out;
    transition-delay: 0.6s;

    span:nth-child(2) {
      width: 0;
    }

    span:nth-child(1),
    span:nth-child(3) {
      transition-delay: 0.3s;
    }

    span:nth-child(1) {
      transform: translateY(11px);
    }

    span:nth-child(3) {
      transform: translateY(-2px) rotate(90deg);
    }
  }
}
