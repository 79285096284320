@import '~@Styles/vars';

.personIcon {
  path{
    fill: $dividerColor;
  }
}

.relative {
  position: relative;
}

.alertTriangle {
  position: absolute;
  left: 2.8rem;
  margin-top: 12px;
}
  
.alertTriangleSettings {
  position: absolute;
  left: 772px;
  margin-top: 12px;
  @media (max-width: $tablet) {
    left: 537px;
  }
  @media (max-width: $mobile) {
    left: 198px;
  }
}
  
.topSpacing {
  margin-top: 10px;
}

.subtitleSpacing {
  margin-top: 20px;
}

.notificationContinueButton {
  max-width: 442px;
  margin-right: auto;
  margin-left: auto;
}

.hide{
  display: none;
} 