@import 'vars';

.logoCircle {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: $border-radius-round;
}

.container {
  position: relative;
  margin-bottom: 8px;
  padding: 16px 32px 16px 16px;
  border: 1px solid $breakerColor;
  border-radius: $border-radius;
  cursor: pointer;
}

.companyLogoImg{
  width: 48px;
  height: 48px;
  border-radius: $border-radius-round;
}

.notSelectedContainer {
  background: $menuBackgrounndColor;
}

.selectedContainer {
  background: $white;
  box-shadow: $case-card;
}

.companyNameSpacing{
  margin-bottom: 4px;
}

.selectionCircle {
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  padding: 4px;
  border: 2px solid $footerColor;
  border-radius: $border-radius-round;
  cursor: pointer;
}

.selectionContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  margin: auto 0;
  margin-left: auto;
  @media (max-width: $mobile) {
    flex-direction: column;
    line-height: 1px;
  }
}

.logoContainer {
  margin-right: 24px;
}

.reviewContainer {
  margin-bottom: 16px;
  padding: 18px 22px 22px 16px;
  background: $white;
  border-radius:$border-radius-toggle;
  box-shadow: $case-card;
}

.reviewActions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto 0;
  margin-left: auto;
}

.handSymbol{
  cursor: pointer;
}

.personContainer{
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  padding:3px 1px 3px 3px;
  text-align: center;
  border-radius: $border-radius-round;
}

.wrapWords {
  white-space: pre-wrap;
}

.ml20 {
  margin-left: 20px;
}

.mt24{
  margin-top: 24px;
}

.briefCaseContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: $white;
  border-radius: $border-radius-round;
  path {
    fill: $buttonTextColor
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: $border-radius-bigger;

  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-bigger;
  }
}

.initialLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-21;
  background: $violet-light;
  border-radius: $border-radius-bigger;
}

.gapFlex {
  gap: 10px;
}

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: 14px 8px 17px 8px;
  font-size: $fontSize-14;
  font-family: inherit;
  background: $transparent;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-14;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}