@import '~@Styles/vars';

.resendContainerSpacing {
  align-items: end;
  margin-top: 20px;
  span {
    margin-right: 5px;
  }
}

.error {
  color: $red;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  
  path{
    fill: $red;
  }
}

.withErrorIcon {
  align-items: center;
  width: 100%;
  margin-top: 1px;
}
.message {
  vertical-align: top;
}
.iconPlacing {
  margin-right:6px;
}

.guidance{
  line-height: 19.68px;
}

.passwordCorrect {
  color: $successColor;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  
  path{
    fill: $successColor;
  }
}

.links {
  color: $electricBlue;
  text-decoration-line: underline;
  text-underline-position: below;
}
