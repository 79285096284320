@import '~@Styles/vars';

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $radioButtonBackground;
}

.loginBox {
  padding: 40px;
  text-align: center;
  background-color: $white;
  border-radius: $border-radius-bigger;
  box-shadow: 0 4px 8px $case-shadow;
}

.logo {
  width: 150px;
}

.mb20 {
  margin-bottom: 20px;
} 
