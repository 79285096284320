@import '~@Styles/vars';

.marginTop80{
  display: flex;
  gap: 24px;
  margin-top:80px;
}

.links {
  margin-top: 8px;
}

.justifyCenter{
  justify-content: center;
}

.handSymbol{
  cursor: pointer;
}

.res1400Max {
  @media (min-width: $desktop-l) {
    max-width: 1037px;
  }
}