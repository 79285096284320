@import '~@Styles/vars';

.tableContainer {
  position: relative;
  // padding: 16px 12px 1px 34px;

  ::-webkit-scrollbar {
    width: 7px;
    height: 9px;
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gray-light;
    border-radius: $border-radius-18;
    -webkit-box-shadow: $scrollShadow;
  }
}
.tableWrapper{
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index1;
  height: 20px; /* adjust as necessary */
  overflow-x: auto;
}
.fonts{
  font-size: $fontSize-18;
  font-family: inherit;
}

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border-new;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-14;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.visibilityToggleIcon {
  position: absolute;
  transform: translateX(-31px) translateY(6px);
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $dividerColor;
  }
}

.relPosition {
  position: relative;
  margin-bottom: 5px;
}

.p-filter-column .p-column-filter .p-column-filter-clear-button {
  display: none !important;
}

.closeIcon {
  path {
    fill: $red;
  }
}

.linkStyle {
  color: $electricBlue;
  &:visited{
    color: $electricBlue;
  }
}

.mb18{
  gap: 4px;
  margin-bottom: 18px;
}

.mb11 {
  margin-bottom: 18px !important;
}

.unAssigned{
  color: $red;
  font-size: $fontSize-18;
  opacity: 0.6;
}

.flexDisplay {
  display: flex;
  justify-content: flex-end;
}