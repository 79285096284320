@import '~@Styles/vars';

.container {
  height: $card-height;
  padding: 11px 17px 11px 17px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $case-shadow;

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: $border-radius-bigger;

    img {
      max-width: 91%;
      max-height: 100%;
      border-radius: $border-radius-bigger;
    }
  }

  .nameDetails {
    padding-left: 20px;
  }

  .todoTitle {
    padding-left: 18px;
  }

  .statusPanel {
    display: flex;
    width: fit-content;
    margin-left: 25px;
    padding: 4px 8px;
    border-radius: $border-radius-info;

    .statusTitle {
      padding-left: 7.5px;
      font-weight: $bold;
      font-size: $fontSize-12;
      line-height: 164%;
      letter-spacing: 0.045em;
    }
  }

}

.handSymbol {
  cursor: pointer;
}

.bottomSpacing {
  margin-bottom: 10px;
}

.sentDate {
  margin-top: 3px;
  margin-right: 4px;

  path {
    fill: $footerColor;
  }
}

.flexDiv {
  display: flex;
}

.iconSpacing {
  margin-top: 5px;
}

.rightSpacing {
  margin-right: 5px;
}

.completedDate {
  margin-top: 3px;
  margin-right: 4px;
}

.sendIcon {
  position: absolute;
  margin-top: 3px;

  path {
    fill: $profRegisterBackground;
  }
}

.sendButton {
  padding-left: 30px;
}

.readyToSendActions {
  padding-top: 17px;
}

.utilityIcon {
  position: absolute;
  margin-top: 9px;

  path {
    fill: $dividerColor;
  }
}

.switchButton {
  padding-left: 22px;
}

.sendHoverState {
  padding: 0 27px;

  &:hover {
    background: $hoverButtonColor;
  }

  @media (max-width: $mobile) {
    text-align: left !important;
  }
}

.globeIcon {
  path {
    fill: $dividerColor;
  }
}

.cardClickable {
  cursor: pointer;

  &:hover {
    background: $hoverButtonColor;
  }
}

.combined {
  @media (max-width: $mobile) {
    display: flex;
  }
}

.splitSpNameAndStatusPanel {
  display: flex;

  @media (max-width: $mobile) {
    display: flex;
    justify-content: space-between;
  }
}

.moreEllipsis {
  padding-left: 5px;
}

.moreHoriz {
  margin-top: 7px;
}

.alignRight {
  text-align: right;
}

.initialLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: $white;
  font-weight: $semiBold;
  font-size: $fontSize-21;
  background: $limeYellow;
  border-radius: $border-radius-round;
}

.right16Spacing {
  margin-right: 16px;
}

.viewNotificationIcon {
  margin-top: 6px;
  margin-right: 5px;
}

.deathDateSpacing {
  margin-right: 8px;
}

.flexBox {
  display: flex;
  justify-content: end;
}

.assigneeHeader {
  display: flex;
  justify-content: end;
  margin-left: 5rem;
  padding: 0 3px 2px 12px;
  border-radius: $border-radius;
  cursor: pointer;
  path {
    fill: $footerColor;
  }
}

.assignedToSelectSpacing {
  width: 100%;
  // margin-left: 4rem;
}

.cardsPagination {
  margin-top: 75px;
}

.pagination__link__active {
  a {
    color: $white;
    background: $profRegisterBackground !important;
    border: none;
  }
}

.pagination__link__disabled {
  a {
    color: $gray;
  }
}


.previousButton {
  background: $white !important;

  path {
    fill: $footerColor;
  }
}

.navigationIconsPlacement {
  margin-top: 7px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  list-style: none;
  cursor: pointer;

  li {
    padding: 3px;
  }

  a {
    padding: 9px 16px;
    font-weight: $bold;
    font-size: $fontSize-12;
    background: $menuBackgrounndColor;
    border-radius: $border-radius;
    outline: none;
  }
}

.gap65 {
  gap: 65px;
}

.gap4 {
  gap: 5px;
}

.gap45 {
  gap: 82px;
}

.noNotifications {
  width: 80%;
  padding: 6% 13%;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
}

.assigneeHeaderClicked {
  align-items: center;
  color: $white;
  background: $black;
  path {
    fill: $white;
  }
}

.assigneeHeaderDefault {
  align-items: center;
  background: $menuBackgrounndColor;
}

.tickColor {
  path {
    fill: $buttonTextColor;
  }
}

.assignedUsers {
  gap: 7px;
  align-items: center;
  padding-right: 11px;
  color: $white;
  background: $profRegisterBackground;
  path {
    fill: $white;
  }
}

.menuOption {
  margin-bottom: 13px;
  line-height: 1.3rem;
}

.fonts{
  font-size: $fontSize-18;
  font-family: inherit;
}

.linkStyle {
  color: $electricBlue;
  &:visited{
    color: $electricBlue;
  }
}

.tableContainer {
  position: relative;
  padding: 16px 12px 1px 34px;

  ::-webkit-scrollbar {
    width: 7px;
    height: 9px;
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gray-light;
    border-radius: $border-radius-18;
    -webkit-box-shadow: $scrollShadow;
  }
}

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border-new;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-14;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.visibilityToggleIcon {
  position: absolute;
  transform: translateX(-31px) translateY(6px);
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $dividerColor;
  }
}

.mb18{
  gap: 4px;
  margin-bottom: 18px;
}