@import '~@Styles/vars';
@import '~@Styles/mixins';


.stepperWrapper{
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  margin: 1em 0 8px 0;
  .stepsContainer {
    display: grid;
    grid-auto-columns: minmax( auto , 99px);
    grid-auto-flow: column;
    gap: 0.5em;
    align-items: center;
    justify-content: start;

    .step {
      height: 8px;
      border-radius: $border-radius;
      &.done {
        background: $profRegisterBackground;
      }
      &.pending {
        background: $buttonTextColor;
      
        opacity: 0.2;
      }
    }

    // @media (max-width: $tablet) {
    //   grid-auto-columns: minmax( auto , 160px);
    // }

    @media (max-width: $mobile) {
      grid-auto-columns: minmax( 20px , 1fr);
    }
  }
  .stepperDescription {
    color: $buttonTextColor;
    font-weight: $fontStyle;
    font-size: $fontSize-14;
    font-family: $AssistantFamily;
    font-style: $fontStyle;
    line-height: 164%;
    letter-spacing: 0.045em;
  }
}

.handSymbol {
  cursor: pointer;
}
