@import '~@Styles/vars';

.spacing {
  margin-top: 15px;
  margin-bottom: 20px;
}
  
.notesContainer {
  padding-top: 10px;
  color: $footerColor;
  font-weight: $fontStyle;
  font-size: $fontSize-14;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 164%;
  letter-spacing: 0.045em;
  
  @media (max-width: $tablet) {
    padding-top: 0;
  }
}
  

.searchButtonContainer {
  margin-left: 0.5rem;
  @media (max-width: $tablet) {
    margin-top: 0.3rem;
    margin-left: unset;
  }
}

.addressContainer {
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.topSpacing {
  margin-top: 0.3rem;
}
  
.adresslink {
  margin: 1.25em 0;
  color: $electricBlue;
  font-size: $fontSize-17;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 22px;
  text-decoration-line: underline;
}

.input {
  width: 100%;
  max-height: $input-address-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-16;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.visibilityToggleIcon {
  position: absolute;
  transform: translateX(-31px) translateY(6px);
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $buttonTextColor;
  }
}
  