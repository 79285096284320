@import '~@Styles/vars';

.tabsContainer {
  display: flex;
  gap: 3.5rem;
  margin: 0;
  padding: 0;
 
  
  .tabItem {
    position: relative;
    display: flex;
    cursor: pointer;
    span {
      color: $gray-light;
    }
    @media (max-width: $tablet) {
      width: 140px;
    }

    @media (max-width: $tablet) {
      width: 165px;
    }
  }

  .tabItemNoWidth {
    position: relative;
    display: flex;
    span {
      color: $gray-light;
    }
  }

  .active {
    font-weight: $semiBold;
    &:before {
      position: absolute;
      bottom: -2px;
      width: 100%;
      height: 7px;
      background-color: $profRegisterBackground;
      border-top-left-radius: $border-radius-5;
      border-top-right-radius: $border-radius-5;
      transition: left 0.9s ease-in-out, width 0.9s 0.70s;
      content: "";
    }
    span {
      color: $profRegisterBackground;
    }
  }

  .notActive {
    &:hover::before {
      position: absolute;
      bottom: -2px;
      width: 100%;
      height: 7px;
      background-color: $dividerColor;
      border-top-left-radius: $border-radius-5;
      border-top-right-radius: $border-radius-5;
      transition: left 0.9s ease-in-out, width 0.9s 0.70s;
      content: "";
    }
  }
}

.countContainerSelected{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  margin-left: 16px;
  padding: 1px 8px 2px;
  color: $white !important;
  font-weight: $bold;
  font-size: $fontSize-12;
  background: $footerColor;
  border-radius: $border-radius-info;
  transform: translateX(270%) translateY(15%);
}

.countContainerUnSelected{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  margin-left: 16px;
  padding: 1px 8px 2px;
  color: $white !important;
  font-weight: $bold;
  font-size: $fontSize-12;
  background: $gray-light;
  border-radius: $border-radius-info;
  transform: translateX(270%) translateY(15%);
}

.w210{
  width: 250px !important;
}

.disabledTab {
  color: $buttonTextColor !important;
  opacity: 0.25;
}

.noCursor{
  cursor: default !important;
}

.lockIcon{
  margin-right: 3px;
  opacity: 0.25 !important;
  path{
    fill:$buttonTextColor;
  }
}

