@import '~@Styles/vars';
@import '~@Styles/mixins';

.formContainer {
  width: calc(max(75%, 500px));
  margin: 32px 0 32px 0;
  padding: 16px 16px 40px 16px;
  background-color: $formBgColor;
  border-radius: $border-radius-toggle;
  @media (max-width: $desktop-983) {
    width: calc(max(90%, 365px));
  }

  @media (max-width: $tablet) {
    width: calc(max(90%, 365px));
  }

  @media (max-width: $tablet-700) {
    width: 94%
  }

  @media (max-width: $mobile) {
    width: unset;
  }

}

.updateBtn {
  margin-left: 6px;
}
