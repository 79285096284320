@import '~@Styles/vars';

.deathCaseContainer {
  margin-bottom: $size43;
  .deceasedContainer {
    margin-bottom: 40px;
  }
  .namesContainer {
    margin: 10px 0;
  }
}

.separator {
  width: 100%;
  height: 2px;
  margin-bottom: 5px;
  background-color: $dividerColor;
}
