@import '~@Styles/vars';

.deathCaseContainer {
  margin-bottom: 10px;
}

.marginBottom20{
  margin-bottom: 10px;
}

.addressContainer {
  margin-top: 7px;
  margin-bottom: 30px;
  padding: 14px 0 24px 24px;
  background: $summaryColor;
  border-radius: $border-radius-toggle; 
}