@import '~@Styles/vars';

.paperBody {
  padding: 35px 70px;
  overflow-y: auto;
  scroll-behavior: smooth;
  &.spacing-xs {
    padding: 5px;
  }

  &.spacing-s {
    padding: 10px;
  }

  &.spacing-m {
    padding: 20px;
  }

  &.spacing-l {
    padding: 48px 40px;
    @media (max-width: $desktop-m) {
      padding: 30px;
    }
  }

  &.spacing-none {
    padding: 0;
  }

  &.overflow-auto {
    overflow: auto;
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  @media (max-width: $desktop-m) {
    padding: 30px;
  }
}
