@import '~@Styles/vars';
@import '~@Styles/mixins';

.mt30{
  margin-top: 30px;
}

.mt18{
  margin-top: 18px;
}

.mt16{
  margin-top: 16px;
}

