@import '~@Styles/vars';


.namesContainer {
  margin: 10px 0;
}

.deceasedContainer {
  margin-bottom: 24px;
}

.separator {
  width: 100%;
  height: 2px;
  margin-bottom: 5px;
  background-color: $dividerColor;
}

.requestIdDcoument {
  margin-top: auto;
}

.tickContainer {
  margin-top: 24px;
}

.normalGrayPaper {
  background: $authContentColor;
}

.professionalPaper {
  background: $fileUploadBackground;
}

.sameHeight {
  flex: 1 1 auto;
}

.flexBox{
  display: flex;
  flex-direction: column;
}

.mb8{
  margin-bottom: 8px;
}

.p18{
  padding: 18px !important;
}

.noPadding {
  margin-top: 4px;
  padding-left: 1rem;
}

.width{
  gap: 16px;
  width: 100%;  
}