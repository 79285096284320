@import '~@Styles/vars';

.companyAccntForm {
  margin-top: 24px;

  .formSection {
    width: calc(max(75%, 500px));
    margin-bottom: 16px;
    padding: 16px 16px 20px 16px;
    background-color: $formBgColor;
    border-radius: $border-radius-toggle;
  
    .sectionHeader {
      font-weight: $semiBold;
    }
  
    .dobHeader {
      margin-top: 64px;
    
      .dobTitle {
        font-weight: $semiBold;
      }
    }
    
    .dobRow {
      margin-right: 16px;
      margin-bottom: 20px;
    }
  
    .subText {
      font-weight: $normal;
    }

    @media (max-width: $tablet) {
      width: calc(max(90%, 365px));
    }

    @media (max-width: $mobile) {
      width: unset;
    }
  }

  .mfaRadioOptions {
    margin: 10px 0;
  }
}

.distancing {
  margin-top: 50px;
}

.buttonTopSpacing {
  margin-top: 37px;
}

.topSpacing{
  margin-top: 10px;
}

.info{
  margin-top: 20px;
  line-height: 19px;
}

.searchButtonContainer {
  
  margin-left: 0.5rem;
  @media (max-width: $tablet) {
    //width:100% ;
    margin-top: 0.3rem;
    margin-left: unset;
  
  }
}
.addressContainer {
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.topAddressSpacing {
  margin-top: 0.3rem;
}

.adresslink {
  margin: 1.25em 0;
  color: $electricBlue;
  font-size: $fontSize-17;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
}

.input {
  width: 100%;
  max-height: $input-address-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-16;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.visibilityToggleIcon {
  position: absolute;
  transform: translateX(-31px) translateY(6px);
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $buttonTextColor;
  }
}

.helpTextSpacing {
  margin-bottom: 16px;
}

.fullWidth{
  width:95%;
  @media (max-width: $tablet) {
    width: 100%
  }

}
