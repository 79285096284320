@import '~@Styles/vars';

.gridRow {
  $i: 12;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.fullWidth {
    width: 100%;
  }

  &.centerVertically {
    align-items: center;
  }

  > * {
    flex: 1 1 calc(1 / $i);
  }

  &.withRowGap {
    grid-row-gap: 20px;
  }

  &.withTopPadding {
    padding-top: 30px;
  }

  @supports (display: grid) {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat($i, 1fr);
  }
  @media (max-width: $tablet) {
    flex-flow: column;
  }
}
