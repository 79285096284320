@import '~@Styles/vars';

.distancing {
  margin-top: 50px;
}

.tabPlacement {
  margin-top: 38px;
}

.periodContainer {
  margin-right: 8px;
}

.noteContainer {
  margin-top: 8px;
}

.headerContainer{
  padding: 55px 0 1px 25px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding: 25px 10px 0 10px;
  }
}

.tabBody {
  padding: 24px 0 0 24px;
  @media (max-width: $ipadAir) {
    padding-right: 12px;
    padding-left: 12px;
  }
  @media (max-width: $mobile) {
    padding-left: 0;
  }

}

.ralWidth {
  width: 70%;
  @media (max-width: $desktop-s) {
    width: 90%;
  }
  @media (max-width: $res986) {
    width: unset;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.historyWidth {
  width: 85%;
  @media (max-width: $desktop-s) {
    width: 95%;
  }
  @media (max-width: $res986) {
    width: unset;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.mt4{
  margin-top: 4px;
}

.mt18{
  margin-top: 18px;
}

.emptyNotificationContainer {
  padding: 17px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
  @media (max-width: $mobile) {
    height: unset;
  }
}

.emptyIcon {
  path{
    fill: $dividerColor
  }
}

.messageWidth{
  max-width: 382px;
  margin-right: auto;
  margin-left: auto;
}

.customDropdown {
  position: relative;
  display: inline-block;
  width: 14rem;
}

.dropdownHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 15px;
  border-radius: $thumb-radius;
  cursor: pointer;

  &:hover {
    background-color: $spDashBackground;
  }
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: $zindex-modal;
  max-height: 200px; 
  overflow-y: auto;
  background-color: $white;
  border: 1px solid #ccc;
  border-radius: $thumb-radius;
  box-shadow: 0 4px 6px $case-shadow;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dropdownItem input[type='checkbox'] {
  margin-right: 10px;
}

.dropdownItem input[type='checkbox']:focus {
  outline: none;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownIcon{
  path {
    fill: $footerColor;
  }
}

.peopleIcon{
  path{
    fill: $dividerColor;
  }
}
.download {
  gap: 4px;
  cursor: pointer;
  @media (max-width: $mobile) {
    margin-left: 13px;
  }
  @media (max-width: $tablet) {
    margin-right: 13px;
  }
}

.tabs{
  @media (max-width: $mobile) {
    justify-content: space-between;
  }
}
