@import '~@Styles/vars';

.dropDownPosition {
  position: absolute;
  top: 5px;
  right: 2px;
}
:global {
  .Select__clear-indicator {
    padding: 0 !important;
  }
  .Select {
    position: relative;
    outline: 0;
    &__placeholder {
      top: 59%!important;
      margin-top: 4px;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      color: $buttonTextColor !important;
      font-size: $fontSize-16 !important;
      font-family: $helveticaFamily !important;
      opacity: 0.6;

      @media (max-width: $mobile) {
        width: 90%;
      }
    }

    &__indicator-separator {
      margin: 0;
      opacity: 0;
    }

    &__option {
      padding: $input-padding !important;
      color: $black !important;
      background-color: $white !important;
      border: 0;
      box-shadow: none;
      transition: background-color 0.3s;

      &:not(:first-child) {
        border-top: 1px solid $gray;
      }

      &:active {
        color: $black;
        background-color: $gray-lighter;
      }

      &:hover {
        background-color: $gray-lightest;
      }
      &--is-selected {
        background-color: $gray-lighter !important;
      }
      &--is-disabled {
        color: $gray;
        cursor: not-allowed;
      }
    }

    &__container {

      &.size-ss {
        max-width: 200px;
      }

      &.size-xs {
        max-width: 250px;
      }

      &.size-s {
        min-width: 70px;
      }

      &.size-m {
        min-width: 140px;
      }

      &.size-l {
        min-width: 246px;
      }

      &.size-xl {
        min-width: 360px;
      }

      &.Select--with-error {
        .Select__control {
          background: $statusErrorColor;
          border-color: $red;
        }
      }

      .Select {
        &__single-value {
          max-width: 100%;
        }

        &__placeholder {
          color: $buttonTextColor !important;
          font-size: $fontSize-14 !important;
          opacity: 0.4;
        }

        &__value-container {
          padding: 0;

          & > div {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .Select__control {
        align-items: baseline;
        width: 100%;
        max-height: $input-control-height;
        padding: $select-padding;
        border: $input-border;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        filter: none;
        transition: border 0.2s ease;
        caret-color: $green;

        &:hover {
          border: 1px solid $electricBlue;
        }

        &--is-focused {
          border: 1px solid $electricBlue;
        }

        &--menu-is-open {
          border: 1px solid $electricBlue;
          border-bottom-color: $gray-light;
          border-bottom-right-radius: $border-radius-zero;
          border-bottom-left-radius: $border-radius-zero;
          box-shadow: $no-shadow;
        }
        @include textOverflow();
      }

      .Select__multi-value {
        margin-bottom: 5px !important;
        color: $gray;
        background-color: $gray-light;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &__label {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__remove {
          color: $gray;
          cursor: pointer;
          &:hover {
            color: $gray-light;
            background-color: $gray;
          }
        }
      }

      .Select__menu {
        top: 98%;
        margin-top: 0;
        overflow: hidden;
        background-color: $transparent;
        border: 1px solid $electricBlue;
        border-top-color: $gray;
        border-radius: $border-radius-zero;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        box-shadow: $no-shadow;
        &-list {
          max-height: 195px;
          padding: 0;
          background-color: $white;
        }
      }
    }

    &__containment {

      path {
        fill: $footerColor !important;
      }

      &.size-xs {
        max-width: 250px;
      }

      &.size-s {
        min-width: 70px;
      }

      &.size-m {
        min-width: 140px;
      }

      &.size-l {
        min-width: 246px;
      }

      &.size-xl {
        min-width: 338px;
      }

      &.Select--with-error {
        .Select__control {
          background: $statusErrorColor;
          border-color: $red;
        }
      }

      .Select {
        &__single-value {
          max-width: 100%;
        }
        &__placeholder {
          color: $red !important;
          font-size: $fontSize-18 !important;
          opacity: 0.6;
        }
        &__value-container {
          padding: 0;

          & > div {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .Select__control {
        align-items: baseline;
        width: 100%;
        max-height: 28px;
        padding: 2px 16px 8px 45px;
        border: none;
        outline: none;
        filter: none;
        transition: border 0.2s ease;
        caret-color: $green;

        // &:hover {
        //   border: 1px solid $pineGreen;
        // }

        // &--is-focused {
        //   border: 1px solid $pineGreen;
        // }

        &--menu-is-open {
          border: 1px solid $pineGreen;
          border-bottom-color: $gray-light;
          border-bottom-right-radius: $border-radius-zero;
          border-bottom-left-radius: $border-radius-zero;
          box-shadow: $no-shadow;
        }
        @include textOverflow();
        @media (max-width: $mobile) {
          padding: 2px 0 0 5px;
        }
      }

      .Select__multi-value {
        margin-bottom: 5px !important;
        color: $gray;
        background-color: $gray-light;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &__label {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__remove {
          color: $gray;
          cursor: pointer;
          &:hover {
            color: $gray-light;
            background-color: $gray;
          }
        }
      }

      .Select__menu {
        top: 98%;
        margin-top: 0;
        overflow: hidden;
        background-color: $transparent;
        border: 1px solid $pineGreen;
        border-top-color: $gray;
        border-radius: $border-radius-zero;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        box-shadow: $no-shadow;
        &-list {
          max-height: 195px;
          padding: 0;
          background-color: $white;
        }
      }

      .Select__indicator{
        padding: unset !important;
      }
    }

    &__menuposition {

      &.size-ss {
        max-width: 200px;
      }

      &.size-xs {
        max-width: 250px;
      }

      &.size-s {
        min-width: 70px;
      }

      &.size-m {
        min-width: 140px;
      }

      &.size-l {
        min-width: 246px;
      }

      &.size-xl {
        min-width: 374px;
      }

      &.Select--with-error {
        .Select__control {
          background: $statusErrorColor;
          border-color: $red;
        }
      }

      .Select {
        &__single-value {
          max-width: 100%;
        }
        &__value-container {
          padding: 0;

          & > div {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .Select__control {
        align-items: baseline;
        width: 100%;
        max-height: $input-control-height;
        padding: $select-padding;
        border: $input-border;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        filter: none;
        transition: border 0.2s ease;
        caret-color: $green;

        &:hover {
          border: 1px solid $electricBlue;
        }

        &--is-focused {
          border: 1px solid $electricBlue;
        }

        &--menu-is-open {
          border: 1px solid $electricBlue;
          border-bottom-color: $gray-light;
          border-bottom-right-radius: $border-radius-zero;
          border-bottom-left-radius: $border-radius-zero;
          box-shadow: $no-shadow;
        }
        @include textOverflow();
      }

      .Select__multi-value {
        margin-bottom: 5px !important;
        color: $gray;
        background-color: $gray-light;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &__label {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__remove {
          color: $gray;
          cursor: pointer;
          &:hover {
            color: $gray-light;
            background-color: $gray;
          }
        }
      }

      .Select__menu {
        position: relative;
        top: 98%;
        margin-top: 0;
        overflow: hidden;
        background-color: $transparent;
        border: 1px solid $electricBlue;
        border-top-color: $gray;
        border-radius: $border-radius-zero;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        box-shadow: $no-shadow;
        &-list {
          max-height: 195px;
          padding: 0;
          background-color: $white;
        }
      }
    }

    &__collaborator {

      &.size-ss {
        max-width: 200px;
      }

      &.size-xs {
        max-width: 250px;
      }

      &.size-s {
        min-width: 70px;
      }

      &.size-m {
        min-width: 140px;
      }

      &.size-l {
        min-width: 246px;
      }

      &.size-xl {
        min-width: 360px;
      }

      &.Select--with-error {
        .Select__control {
          background: $statusErrorColor;
          border-color: $red;
        }
      }

      .Select {
        &__single-value {
          max-width: 100%;
          font-size: $fontSize-16 !important;
        }
        &__value-container {
          padding: 0;

          & > div {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .Select__control {
        align-items: baseline;
        width: 100%;
        max-height: $input-control-height;
        padding: 6px 16px 8px 7px;
        border: $input-border;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        filter: none;
        transition: border 0.2s ease;
        caret-color: $green;

        &:hover {
          border: 1px solid $electricBlue;
        }

        &--is-focused {
          border: 1px solid $electricBlue;
        }

        &--menu-is-open {
          border: 1px solid $electricBlue;
          border-bottom-color: $gray-light;
          border-bottom-right-radius: $border-radius-zero;
          border-bottom-left-radius: $border-radius-zero;
          box-shadow: $no-shadow;
        }
        @include textOverflow();
      }

      .Select__multi-value {
        margin-bottom: 5px !important;
        color: $gray;
        background-color: $gray-light;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &__label {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__remove {
          color: $gray;
          cursor: pointer;
          &:hover {
            color: $gray-light;
            background-color: $gray;
          }
        }
      }

      .Select__menu {
        top: 98%;
        margin-top: 0;
        overflow: hidden;
        background-color: $transparent;
        border: 1px solid $electricBlue;
        border-top-color: $gray;
        border-radius: $border-radius-zero;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        box-shadow: $no-shadow;
        &-list {
          max-height: 195px;
          padding: 0;
          background-color: $white;
        }
      }

      .Select__indicator{
        padding: 1px 1px 8px 1px !important;
        path{
          fill: $electricBlue;
        }
      }
    }

    &__referralField {

      &.size-ss {
        max-width: 200px;
      }

      &.size-xs {
        max-width: 250px;
      }

      &.size-s {
        min-width: 70px;
      }

      &.size-m {
        min-width: 140px;
      }

      &.size-l {
        min-width: 246px;
      }

      &.size-xl {
        min-width: 360px;
      }

      &.Select--with-error {
        .Select__control {
          background: $statusErrorColor;
          border-color: $red;
        }
      }

      .Select {
        &__single-value {
          max-width: 100%;
        }
        &__value-container {
          padding: 0;

          & > div {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .Select__control {
        align-items: baseline;
        width: 100%;
        max-height: $input-control-height;
        padding: $select-padding;
        border: $input-border;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        filter: none;
        transition: border 0.2s ease;
        caret-color: $green;

        &:hover {
          border: 1px solid $electricBlue;
        }

        &--is-focused {
          border: 1px solid $electricBlue;
        }

        &--menu-is-open {
          border: 1px solid $electricBlue;
          border-bottom-color: $gray-light;
          border-bottom-right-radius: $border-radius-zero;
          border-bottom-left-radius: $border-radius-zero;
          box-shadow: $no-shadow;
        }
        @include textOverflow();
      }

      .Select__multi-value {
        margin-bottom: 5px !important;
        color: $gray;
        background-color: $gray-light;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &__label {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__remove {
          color: $gray;
          cursor: pointer;
          &:hover {
            color: $gray-light;
            background-color: $gray;
          }
        }
      }

      .Select__menu {
        top: 98%;
        margin-top: 0;
        overflow: hidden;
        background-color: $transparent;
        border: 1px solid $electricBlue;
        border-top-color: $gray;
        border-radius: $border-radius-zero;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        box-shadow: $no-shadow;
        &-list {
          max-height: 325px;
          padding: 0;
          background-color: $white;
        }
      }
    }

    &__address {

      &.size-ss {
        max-width: 200px;
      }

      &.size-xs {
        max-width: 250px;
      }

      &.size-s {
        min-width: 70px;
      }

      &.size-m {
        min-width: 140px;
      }

      &.size-l {
        min-width: 246px;
      }

      &.size-xl {
        min-width: 360px;
      }

      &.Select--with-error {
        .Select__control {
          background: $statusErrorColor;
          border-color: $red;

          .Select__placeholder {
            color: $red !important;
            opacity: 2;
          }
        }
      }

      .Select {
        &__single-value {
          max-width: 100%;
        }

        &__placeholder {
          color: $buttonTextColor !important;
          font-size: $fontSize-14 !important;
          opacity: 0.4;
        }

        &__value-container {
          padding: 0;

          & > div {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .Select__control {
        align-items: baseline;
        width: 100%;
        max-height: $input-control-height;
        padding: $select-padding;
        border: $input-border;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        filter: none;
        transition: border 0.2s ease;
        caret-color: $green;

        &:hover {
          border: 1px solid $electricBlue;
        }

        &--is-focused {
          border: 1px solid $electricBlue;
        }

        &--menu-is-open {
          border: 1px solid $electricBlue;
          border-bottom-color: $gray-light;
          border-bottom-right-radius: $border-radius-zero;
          border-bottom-left-radius: $border-radius-zero;
          box-shadow: $no-shadow;
        }
        @include textOverflow();
      }

      .Select__multi-value {
        margin-bottom: 5px !important;
        color: $gray;
        background-color: $gray-light;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &__label {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__remove {
          color: $gray;
          cursor: pointer;
          &:hover {
            color: $gray-light;
            background-color: $gray;
          }
        }
      }

      .Select__menu {
        top: 98%;
        margin-top: 0;
        overflow: hidden;
        background-color: $transparent;
        border: 1px solid $electricBlue;
        border-top-color: $gray;
        border-radius: $border-radius-zero;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        box-shadow: $no-shadow;
        &-list {
          max-height: 396px;
          padding: 0;
          white-space: pre-wrap;
          background-color: $white;
        }
      }
    }

    &__permissions {

      &.size-ss {
        max-width: 200px;
      }

      &.size-xs {
        max-width: 250px;
      }

      &.size-s {
        min-width: 70px;
      }

      &.size-m {
        min-width: 140px;
      }

      &.size-l {
        min-width: 246px;
      }

      &.size-xl {
        min-width: 360px;
      }

      &.Select--with-error {
        .Select__control {
          background: $statusErrorColor;
          border-color: $red;
        }
      }

      .Select {
        &__single-value {
          max-width: 100%;
        }

        &__placeholder {
          color: $buttonTextColor !important;
          font-size: $fontSize-14 !important;
          opacity: 0.4;
        }

        &__value-container {
          padding: 0;

          & > div {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .Select__control {
        align-items: baseline;
        width: 100%;
        max-height: $input-control-height;
        padding: $select-padding;
        border: $input-border;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        filter: none;
        transition: border 0.2s ease;
        caret-color: $green;

        &:hover {
          border: 1px solid $electricBlue;
        }

        &--is-focused {
          border: 1px solid $electricBlue;
        }

        &--menu-is-open {
          border: 1px solid $electricBlue;
          border-bottom-color: $gray-light;
          border-bottom-right-radius: $border-radius-zero;
          border-bottom-left-radius: $border-radius-zero;
          box-shadow: $no-shadow;
        }
        @include textOverflow();
      }

      .Select__multi-value {
        margin-bottom: 5px !important;
        color: $gray;
        background-color: $gray-light;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &__label {
          padding-top: 0;
          padding-bottom: 0;
        }
        &__remove {
          color: $gray;
          cursor: pointer;
          &:hover {
            color: $gray-light;
            background-color: $gray;
          }
        }
      }

      .Select__menu {
        top: 98%;
        margin-top: 0;
        overflow: auto;
        background-color: $transparent;
        border: 1px solid $electricBlue;
        border-top-color: $gray;
        border-radius: $border-radius-zero;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        box-shadow: $no-shadow;
        &-list {
          max-height: 90px;
          padding: 0;
          background-color: $white;
        }
      }
    }


  }
}

.loadingContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}
