@import '~@Styles/vars';

.navLayout {
  .rightPanelDiv {
    margin-top: 30px;
    padding-left: 25px;
    @media (min-width: $desktop-l) {
      max-width: 945px;
    }

    @media (max-width: $tablet) {
      padding-left: 17px;
    }
    @media (max-width: $mobile) {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  .rightPanelNotification {
    margin-top: 30px;
  }
}

.backButtonSpacing {
  margin-right: 10px;
  path {
    fill: $buttonTextColor;
  }
}

.handSymbol {
  cursor: pointer;
}

.headerContainer{
  padding: 55px 0 11px 25px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding: 25px 10px 11px 10px;
  }
}