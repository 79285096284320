@import 'vars';

.logoCircle {
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: $border-radius-round;
}

.container {
  margin-bottom: 8px;
  padding: 16px 32px 16px 16px;
  border-radius: $border-radius;
  cursor: pointer;
}

.companyLogoImg{
  width: 64px;
  height: 64px;
  border-radius: $border-radius-round;
}

.notSelectedContainer {
  background: $menuBackgrounndColor;
}

.selectedContainer {
  background: $white;
  box-shadow: $case-card;
}

.companyNameSpacing{
  margin-bottom: 4px;
}

.selectionCircle {
  width: 38px;
  height: 38px;
  padding: 4px;
  border: 2px solid $footerColor;
  border-radius: $border-radius-round;
  cursor: pointer;
}

.selectionContainer {
  display: flex;
  gap: 16px;
  margin: auto 0;
  margin-left: auto;
}

.logoContainer {
  margin-right: 24px;
}

.reviewContainer {
  margin-bottom: 16px;
  padding: 18px 22px 22px 16px;
  background: $white;
  border-radius:$border-radius-toggle;
  box-shadow: $case-card;
}

.reviewActions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto 0;
  margin-left: auto;
}

.handSymbol{
  cursor: pointer;
}

.personContainer{
  width: 48px;
  height: 48px;
  padding:3px 1px 3px 3px;
  text-align: center;
  border-radius: $border-radius-round;
}

.lineHeight {
  line-height: 164%;
}