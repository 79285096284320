@import '~@Styles/vars';
@import '~@Styles/mixins';

.cardContainer {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  color: $lighterBlack;
  background-color: $white;
  border-radius: $border-radius-card;
  box-shadow: 1px 2px 3px rgba($gray, 0.05);
  cursor: pointer;
  
  @media (max-width: $tablet) {
    max-width: unset;
    margin-top: 15px;
  }
}

.topSpacing {
  margin-top: 32px;
}

.reportKpiContainer {
  display: flex;
  gap:16px;
}

.employeePerformanceContainer {
  height: 618px;
  padding-right: 24px;
  padding-left: 24px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
}

.employeesContainer {
  height: 447px;
  margin-top: 9px;
  padding-top: 12px;
  padding-left: 8px;
  overflow: scroll;
  background: $white;
  border-radius: $border-radius-toggle;
}

.employeeRecord {
  margin-bottom: 14px;
}

.svgContainer {
  margin-top:35px;
}

.csvButton {
  display: flex;
  flex-direction: row;
  gap:10px;
  align-items: center;
  justify-content: center;
  height: 39px;
  padding: 8px 30px;
  overflow: hidden;
  color: $buttonTextColor;
  font-weight: $bold;
  font-size: $fontSize-17;
  line-height: 16px;
  letter-spacing: 0.025em;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  background-color: $transparent;
  background-image: none;
  border: 3px solid $buttonTextColor;
  border-radius: $border-radius;
  outline: 0;
  box-shadow: 0 12px 15px -12px $shadowColor;
  cursor: pointer;
  transition: transform 0.3s;
  will-change: transform;
  path {
    fill: $buttonTextColor;
  }
}