@import '~@Styles/vars';
@import '~@Styles/mixins';

.layout {
  width:350px;
  padding: 15px 16px 15px 16px;
  background: $authContentColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 24px 30px -14px $shadowColor;

  @media (max-width: $tablet) {
    width: 400px;
  }

  @media (max-width: $mobile) {
    width: 100%;
  }
}

.emailSentLayout {
  position: relative;
  align-items: flex-start;
  width:578px;
  padding: 15px 16px 15px 16px;
  background: $authContentColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 24px 30px -14px $shadowColor;

  @media (max-width: $tablet) {
    left:unset;
    width: unset;
    margin:35px;
  }

  @media (max-width: $mobile) {
    margin: unset;
  }
}

.signUpContainer {
  margin-top: 20px;
  border-top: 1px solid $breakerColor;
}

.bodyFooterContainer {
  display: flex;
  flex-direction: column;
  background-color: $white !important;

  .yellowEllipsis {
    position: absolute;
    width: 495px;
    height: 495px;
    margin-top: 210px;
    margin-left: 36%;    
    background-color: $mustard;
    border-radius: $border-radius-round;

    @media (max-width: $desktop) {
      margin-left: 32%;
    }

    @media (max-width: $desktop-s) {
      margin-left: 29%;
    }

    @media (max-width: $tabletSurface) {
      margin-left: 25%;
    }

    @media (max-width: $tablet) {
      margin-left: 21%;
    }

    @media (max-width: $res653) {
      margin-left: 15%;
    }
  }
}

.registerFormlayout {
  width: 58%; 
  margin-top: 7rem;
  padding: 15px 16px 15px 16px;
  background: $authContentColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;
  @media (max-width: $mobile) {
    width: unset;
    margin-top: unset;
  }
}

.sideBarContainer {
  top:0;
  width: 100%;
  height: 830px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $white;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;

  .widgetHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:20px 5px 20px 20px;

    .closeWidget {
      z-index: $z-index1;
      display: block;
      margin-right: 38px;
      padding: 4px 7px 5px 7px;
      font-weight: $normal;
      line-height: 1;
      text-align: center;
      transition: all .3s;
    }    
    .closeIcon{
      position: absolute;
      top: 1.8rem;
      right:1rem;
    }
    a {
      text-decoration: none;
    }
  }

  .widgetBody {

    .itemContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;
      padding-left: 20px;

      .menuItem {
        padding: 10px;

        .link {
          color: $black;
        }

        a{
          text-decoration: none;
        }
      }

      .iconContainer {
        margin-top:0.7rem;
      }

      @media (max-width: $tablet) {
        padding-right: 50px;
      }

    }

    .loginRegisterContainer {
      position: fixed;
      bottom: 0;
      z-index: $zindex-hamburger;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      padding: 40px 0 40px 0;
      background-image: linear-gradient(180deg, $white 0%, $white 100%);
      box-shadow: 0 0 16px 0 $mobileLoginBoxShadowColor;

      .buttonContainers {
        margin-right: 40px;
        margin-bottom: 20px;
        margin-left: 40px;
      }
    }
  }
}

.accountTypeRegisterlayout {
  padding: 213px 100px 0 285px;
  @media (max-width: $desktop-983) {
    padding: 213px 100px 0 170px;
  }
  @media (max-width: $tablet) {
    padding: 104px 0 0 0;
  }
}

.registrationContiner {
  display: flex;
  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.loginFormContainer {
  z-index: $z-index1;
  display: flex;
  justify-content: center;
  margin-top: 6.6rem;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
  
}


