@import '~@Styles/vars';
@import '~@Styles/mixins';

.outerCircle {
  display: inline-block;
  width: 68px;
  height: 68px;
  margin-top: 4px;
  margin-right: 16px;
  background: $white;
  border-radius: $border-radius-round;
  box-shadow: $case-card;
}

.innerCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  margin:3px;
  background: $violet-light;
  border-radius: $border-radius-round;
}

.profLogo{
  width: 62px;
  height: 62px;
  border-radius: $border-radius-round;
}




.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.6em;
  min-height: 100%;
  .rightSideContainer {
    overflow: hidden !important;
    @media (max-width: $tablet) {
      padding-left: unset;
    }
    @media (max-width: $mobile) {
      width: 100%;
      padding-right: 9px; 
      padding-left: 9px;
    }
    @media (max-width: $desktop-s) {
      padding-bottom: 25px;
    }
  }

  .secondLeftSideContainer {
    z-index: $z-index1;
    min-width: 209px;
    @media (max-width: $tablet) {
      min-width: 160px;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: 0.3em;

    .leftSide,
    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 224px 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 164px 1fr;
  }

  @media (max-width: $mobile) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
    min-height: unset;
    margin-top: unset;
  }
}

.headerContainer {
  padding: 16px 0 1px 0;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-bottom: unset;
    padding-left: 8px;
  }
}

.mt34{
  margin-top: 34px;
}

.mt10{
  margin-top: 100px;
}

.handSymbol {
  cursor: pointer;
}


.uploadOuterCircle {
  display: inline-block;
  width: 68px;
  height: 68px;
  margin-top: 4px;
  margin-right: 16px;
  background: $white;
  border-radius: $border-radius-round;
  box-shadow: $case-card;

  &:hover {
    background: $circleHover;
  }
}

.uploadInnerCircle {
  display: inline-block;
  width: 62px;
  height: 62px;
  margin:3px;
  padding: 20px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-round;

  &:hover {
    background: $circleHover;
  }
}

.uploadIcon {
  transform: rotate(180deg);
  path {
    fill: $buttonTextColor;
  }
}

.mb19{
  margin-bottom: 19px;
}

.gap24{
  gap:15px;
}

.dropdownContent {
  position: absolute;
  top: 4rem;
  right: 0.6rem;
  z-index: $z-index1;
  width: 190px;
  max-height: 182px;
  padding: 0.75rem 1.25rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $case-card;
}

.showMenu {
  display: block;
}

.hideMenu {
  display: none;
}

.menuOption {
  gap: 0.5rem;
  margin-bottom: 13px;
  line-height: 1.3rem;
}

.gap4{
  gap: 1rem;
}

.overallContainer {
  height: auto;
  padding:2rem;
  font-size: $fontSize-16 !important;
  background: $spDashBackground;
}

.menuBox {
  flex-basis: auto;
  flex-grow: 1;
}

.justifyEnd {
  display: flex;
  justify-content: end;
}

.cardBackground {
  padding: 16px 34px 16px 34px; 
  background: $white;
  border-radius: $border-radius-bigger;
} 

.layoutHorizontal {
  display: flex;
  justify-content: center;
  .elementorNavMenu {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    .itemMenuLink {
      padding: 13px 20px;
      color: $black;
      // font-weight: $normal;
      font-size: $fontSize-16;
      font-style: normal;
      letter-spacing: 0.025em;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $electricBlue;
      }
    }
    .chevronIconWrapper {
      margin-left: 0.5rem;
    }

    li:hover ul {
      display: block;
      margin-top: 0.5rem;
      background-color: $menuBackgrounndColor;
      border-radius: $border-radius-sub-menu;
    }

    .sub {
      position: absolute;
      z-index: $zindex-dateInput;
      display:none;
      padding: 0;
      
      li {
        display: block;
        margin: 20px;
        padding: 20px;
        line-height: 1.3rem;

        &:hover {
         
          background-color: $subMenuLinkColor;
          border-radius: $border-radius-sub-menu;
        }

        a {
          color: $black;
          text-decoration: none;
        }

      }
    }
    .totalHeader{
      display: flex;
      width: 100%;
      .inner {
        width: 80%;
        margin-right:50px !important;

        .mainHead {
          margin-bottom: 4px;
        }

        .bodyText {
          color: $footerColor;
          font-size: $fontSize-16;

        }
      }
      .arrow {
        padding: 20px;
      }
    }
  }
}

.kpiContainer {
  width: 100%;
  padding: 1.5rem;
  background: $menuBackgrounndColor;
  border: 1px solid rgba($gray-light, 0.4);
  border-radius: $border-radius-toggle;
  box-shadow: 1px 2px 3px rgba($gray, 0.05);
  cursor: pointer;
}

.selectedBox {
  border: 1px solid $successColor;
}

.hyphen{
  width: 25px;
  height: 2px;
  margin-top: 4rem;
  background: $footerColor;
  border-radius: $border-radius-hyphen;
}

.buttonWrapper {
  margin-top: 38px;
}