@import '~@Styles/vars';



.buttonWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
}

.subtitleSpacing {
  margin-top: 10px;
}

.registerFormlayout {
  position: relative;
  left:299px;
  width: 578px; 
  margin-top: 7rem;
  padding: 15px 21px 15px 21px;
  background: $authContentColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;

  .signUpContainer {
    margin-top: 20px;
    border-top: 1px solid $breakerColor;
  }
  @media (max-width: $tablet) {
    position: unset;
    left: unset;
    width: unset;
  }

}