@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  width: 100%;
  height: 85px;
  background: $menuBackgrounndColor;
  box-shadow: $case-card;
 

}

.lifeLedgerIcon {
  padding:24px 195px 24px 8px;
}

.backToSettingsContainer {
  display: flex;
  justify-content: space-between;
  padding:24px 0 24px 8px;
}

.clickSection {
  cursor: pointer;
}

.backToMenuContainer {
  display: flex;
  .backToText {
    padding-left: 30px;
  }

}

.iconPosition {
  position: absolute;
  margin-top: 2px;
  path {
    fill: $buttonTextColor;
  }
}


.settingsCloseIcon {
  margin-top: 6px;
  margin-right: 35px;
  path{
    fill: $buttonTextColor;
  }
}

.grayBackground {
  background: $settingsHeaderBackground;
}

.pb16 {
  padding-bottom: 16px;
}
