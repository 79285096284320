@import '~@Styles/vars';

.subtitleSpacing {
  margin-top: 10px;
}

.topClass{
  top: 99px;
  @media (max-width: $desktop-s) {
    top: 66px;
  }
  @media (max-width: $mobile) {
    top: 95px;
  }
}

.alRegClass{
  top: 99px;
  @media (max-width: $desktop-s) {
    top: 66px;
  }
  @media (max-width: $mobile) {
    top: 95px;
  }
}

.scrollClass{
  top: 72px;
  @media (max-width: $desktop-s) {
    top:69px;
  }
  @media (max-width: $mobile) {
    top: 77px;
  }
}

.notficationMainContainer {
  width: 100%;

  @media (max-width: $desktop-s) {
    width: 100%;
  }
}

.notficationDashboardContainer {
  width: 100%;

 
  @media (max-width: $tablet) {
    position: unset;
    top: unset;
    left:unset;
    width: unset;
  }

  @media (max-width: $mobile) {
    position: unset;
    top: unset;
    left:unset;
    width: unset;
    margin-top: 24px;
    padding-right: 8px;
    padding-bottom: 20px;
    padding-left: 8px;
  }
}

.whiteSpace {
  white-space: nowrap;
}

.referenceNumber {
  margin-left: 45px;
}

.marginTop6{
  margin-top: 6px;
}

.helpContainer {
  margin-top: 24px; 
  margin-bottom: 24px; 
  padding: 8px 24px 8px 16px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;

  .bulbContainer {
    margin-top: 4px;
    margin-right: 16px;
  }

  @media (min-width: $desktop-l) {
    max-width: 895px;
  }

}

.helpCollapsedContainer {
  margin-top: 24px;
  margin-bottom: 24px; 
  padding: 8px 24px 8px 16px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;
  .bulbContainer {
    margin-top: 4px;
    margin-right: 16px;
  }

  @media (min-width: $desktop-l) {
    max-width: 895px;
  }
}
.tabContainer {
  margin-top: 64px;
  white-space: nowrap;
  @media (max-width: $mobile) {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-mask-image: $tabMask;
  }
}

.marginTop33{
  margin-top: 33px;
}

.marginTop27 {
  margin-top: 27px;
}

.lh24{
  margin-bottom: 8px;
  line-height: 23.4px;
}

.flexEnd{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.marginRight16{
  margin-right: 16px;

}

.marginTop68{
  margin-top:50px;
}

.marginTop80{
  margin-top:80px;
}


.infoIcon {
  margin-top: 10px;
  margin-left: 8px;
}

.relevantContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; /* center if you want to the center */

  .relevantBoxPadding {
    width: 50%;
    padding-right: 16px;
    padding-bottom: 9px;
    @media (max-width: $mobile) {
      width: unset;
      padding: unset;
    }
  }

  .relevantBox {
    width: 50%;
    padding-bottom: 9px;
    @media (max-width: $mobile) {
      width: unset;
    }
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    align-items: unset;
  }
}

.header {
  padding: 16px 24px 12px 0;
  border-bottom: 1px solid $dividerColor;
}

.internalSpacing {
  padding: 0 8px 16px 16px;
}
 
.bottomBorder {
  border-bottom: 1px solid $dividerColor;
}

.chatInfoSpacing{
  margin-left: 5px;
}

.chatTypeSelected{
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 5px;
  background-color: $subMenuLinkColor;
  border-radius: $border-radius;
}

.chatTypeUnSelected{
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 5px;
  cursor: pointer;
}

.professionalInfo {
  width: 70%;
  margin-bottom:30px;
  padding: 10px 20px 20px 20px;
  color: $white;
  background-color: $profRegisterBackground; 
  border-radius: $border-radius-toggle;
  path {
    fill: $white;
  }

  ul{
    list-style-type: unset;
  }

  .listContainer {
    padding-left: 60px;
  }

  @media (max-width: $mobile) {
    width: unset;
  }
}

.helpStatusVerfication {
  margin-top: 15px;
  margin-bottom: 15px;
}

.colContainer {
  display: flex;
  gap: 16px;
  width: 100%;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.messageSquare{
  path {
    fill: $profRegisterBackground;
  }
}

.handSymbol {
  cursor: pointer;
}

.csvFileButton {
  margin-top: 15px;
}

.mt24 {
  margin-top: 24px;
}

.mt1 {
  margin-top: 5px;
}

.flexGap {
  gap:24px;
}

.equalWidth {
  width: 33%;
  @media (max-width: $tablet) {
    width: unset;
  }
}

.headerContainer {
  padding: 40px 0 1px 57px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $desktop-s) {
    padding-right: 8px;
    padding-left: 8px;
  }
  @media (max-width: $mobile) {
    padding-bottom: unset;
  }
}

.pr12{
  padding-right: 16rem;
}

.mobileBody {
  padding-right: 8px;
  padding-bottom: 40px;

  @media (min-width: $desktop-l) {
    max-width: 935px;
  }
}

.staffBody {
  @media (min-width: $desktop-l) {
    max-width: unset !important;
  }
}

.pl8{
  padding-left: 8px;
}

.greenEllipsis {
  position: absolute;
  z-index: $z-index-1;
  width: 526px;
  height: 526px;
  margin-top: 14rem;
  margin-left: 9rem;    
  background-color: $ellipsisColor;
  border-radius: $border-radius-round;
}

.greenEllipsis1 {
  position: absolute;
  z-index: $z-index-1;
  width: 445px;
  height: 445px;
  margin-top: 1167px;
  margin-left: 458px;    
  background-color: $ellipsisColor;
  border-radius: $border-radius-round;
}

.gap8{
  @media (max-width: $mobile) {
    gap:8px;
  }
}

.headerInset {
  padding-left: 32px;
  box-shadow: inset 7px 0 14px -7px $mobileLoginBoxShadowColor;
}

.spMainContainer {
  top: 6rem;
  @media (max-width: $desktop-s) {
    top: 3rem;
  }
}

.scrollDownMainContainer {
  top: 3rem;
}

.pl34 {
  padding-left: 34px;
  @media (max-width: $tablet) {
    padding-left: 17px;
  }
}
  