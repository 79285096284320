@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  h2 + h4 {
    margin-top: 10px;
  }
  h4 {
    font-weight: $normal;
    line-height: normal;
  }
  .biggerSpace {
    margin-top: 40px;
    line-height: 28px;
  }

  .marginBetween {
    margin-top: 45px;
  }

  .withLessMarging {
    margin-top: 10px;
  }

  .userSectionSubtitle {
    font-weight: $normal;
    line-height: 28px;
  }

  .userSectionTitle {
    font-weight: $normal;
    font-size: $fontSize-32;
    line-height: 42px;
  }
  .titleIcon {
    margin-left: 20px;
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background-color: $gray-lighter;
    border-radius: $border-radius-bigger;
    img {
      width: 60px;
      height: 44px;
    }
  }
}
