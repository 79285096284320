@import '~@Styles/vars';
@import '~@Styles/mixins';
  
.cardContainer {
  position: relative;
  margin-bottom: 10px;
  padding: 25px;
  color: $lighterBlack;
  background-color: $white;
  border-radius: $border-radius-card;
  box-shadow: 1px 2px 3px rgba($gray, 0.05);
}


.buttonSpacing{
  width: 10%;
  margin-left: 10px;
}

.sendButtonSpacing{
  margin-top: 5px;
}

.mtop20{
  margin-top: 20px;
}
  
.pagination__link__active 
  {
  a {
    color: $white;
    background: $pineGreen;
    border: none;
  }
}
  
.pagination__link__disabled 
  {
  a {
    color: $gray;
    border: 1px solid $gray-light
  }
}

.chatInputBox {
  min-height: 70px;
  max-height: 180px !important;
  background-color: $gray-lighter;
}

.dateDisplay {
  justify-content: center;
  margin-bottom: 10px;
}

.header {
  padding: 16px 24px 12px 24px;
  background: $menuBackgrounndColor;
  border-bottom: 1px solid $breakerColor;
  border-top-left-radius: $border-radius-toggle;
  border-top-right-radius: $border-radius-toggle;
}

.internalSpacing {
  padding-right: 24px;
  padding-left: 24px;
  background: $white;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.rightSpacing {
  margin-right: 8px
}

.emptyMsgSpacing {
  margin-top:10px;
}

.chatMessagesContainer{
  height: 300px;
  overflow-y: auto;
}

.logoWrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: $border-radius-round;
  img {
    max-width: 40px;
    max-height: 40px;
    border-radius: $border-radius-round;
  }
}

.emptyMessageContainer{
  height: 300px;
}

.chatMessageSpacing {
  line-height: 22.96px;
}

.bigBottomSpacing {
  margin-bottom: 13px;
  padding-top: 12px;
}

.personContainer{
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background: $accent;
  border-radius: $border-radius-round;
  
  .iconSpacing {
    margin-top: 5px;
  }

  @media (max-width: $mobile) {
    margin-right: 5px;
  }
}

.messageBoxContainer {
  padding: 8px 24px 24px 24px;
  background: $menuBackgrounndColor;
  border-top: 1px solid $breakerColor;
  border-bottom-right-radius: $border-radius-toggle;
  border-bottom-left-radius: $border-radius-toggle;
  @media (max-width: $mobile) {
    padding: 8px 8px 24px 8px;
  }
}

.chatMessageText {
  font-size: $fontSize-14;
}

.buttonContainers{
  display: flex;
  align-items: center;
  @media (max-width: $mobile) {
    justify-content: center;
  }
}
  

.sendDocText {
  margin-right: 8px;
  cursor: pointer;
}

.uploadIcon {
  margin-right: 24px;
  cursor: pointer;
}

.lineSep{
  width: 1px;
  height: 41px;
  margin-right: 24px;
  background: $verticalSeparatorColor;
}

.chatContainer {
  padding: 5px 17px 17px 18px;
  border: 1px solid $breakerColor;
  border-top-right-radius: $border-radius-toggle;
  border-bottom-right-radius: $border-radius-toggle;
  border-bottom-left-radius: $border-radius-toggle;
}

.flexBox {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 8px;
}

.justifyEnd {
  justify-content: end;
}

.fileContainer {
  padding: 7px 20px 7px 8px;
  background: $chatFile;
  border-radius: $border-radius-toggle;
}

.wrapText {
  gap: 8px;
  @media (max-width: $mobile) {
    word-break: break-all;
  }
  
}

.viewButton {
  margin-top: 8px;
  margin-left: 4.6rem;
  @media (max-width: $mobile) {
    margin-left: 3rem;
  }
}

.mt10 {
  margin-top: 10px;
}

.radioButtonContainer {
  overflow: hidden;
  @media (max-width: $mobile) {
    margin: 5px 0;
  }
}

.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;

  &.column {
    flex-direction: column;
  }
  @media (max-width: $mobile) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.flexDisplay {
  display: flex;
  align-items: center;
}

.breakAll {
  overflow-wrap:break-word;
}

.quillArea {
  width: 100%;
  height: 107px !important;

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $electricBlue;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
}

