@import '~@Styles/vars';

.outerCircle {
  display: inline-block;
  justify-content: center;
  width: 148px;
  height: 148px;
  margin-top: 4px;
  margin-right: 16px;
  background: $white;
  border-radius: $border-radius-round;
  box-shadow: $case-card;
}
    
.innerCircle {
  display: inline-block;
  width: 134.94px;
  height: 134.94px;
  margin:7px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-round;
}

.alignCenter {
  margin-bottom: 40px;
  text-align: center;
}

.bottomSpacing {
  margin-bottom: 28px;
}

.profLogo{
  width: 134.94px;
  height: 134.94px;
  border-radius: $border-radius-round;
}

.handSymbol {
  outline: none;
  cursor: pointer;
}