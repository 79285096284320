@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  margin-top: 15px;
}
.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-16;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &.error {
    border: 1px solid $red;
  }

  &.withInputIcon {
    padding-left: 40px;
  }

  &::placeholder {
    color: $black;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.visibilityToggleIcon {
  position: absolute;
  transform: translate(-108%, 18%);
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  fill:$pineGreen;
}

.addUserBox {
  width: calc(min(40%, 380px));
  margin: 24px 0;
  padding: 40px 20px;
  border: 3px dashed $footerColor;
  border-radius: $border-radius;

  .addButton {
    width: 100%;

    svg {
      width: 21px;
      height: 21px;
    }
  }

  @media (max-width: $tablet) {
    width: calc(max(70%, 330px));
  }

  @media (max-width: $mobile) {
    width: unset;
  }
}

.buttonSpacing{
  margin-left: 5px;
}

.actionButtons {
  display: flex;
  margin-right: 50px;
}
