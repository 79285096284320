@import '~@Styles/vars';

.backButton {
  gap: 8px;
  cursor: pointer;
}
  
.mt12{
  margin-top: 12px;
}
  
.mt8{
  margin-top: 8px;
}
  
.letterSpacing{
  letter-spacing: 0.045em;
}
  
.mt32{
  margin-top: 32px;
}
  
.graySection {
  width: 535px;
  margin-top: 16px; 
  margin-left: 42px;
  padding: 16px 24px 32px 24px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius;
  @media (max-width: $mobile) {
    width: unset;
    margin-left: unset;
  }
}
  
.mt40 {
  width: 572px;
  margin-top: 40px;
  margin-left: 25px;
  @media (max-width: $mobile) {
    width: 341px;
    margin-bottom: 25px;
    margin-left: unset;
  }
}


.mt16{
  margin-top: 16px;
}

.pb25 {
  @media (max-width: $mobile) {
    padding-bottom: 25px;
  }
}