@import '~@Styles/vars';

.mainContainer {
  height: 100%;
  overflow: scroll;
  background: $menuBackgrounndColor;
}

.mt30{
  margin-bottom: 24px;
  padding-top: 30px;
}

.successModal {
  width: 450px;
  margin: auto;
  padding: 40px 40px 56px 40px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  @media (max-width: $mobile) {
    width: unset;
    margin: 5px;
  }
}

.errorIcon {
  path {
    fill: $red;
  }
}

.tickIcon {
  position: absolute;
  top: 4px;
  right: 8.9rem;
  // @media (max-width: $tablet) {
  //   right: 21.4rem;
  // }
  @media (max-width: $mobile) {
    right: 7.6rem;
  }
}

.mb24 {
  margin-bottom: 24px;
}

.subTitle {
  margin-bottom: 54px;
}

.closeIcon {
  path {
    fill : $red;
  }
}

.textCenter {
  text-align: center;
}

.relPosition {
  position: relative;
  // display: flex;
  // justify-content: center;
}

.mb10 {
  margin-bottom: 10px;
}

.lsp {
  line-height: 130%;
}