@import '~@Styles/vars';

.deathCaseDisplayWrapper {
  margin-bottom: 25px;

  &.sectionsToCol {
    flex-direction: column;
    padding: 0;
  }
  .deathCaseDetailsContainer {
    margin-right: 10px;
  }
}

.greenEllipsis {
  position: absolute;
  z-index: $z-index-1;
  width: 526px;
  height: 526px;
  margin-top: 200px;
  margin-left: 358px;    
  background-color: $ellipsisColor;
  border-radius: $border-radius-round;
}

.greenEllipsis1 {
  position: absolute;
  z-index: $z-index-1;
  width: 445px;
  height: 445px;
  margin-top: 1167px;
  margin-left: 458px;    
  background-color: $ellipsisColor;
  border-radius: $border-radius-round;
}
