@import '~@Styles/vars';

.reviewLayout{
  width: 68%;
}
.buttonSpacing {
  margin-top: 32px;
}


.bottomSpacing{
  margin-bottom: 24px;
}

.messageInfo {
  margin-top: 5px;
  margin-right: 3px;
}