@import '~@Styles/vars';

.bottomSpacing   {
  margin-bottom: 24px;
}

.colContainer {
  display: flex;
  gap: 16px;
  width: 100%;
  @media (max-width: $tablet) {
    width: unset;
  }

  @media (max-width: $mobile) {
    flex-direction: column; 
    gap: 16px;
    width: unset;
  }
}

.normalGrayPaper {
  position: relative;
  width: 70% !important;
  padding: 16px;
  background: $authContentColor;
  @media (max-width: $mobile) {
    width: unset !important; 
    margin: unset !important;
  }
}

.selectedPaper {
  width: 70% !important;
  padding: 16px;
  background: $ellipsisColor;
  border: 4px solid $profRegisterBackground;
  @media (max-width: $mobile) {
    width: unset !important; 
    margin: unset !important;
  }
}

.question {
  gap:9px;
  margin-top: 50px;
  cursor: pointer;
  path {
    fill : $electricBlue;
  }
}

.evenBottomSpacing {
  margin-bottom: 35px;
}

.lcEvenBottomSpacing {
  margin-bottom: 62px;
}

.moveShowLess {
  margin-bottom: 40px;
}

.topSpacing{
  gap:16px;
  margin-top: 24px;
  padding-bottom: 50px;
}

.iconPlacement {
  margin-top: 20px;
}

.handSymbol {
  cursor: pointer;
}

.evenLifeSpacing {
  margin-bottom: 65px;
}

.referCaseLayout {
  width: 70%;
  @media (max-width: $tablet) {
    width: 90%;
  }

  @media (max-width: $mobile) {
    width: unset;
  }
}

.titleRefCase{
  margin-bottom: 24px;
  line-height: 41.86px;
}

.referCaseContainer{
  margin-top: 16px;
  margin-bottom: 56px;
  padding: 31px 31px 31px 20px;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
}

.personContainer{
  width: 48px;
  height: 48px;
  margin-right: 24px;
  padding:3px 1px 3px 3px;
  text-align: center;
  background-color: $violet-light;
  border-radius: $border-radius-round;
}

.confirmButton{
  margin-bottom: 16px;
}

.gap24{
  gap:24px;
}

.flexColumnDisplay{
  display: flex;
  flex-direction: column;
}

.flexStart {
  align-items: flex-start;
}

.mb24 {
  margin-bottom: 21px;
}

.textDecorationColor {
  text-decoration-color: $electricBlue;
}
.mt24{
  margin-top: 24px;
}