@import '~@Styles/vars';

.containers{

  margin-bottom: 16px;
  padding: 16px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
}

.titleIconGap {
  gap: 9px;
}

.buttonSpacing {
  margin-top: 24px;
}

.mb16{
  margin-bottom: 16px;
}

.pb25 {
  @media (max-width: $mobile) {
    padding-bottom: 25px;
  }
}

.mt10 {
  margin-top: 10px;
  cursor: pointer;
} 

.lh24 {
  line-height: 24px;
}

.backButton {
  gap: 8px;
  cursor: pointer;
}

.p24 {
  padding: 24px;
}