@import '~@Styles/vars';
@import '~@Styles/mixins';

.cardContainer {
  position: relative;
  max-width: 300px;
  min-height: 150px;
  padding: 25px;
  color: $lighterBlack;
  background-color: $white;
  border-radius: $border-radius-card;
  box-shadow: 1px 2px 3px rgba($gray, 0.05);
}

.buttonContainer {
  margin-top:130px;
}

.priceContainer {
  display: flex;
  margin-top: 30px;
}

.planTitle {
  border-top: 3px solid $pineGreen;
}

.space {
  margin-top: 20px;
}

.subscribeReplace {
  margin-top: 48px;
}