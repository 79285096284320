@import '~@Styles/vars';

.menuLayout {
  position: fixed;
  top: 0;
  z-index: $z-index2;
  width: 100%;
  height: 73px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 0 10px 0;
  background: $menuBackgrounndColor;
    
  .menuContentLayout {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1175px;
    margin-right: auto;
    margin-left: auto;
      
    .elementorCol20 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 45.305%;
      padding: 0 15px 0 15px;
    } 
    
    .buttonContainer {
      display: flex;
       
      .itemMenuLink {
        margin-right: 40px;
        padding: 0;
        padding-top: 12px;
        padding-left: 30px;
        color: $black;
        font-weight: $normal;
        font-size: $fontSize-16;
        font-style: normal;
        letter-spacing: 0.025em;
        text-decoration: none;
        border-left: 1px solid $breakerColor;
        &:hover {
          color: $electricBlue;
        }
        @media (max-width: $desktop-s) {
          border-left: none;
        }
      }

      .mb4{
        margin-bottom: 3px;
      }
      
    } 
    .burgerIconSpacing {
      margin-top: 0.4rem;
    }
    @media (max-width: $desktop-s) {
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  @media (max-width: $desktop-s) {
    padding: 10px 20px;
  }

}