@import '~@Styles/vars';
.topSpacing{
  margin-top: 5px;
}

.mobileView {
  @media (max-width: $mobile) {
    display: flex;
    gap:16px;
  }
}