@import '~@Styles/vars';

.biggerBottomSpacing {
  margin-bottom: 20px;
}
.title {
  @media (max-width: $tablet) {
    margin-bottom: 10px;
    font-size: $fontSize-18;
  }
}
.button {
  @media (max-width: $tablet) {
    padding: 0 10px;
    font-size: $fontSize-14;
  }
}
.separator {
  width: 100%;
  height: 2px;
  background-color: $dividerColor;
}

.titleBottom{
  margin-bottom: 10px;
}

.infoBottom{
  margin-bottom: 9px;
}

.removeTextSpacing {
  margin-left: 6px;
}

.topSpacing {
  margin-top: 10px;
}

.removeContainer {
  cursor: pointer;
}