@import 'reset';
@import 'vars';
@import 'fonts';
@import 'animations';
@import 'mixins';
@import 'datepicker';
@import 'react-datatable';
@import 'spacing';

:global {
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; 
  }

  body {
    width: 100%;
    min-height: 100%;
    margin: 0;
    color: $buttonTextColor;
    font-weight: $normal;
    font-size: $fontSize-18;
    font-family: $font-family;
    line-height: $line-height;
    background-color: $white;
    background-size: cover;
    background-attachment: fixed;
    text-underline-position: under;
  }

  #app {
    height: 100%;
  }

  #userVerify  div:first-child
  {
    max-width: unset !important;
  }

  *[tabindex]:focus {
    outline: 1px $electricBlue solid;
  }
  
  *[tabindex]:focus:not(:focus-visible) {
    outline: none;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.8rem 0.8rem;
  }

  .p-column-filter-element .p-multiselect{
    max-width: 14rem;
  }

  .p-column-filter-add-rule{
    display: none !important;
  }

  .p-column-filter-operator{
    display: none !important;
  }
  
  .ql-container.ql-snow {
    font-size: $fontSize-18 !important;
    font-family: inherit !important;
    border: 1px solid $gray-light !important;
  }

  .ql-toolbar.ql-snow {
    border-top: 1px solid $gray-light !important;
    border-right: 1px solid $gray-light !important;
    border-bottom: none !important;
    border-left: 1px solid $gray-light !important;
  }

  .ql-editor.ql-blank::before {
    color: $gray-light;
    font-size: $fontSize-14;
    font-family: $helveticaFamily;
    font-style: normal !important;
  }
}
