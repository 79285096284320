@import 'vars';

//Assistant
@font-face {
  font-weight: $normal;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  src: url(./fonts/assistant/Assistant-Regular.ttf) format("truetype");
}
@font-face {
  font-weight: $semiBold;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  src: url(./fonts/assistant/Assistant-SemiBold.ttf) format("truetype");
}
@font-face {
  font-weight: $bold;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  src: url(./fonts/assistant/Assistant-Bold.ttf) format("truetype");
}

//Helvetica
@font-face {
  font-weight: $normal;
  font-family: $helveticaFamily;
  font-style: $fontStyle;
  src: url(./fonts/helvetica/Helvetica.ttf) format("truetype");
}

@font-face {
  font-weight: $bold;
  font-family: $helveticaFamily;
  font-style: $fontStyle;
  src: url(./fonts/helvetica/Helvetica-Bold.ttf) format("truetype");
}