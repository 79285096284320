@import '~@Styles/vars';
@import '~@Styles/mixins';

$scaledDownSize: 0.98;

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 45px 8px 45px;
  color: $buttonTextColor;
  font-weight: $bold;
  font-size: $fontSize-17;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 22px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-decoration: none;
  background-color: $pineGreen;
  border: 0;
  border-radius: $border-radius;
  box-shadow: 0 12px 15px -12px $shadowColor;
  cursor: pointer;
  transition: transform 0.3s;
  will-change: transform;

  path {
    fill: $black;
  }
  // STATES

  &:hover {
    text-decoration: none;
    transform: scale($scaledDownSize);
  }

  &:active {
    text-decoration: none;
    transform: scale($scaledDownSize);
  }

  &.disabled {
    background: $buttonDisabled;
    transform: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.outlined {
    border: 2px solid $white;

    &.disabled {
      background: $buttonDisabled;
      opacity: 0.5;
    }
  }

  &.white {
    background-color: $white;
    background-image: none;
    border: 3px solid $black
  }

  &.black {
    color: $white;
    background-color: $lighterBlack;
    background-image: none;
    border: none;

    path {
      fill: $white;
    }
  }

  &.neutral {
    color: $black;
    background-color: $dividerColor;
    background-image: none;
    border: 1px solid $dividerColor;
    path {
      fill: $black;
    }
  }

  &.transparent {
    padding: 8px 30px;
    letter-spacing: 0.025em;
    background-color: $transparent;
    background-image: none;
    border: 3px solid $buttonTextColor;
    path {
      fill: $footerColor;
    }
    &.disabled {
      // background: $buttonDisabled;
      // border: 3px solid $dividerColor;
      opacity: 0.3;
    }
  }

  &.plain {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    text-transform: none;
    border: 0;

    &.disabled {
      background: $buttonDisabled;
      opacity: 0.5;
    }
  }

  &.success {
    color: $pineGreen;
    font-weight: $medium;
    background-color: $pineGreenLowerOpacity;
    background-image: none;
    border: 2px solid $pineGreenLowerOpacity;
    path {
      fill: $pineGreen;
    }
  }

  &.danger {
    color: $red;
    font-weight: $medium;
    background-color: $lightestRed;
    background-image: none;
    border: 2px solid $lightestRed;
    path {
      fill: $red;
    }
  }

  //SIZES

  &.xsmall {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: unset;
    height: 30px;
    min-height: unset;
    padding: 0;

    svg {
      margin-right: 0;
    }
  }

  &.ss {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: unset;
    height: 40px;
    min-height: unset;
    padding: 10px;

    svg {
      margin-right: 0;
    }
  }

  &.small {
    min-height: 36px;
  }

  &.medium {
    height: 48px;
  }

  &.large {
    min-height: 56px;
    padding: 0 37px;
  }

  &.xlarge {
    min-height: 64px;
    padding: 0 51px;
  }

  &.send {
    padding: 8px 30px 8px 22px;

    @media (max-width: $tablet) {
      padding: 8px 20px 8px 20px;

    }
  }

  &.fill {
    width: 100%;
    height: 48px;
  }
  &:focus{
  
    border: 1px solid $electricBlue;
    outline: none;
  }

  //ICON

  .iconStyling {
    margin-right: 10px;
  }

  @include textOverflow();
}

.spinner {
  path {
    fill: gray;
  }
}

.isFullWidth {
  width: 100%;
}
