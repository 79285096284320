@import '~@Styles/vars';
@import '~@Styles/mixins';

.wrapper {
  transition: background-color 0.2s ease;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: 1px solid $green;
    -webkit-box-shadow: 0 0 0 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $green;
  }

  .input {
    width: 100%;
    max-height: $input-control-height;
    padding: $input-padding;
    font-size: $fontSize-18;
    font-family: inherit;
    border: $input-border;
    border-radius: $border-radius;
    filter: none;
    transition: border 0.2s ease;
    caret-color: $green;

    &.error {
      background: $statusErrorColor;
      border: 1px solid $red;
    }

    &.disabled {
      color: $inputDisabledColor;
    }

    &.withInputIcon {
      padding-left: 40px;
    }

    &::placeholder {
      color: $gray-light;
      font-size: $fontSize-14;
      font-family: $helveticaFamily;
    }

    &:placeholder-shown + .label::before {
      transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
    }

    &:focus {
      border: 1px solid $electricBlue;
      outline: none;

      & + .label::before {
        color: $pineGreen;
        transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
      }
    }
    @include textOverflow();
  }



  .textArea {
    width: 100%;
    padding: $input-padding;
    font-size: $fontSize-18;
    font-family:  $font-family !important;
    white-space: normal!important;
    border: $input-border;
    border-radius: $border-radius;
    filter: none;
    transition: border 0.2s ease;
    caret-color: $green;
     
    &.error {
      background: $statusErrorColor;
      border: 1px solid $red;
    }

    &::placeholder {
      color: $gray-light;
      font-size: $fontSize-14;
      font-family: $helveticaFamily;
    }

    &:placeholder-shown + .label::before {
      transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
    }

    &:focus {
      border: 1px solid $electricBlue;
      outline: none;

      & + .label::before {
        color: $electricBlue;
        transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
      }
    }
  }

  .inputIcon {
    position: absolute;
    top: 14px;
    left: 15px;
  }

  .visibilityToggleIcon {
    position: absolute;
    top: 50%;
    right: 4%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: transform 0.3s, opacity 0.3s;
    path {
      fill: $electricBlue;
    }

    &.error {
      path {
        fill: $red;
      }
    }
  }

  .label {
    position: absolute;
    display: block;
    max-height: 0;
    pointer-events: none;

    &::before {
      position: relative;
      left: 16px;
      display: inline-block;
      padding: 0 5px;
      color: $gray;
      background: white;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
      transform-origin: left top;
      backface-visibility: hidden;
      filter: blur(0);
      transition: transform 0.1s ease;
      content: attr(data-content);
    }
  }

  .hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }
}

.red{
  path{
    fill: $red;
  }
}

.disableResize {
  resize: none;
}
