@import '~@Styles/vars';

.container {
  width: 100%;
  height: 242px;
  background-color: $menuBackgrounndColor;
  box-shadow: 0 4px 23px 4px $shadowColor;
}

.lifeLedgerIcon {
  padding: 24px 195px 12px 8px;
}

.clickSection {
  cursor: pointer;
}

.divider{
  position: absolute;
  right: 12.5%;
  left: 12.23%;
  width: 283px;
  height: 1px;
  background: $dividerColor;
}

.listContainer {
  margin-top:30px;
  margin-left:38px;
  padding: 0;
  list-style: none;
}

// .listClass {
//   position: relative;
//   display: inline-block;
//   width: 16px;
//   height: 16px;
//   margin: 0 1em;
//   color: $white;
//   line-height: 2em;
//   text-align: center;
//   background: $breakerColor;
//   border-radius: $border-radius-round;

//   &::before{
//     position: absolute;
//     top: .9em;
//     left: 126px;
//     z-index: $z-index-1;
//     width: 46px;
//     height: 2px;
//     background: $successColor;
//     content: '';
//   }
  
//   &:first-child::before {
//     display: none;
//   }

// }
// .active {
//   width: 24px;
//   height: 24px;
//   background: $menuBackgrounndColor;
//   border: 3px solid $successColor;
//   ~ li {
//     background: $breakerColor;
//   }
//   ~ li::before {
//     background: $breakerColor;
//   }
// }


.listClass {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 1em;
  color: $breakerColor;
  line-height: 2em;
  background: $breakerColor;
  border-radius: $border-radius-round;
  cursor: pointer;
  
  &::before{
    position: absolute;
    top: 6px;
    left: -52px;
    width: 53px;
    height: .2em;
    background: $breakerColor;
    content: '';
  }
    
  &:first-child::before {
    display: none;
  }
  
}
.active {
  z-index: $zindex-hamburger;
  width: 16px;
  height: 16px;
  background: $successColor;
  //border: 3px solid $successColor;
  ~ li {
    background: $breakerColor;
  }
  ~ li::before {
    background: $breakerColor;
  }
}

.hideUnselectedPageName {
  display: none;
}

.flexDisplay {
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
}

.defaultCircle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: $breakerColor;
  border-radius: $border-radius-round;
}

.buttonControl {
  padding: 0;
  background: none;
  border: none;
}

.stepperLine {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 2px;
  margin: auto;
  background-color: $breakerColor;
}

.completedStepperLine {
  background-color: $successColor;
}

.tickCircle {
  align-items: center;
  width: 26px;
  height: 26px;
  background: $successColor;
  path {
    fill: $white;
  }
}

.activeCircle {
  align-items: center;
  width: 26px;
  height: 26px;
  margin-top: 3px;
  background: $transparent;
  border: 3px solid $successColor; 
}

.pageName {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
}

.mt34{
  margin-top: 29px;
}

.mt7{
  margin-top: 7px;
}