@import '~@Styles/vars';
$shadow-card: 0 0 24px rgba($black, 0.1);

.paper {
  display: inline-block;
  margin: 0 auto;
  // overflow-y: auto;
  background: $white;
  border-radius: $border-radius-toggle;

  &.depth0 {
    box-shadow: $no-shadow;
  }

  &.depth1 {
    box-shadow: $shadow-card;
  }

  &.width-s {
    width: 100%;
    max-width: 328px;
  }

  &.width-mobileaction {
    position: absolute !important;
    bottom:0;
    width: 96%;
    border-radius: $border-radius-toggle;
  }

  &.width-m {
    width: 100%;
    max-width: 512px;
  }
  &.width-ml {
    width: 100%;
    max-width: 577px;
  }

  &.width-581 {
    width: 100%;
    max-width: 582px;
  }

  &.width-672 {
    width: 100%;
    max-width: 672px;
  }

  &.width-mm {
    width: 100%;
    max-width: 650px;
  }

  &.width-banner {
    width: 100%;
    max-width:800px;
    height: 600px;
  }

  &.width-verification {
    width: 100%;
    max-width:672px;
  }


  &.width-l {
    width: 100%;
    max-width: 720px;
  }

  &.width-yoti {
    width: 100%;
    max-width: 566px;
  }

  &.width-success {
    width: 100%;
    max-width: 676px;
  }

  &.width-full {
    width: 100%;
  }

  &.width-transunion {
    width: 100%;
    max-width: 804px;
  }

  &.width-1016 {
    width: 100%;
    max-width: 1016px;
  }

  &.width-1330 {
    width: 100%;
    max-width: 1330px;
  }

  &.marginBottom {
    margin-bottom: 80px;
  }

  &.bordered {
    border: 1px solid $breakerColor;
  }
}
