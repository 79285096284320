@import '~@Styles/vars';

.navigationContainer {
  gap: 1rem;
  margin-top: 25px;
  cursor: pointer;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.skipContainer {
  margin-top: 1rem;
  text-align: center;
}

.searchButtonContainer {
  .searchButton {
    background: white;
    border: 0.2em solid  black;
    &:focus{
  
      border: 0.1em solid $electricBlue;
         
    }
    
  }
  

}

.tabletSkipContainer{
  margin-top: 1.3rem;
  margin-bottom: 5rem;
}

.bottomSpacing {
  margin-bottom: 30px;
}


.bulbIcon {
  margin-top: -12px;
  margin-right: 8px;

  path {
    fill: $footerColor;
  }
}
