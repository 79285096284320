@import '~@Styles/vars';

.bottomSpacing {
  margin-bottom: 17px;
}
  
.marginBotto25 {
  margin-bottom: 25px;
}
  
.rightSpacing {
  padding-right: 10px;
}

.packageCard{
  flex: 1 1 0;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px 12px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  @media (max-width: $mobile) {
    margin: unset;
  }
}

.iconTile {
  gap: 10px 
}

.listItemGap {
  gap:8px;
}

.f18{
  font-size: $fontSize-18;
}

.mt16{
  margin-top: 16px;
}

.ticks{
  flex-shrink: 0;
}

.unavailable {
  flex-shrink: 0;
  path {
    fill: $dividerColor;
  }
}

.rightCardBottomSpacing {
  margin-bottom: 41px;
  // @media (max-width: $mobile) {
  //   margin-bottom: 129px;
  // }
}

.recommendedCard {
  position: relative;
  border: 2px solid $electricBlue;
}

.recommendedStrip {
  position: absolute;
  top:-13px;
  right: 0.2rem;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  width: 116px;
  padding: 2px 16px;
  background: $electricBlue;
  border-radius: $border-radius-18;
}

.tenure {
  margin: 29px 0 0 0;
}

.fromLabel {
  margin-top:33px;
}

.starIcon {
  path{
    fill: $buttonTextColor;
  }
}

.mt10 {
  margin-top: 10px;
  cursor: pointer;
}

.backButton {
  gap: 8px;
  cursor: pointer;
}

.mt40 {
  width: 572px;
  margin-top: 40px;
  margin-left: 25px;
  @media (max-width: $mobile) {
    margin-bottom: 25px;
    margin-left: unset;
  }
}
.pb25 {
  @media (max-width: $mobile) {
    padding-bottom: 25px;
  }
}

.footerColor {
  color: $footerColor;
}

.lineHeight {
  line-height: 29.52px;
}

.mt22{
  margin-top: 22px;
  @media (max-width: $res608) {
    margin-top: 38px;
  }
}

.mt33{
  margin-top: 33px;
  @media (max-width: $res608) {
    margin-top: 47px;
  }
}

.lh24 {
  line-height: 24px;
}

.lh164{
  line-height: 164%;
}

.paddingBody {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding: 14px 8px !important;
  }
}

.gap13{
  gap:13px;
}

.handSymbol {
  cursor: pointer;
}

.mt31{
  margin-top: 25px;
  margin-bottom: 8px;
}

.gap10{
  margin-top: 8px;
  margin-bottom: 11px;
}

.f16 {
  font-size: $fontSize-18;
}

.flexBox {
  display: flex;
  gap: 12px;
  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.gap8{
  margin-top: 8px;
  margin-bottom: 8px;
}

.mb16{
  margin-bottom: 16px;
}

.liGap{
  flex-direction: column;
  gap:8px;
}

.h80 {
  height: 80px;
}

.h70{
  height: 70px;
}

.mtAuto {
  margin-top: auto;
}
