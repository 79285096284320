@import '~@Styles/vars';
@import '~@Styles/mixins';

.layoutContainer {
  margin-bottom: 15px;
  .rightSideContainer {
    &.hasStepper {
      flex-basis: 65%;
      width: 65%;
      max-width: 65%;
      @media (max-width: $tablet) {
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: $tablet) {
      padding-left: unset;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;

    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }
  @media (max-width: $mobile) {
    padding-bottom: 40px;
  }
}

.innerSpacing {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}
