@import '~@Styles/vars';

@for $i from 1 through 13 {
  $width: percentage(calc($i / 12));

  .size#{$i} {
    flex-basis: $width;
    width: $width;
    max-width: $width;

    &:not(.constant) {
      @media (max-width: $tablet - 1) {
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.auto {
  flex-basis: auto;
  width: auto;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.alignCenter {
  margin: auto 0;
}
.textCenterTablet {
  @media (max-width: $tablet) {
    text-align: center;
  }
}

.alignBottom {
  align-self: flex-end;
}
