@import '~@Styles/vars';
@import '~@Styles/mixins';

.settingsMenuComponent {
  position: fixed;
  top: 0;
  left: 235px;
  width: 210px;
  height: 100%;
  background-color: $settingsMenuBgColor;
  animation: mymove 300ms;
  animation-timing-function: ease-out;
  @media (max-width: $tabletSurface) {
    left: 171px;
    width: 181px;
    animation: tabletSurfaceMove 300ms;
  }
  @media (max-width: $tablet) {
    left: 151px;
    width: 160px;
    animation: tabletMove 300ms;
  }
}

@keyframes mymove {
  from {left: 0;}
  to {left:235px;}
}

@keyframes tabletSurfaceMove {
  from {left: 0;}
  to {left:171px;}
}

@keyframes tabletMove {
  from {left: 0;}
  to {left:151px;}
}

.rightSidePosition{
  padding-left: 175px;
  @media (max-width: $tablet) {
    padding-left: 142px !important;
  }

  @media (max-width: $mobile) {
    padding-left: unset !important;
  }
}

.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.6em;
  min-height: 100%;
  .rightSideContainer {
    @media (max-width: $tablet) {
      padding-bottom: 20px;
      padding-left: unset;
    }
    @media (max-width: $mobile) {
      width: 100%;
      padding-bottom: 20px;
    }

  }

  .secondLeftSideContainer {
    // z-index: $z-index1;
    min-width: 209px;
    @media (max-width: $tabletSurface) {
      min-width: unset;
    }
    @media (max-width: $tablet) {
      min-width: 160px;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 260px 1fr;
  }

  @media (max-width: $tabletSurface) {
    grid-template-columns: 167px 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 164px 1fr;
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: 0.3em;

    .leftSide,
    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @media (max-width: $mobile) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
    min-height: unset;
    margin-top: unset;
  }
}

.footerSpacing {
  margin-left: 25px;
}

.overallContainer {
  height: auto;
  padding:2rem;
  font-size: $fontSize-16 !important;
  background: $spDashBackground;
}

.cardBackground {
  padding: 16px 34px 16px 34px; 
  background: $white;
  border-radius: $border-radius-bigger;
} 

.mt34{
  margin-top: 34px;
}

.flex{
  display: flex;
}

.spSetiingMenuComponent{
  top: 0;
  left: 235px;
  width: 210px;
  height: 100%;
  padding-bottom: 325px;
  background-color: $settingsMenuBgColor;
  animation: mymove 300ms;
  animation-timing-function: ease-out;
}