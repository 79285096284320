@import '~@Styles/vars';

.tabSpacing {
  @media (max-width: $mobile) {
    flex-wrap: nowrap;
  }
}

.rightFormLayout{
  padding: 40px 10px 0 32px;
}

.spaceUpAndDown {
  margin: 16px 0 0 0;
}

.bottomSpacing {
  margin-bottom: 35px;
  @media (min-width: $desktop-l) {
    max-width: 746px;
  }
}

.summaryContainer {
  width: 65%;
  margin-top: 24px;
  padding-left: 34px;
  @media (max-width: $tablet) {
    width: 86%;
  }
  @media (max-width: $mobile) {
    width: unset;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.container { 
  @media (max-width: $mobile) {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-mask-image: $tabMask;
  }
 
}

.missingInfoBox {
  margin-bottom: 50px;  
  padding: 16px;
  background: $missingInfoBackground;
  border: 1px solid $red;
  border-radius: $border-radius-toggle;
  @media (min-width: $desktop-l) {
    max-width: 746px;
  }
}

.infoIcon {
  margin-top: 2px;
  padding-right: 10px;
  path {
    fill: $buttonTextColor;
  }
}

.missingItems {
  path {
    fill: $red;
  }
}

.sent {
  padding-left: 38px;
}

.mobileMissingItems {
  @media (max-width: $mobile) {
    padding-left: 35px;
  }
}

.iconPlacement{
  position: absolute;
  margin-top: 8px;
}

.handSymbol {
  cursor: pointer;
}

.starIcon {
  margin-right: 7px;
  path {
    fill: $buttonTextColor;
  }
}

.letterSpacing{
  letter-spacing: 0.045em;
}

.banksCountContainer {
  gap:8px;
  width: fit-content;
  padding: 4px 8px 4px 8px;
  background: $statusSuccessColor;
  border-radius: $border-radius-info;
}

.banksInfoAdd {
  gap: 10px;
  margin-bottom: 16px;
}

.plusIcon {
  path {
    fill: $footerColor;
  }
}

.gap7 {
  gap: 6.3px;
  margin-left: 16px;
}

.businessReferralBlock {
  position: absolute;
  top: 5rem;
  right: 6rem;
}

.logoPlacement{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 10rem;
  // @media (max-width: $tablet) {
  //   margin-bottom: 40px;
  // }
}

.businessReferralLogo {
  max-width: 220px;
  height: auto;
  max-height: 6.4rem;
  @media (max-width: $mobile) {
    max-width: 320px;
  }
}

.hideScroll {
  overflow: hidden;
}

.blueBanner {
  padding: 8px 19px 8px 18px;
  color: $white;
  letter-spacing: 0.045em;
  background: $electricBlue;
  border-radius: $border-radius-10;
  box-shadow: $case-card;

  &:hover {
    opacity: 0.7;
  }
}

.starColor {
  margin-right: 17px;
  path {
    fill: $white;
  }
}

.gap20{
  gap: 20px;
}

.btnUpgrade {
  display: flex;
  justify-content: center;
  padding: 1px 24px;
  color: $menuBackgrounndColor;
  white-space: nowrap;
  border: 2px solid $menuBackgrounndColor;
  border-radius: $border-radius;
  cursor: pointer;
  @media (max-width: $res722) {
    margin-top: 14px;
    margin-bottom: 9px;
  }
}

.closeIcon {
  cursor: pointer;
  path {
    fill: $white;
  }
  @media (max-width: $res722) {
    margin-top: 8px;
  }
}

.wrapDiv {
  flex-wrap: wrap;
  gap:8px;
}

.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100%;
  .rightSideContainer {
    @media (max-width: $tablet) {
      padding-bottom: 20px;
      padding-left: unset;
    }
    @media (max-width: $mobile) {
      width: 100%;
      padding-bottom: 20px;
    }
  }

  .secondLeftSideContainer {
    z-index: $z-index1;
    min-width: 209px;
    @media (max-width: $tablet) {
      min-width: 160px;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 234px 1fr;
  }

  @media (min-width: $greaterThanTablet) and (max-width: $tabletSurface) {
    grid-template-columns: 167px 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 153px 1fr;
  }

  @media (max-width: $tablet) {
    flex-direction: column;

    .leftSide,
    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @media (max-width: $mobile) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
    min-height: unset;
    margin-top: unset;
  }
}


.pl6 {
  margin-top: 24px;
  padding-left: 1.3em;
  @media (max-width: $mobile) {
    margin-top: unset;
    padding-left: unset;
  }
}

.gap16{
  gap:16px;
  align-items: baseline;
  border-radius: $border-radius;
  cursor: pointer;
  &:hover{
    width: fit-content;
    padding-right: 24px;
    background: $hoverButtonColor;
    border-radius: $border-radius;
  }
  @media (max-width: $tablet) {
    margin-left: 2px;
    padding-left: 0;
  }
  @media (max-width: $mobile) {
    flex-wrap: wrap;
    margin-left: unset;
  }
}

.seeDetails {
  white-space: nowrap;
}

.headerContainer {
  padding: 16px 12px 1px 34px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-bottom: unset;
    padding-left: 8px;
  }
}

.mt24 {
  margin-top: 24px;
}

.pl34{
  padding-left: 34px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}
.p8{
  padding: 8px 8px 0 8px;
  @media (max-width: $mobile) {
    background: $settingsHeaderBackground;
  }
}

.closeAndButton{
  gap: 20px;
}

.gap18{
  gap:18px;
}

.res1400MaxBlue {
  @media (min-width: $desktop-l) {
    max-width: 1119px;
  }
}

.res1400MaxMain{
  @media (min-width: $desktop-l) {
    max-width: 930px;
  }
}

.res1400MaxDocs{
  @media (min-width: $desktop-l) {
    max-width: 800px;
  }
}

.res1400MaxSums{
  @media (min-width: $desktop-l) {
    max-width: 750px;
  }
}

.alignSelf {
  align-self: flex-start;
  @media (max-width: $lessThanTablet) {
    align-self: unset;
    margin-bottom: 8px;
    margin-left: 14px;
  }
  @media (max-width: $mobile) {
    flex-direction: row !important;
    gap:1rem;
    align-items: center;
    margin-left: unset;
  }
}

.lh3 {
  line-height: 3rem;
}

.gap2{
  flex-wrap: wrap;
  @media (max-width: $desktop-1181) {
    gap:2rem;
  }
}
