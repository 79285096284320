
@import '~@Styles/vars';

.wrapperBlock {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 213px;
  padding: 84px 162px 44px;
  text-align: center;
  background-color: $fileUploadBackground;
  border: 2px dashed $fileUploadBorder;

  cursor: pointer;
  &.unsetMinHeight {
    @media (max-width: $mobile) {
      min-height: unset;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
 
  @media (max-width: $tablet) {
    margin-bottom: 10px;
  }
  @media (max-width: $mobile) {
    padding: unset;
  }
}

.margintop32{
  margin-top: 32px;
  margin-bottom: 32px;
}

.topSpacing {
  margin-top: 5px;
}

.emptyDocumentContainer {
  min-height: 214px;
  margin-top: 35px;
}

.recentlyUploadedContainer {
  height: 94%;
  margin-bottom: 10px;
}

.mb8{
  margin-bottom: 8px;
}