@import '~@Styles/vars';

.container {
  text-align: center;
}

.bottomSpacing {
  margin-bottom: 40px;
}

.termsLink {
  color: $buttonTextColor;
}