@import '~@Styles/vars';

.dcLayoutContainer {
  min-height: 100%;
}

.tabSpacing {
  @media (max-width: $mobile) {
    flex-wrap: nowrap;
  }
}

.bottomSpacing {
  margin-bottom: 35px;
}

.missingInfoBox {
  margin-bottom: 50px;  
  padding: 16px;
  background: $missingInfoBackground;
  border: 1px solid $red;
  border-radius: $border-radius-toggle;
}

.infoIcon {
  margin-top: 2px;
  padding-right: 10px;
  path {
    fill: $buttonTextColor;
  }
}

.missingItems {
  path {
    fill: $red;
  }
}

.availableItems {
  path {
    fill: $successColor;
  }
}

.iconPlacement{
  position: absolute;
  margin-top: 8px;
}

.sent {
  padding-left: 38px;
}

.handSymbol {
  cursor: pointer;
}

.spaceUpAndDown {
  margin: 16px 0 0 0;
}

.hideScroll {
  overflow: hidden;
}

.mobileMissingItems {
  @media (max-width: $mobile) {
    padding-left: 35px;
  }
}

.container { 
 
  @media (max-width: $mobile) {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-mask-image: $tabMask;
  }
 
}

.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100%;
  .rightSideContainer {
    @media (max-width: $tablet) {
      padding-bottom: 20px;
      padding-left: unset;
    }
    @media (max-width: $mobile) {
      width: 100%;
      padding-bottom: 20px;
    }
  }

  .secondLeftSideContainer {
    z-index: $z-index1;
    min-width: 209px;
    @media (max-width: $tablet) {
      min-width: 160px;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 234px 1fr;
  }

  @media (min-width: $greaterThanTablet) and (max-width: $tabletSurface) {
    grid-template-columns: 167px 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 153px 1fr;
  }

  @media (max-width: $tablet) {
    flex-direction: column;

    .leftSide,
    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @media (max-width: $mobile) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
    min-height: unset;
    margin-top: unset;
  }
}

.rightSideGap {
  margin-left: 34px;
  @media (max-width: $tablet) {
    margin-left: 14px;
  }
 
  @media (max-width: $mobile) {
    margin-left: unset;
    padding-right: 9px;
    padding-left: 9px;
  }
}

.mobileCompanyBlockSpacing {
  @media (max-width: $mobile) {
    padding-right: 9px;
    padding-left: 9px;
  }
}

.gapping {
  @media (max-width: $mobile) {
    padding-right: 9px;
    padding-left: 9px;
  }
}

.shortenWidth {
  @media (min-width: $mobile) and (max-width: $tablet) {
    width: 88% !important;
  }

  @media (min-width: $desktop-l) {
    max-width: 778px;
  }
}

.readyDCHeader {
  margin-bottom: 20px;
  padding-top: 55px;
  padding-bottom: 1px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding-top: unset;
    padding-bottom: unset;
  }
}

.gap16{
  flex-wrap: wrap;
  gap:16px;
  align-items: baseline;
  margin-right: 16px;
  margin-left: 26px;
  padding-left: 8px;
  border-radius: $border-radius;
  cursor: pointer;
  &:hover{
    width: fit-content;
    padding-right: 24px;
    background: $hoverButtonColor;
  }
  @media (max-width: $mobile) {
    margin-left: 4px;
  }
  @media (max-width: $tablet) {
    flex-wrap: wrap;
    margin-left: 14px;
    padding-left: 0;
  }
}


.seeDetails {
  margin-right: 4rem;
  white-space: nowrap;
}

.summaryContainer {
  width: 65%;
  margin-top: 24px;
  padding-left: 34px;
  @media (max-width: $tablet) {
    width: 86%;
  }
  @media (max-width: $mobile) {
    width: unset;
    padding-right: 8px;
    padding-left: 8px;
  }
}
.res1400Max {
  @media (min-width: $desktop-l) {
    max-width: 1037px;
  }
}

.res1400SummaryMax {
  @media (min-width: $desktop-l) {
    max-width: 780px;
  }
}

.businessReferralBlock {
  position: absolute;
  left: 47.3rem;
  display: flex;
  align-items: center;
  // top: 10rem;
  // right: 33.4rem;
  margin-left: 0; /* Added margin for spacing */
  @media (max-width: $res940) {
    position: unset;
  }
}

.pl38{
  padding-left: 38px;
  @media (max-width: $tablet) {
    padding-left: 18px;
  }
  @media (max-width: $desktop-s) {
    margin-top: 20px;
  }
}

.businessReferralLogo {
  max-width: 220px;
  height: auto;
  max-height: 6.4rem;
  @media (max-width: $mobile) {
    max-width: 320px;
  }
}

.mr4 {
  margin-right: 8px;
}

.alignSelf {
  align-self: flex-start;
  @media (max-width: $lessThanTablet) {
    align-self: unset;
    margin-bottom: 8px;
    margin-left: 14px;
  }
  @media (max-width: $mobile) {
    flex-direction: row !important;
    gap:1rem;
    align-items: center;
  }
}

.lh3 {
  line-height: 3rem;
}

.gap2{
  flex-wrap: wrap;
  @media (max-width: $desktop-1181) {
    gap:2rem;
  }
}

