@import '~@Styles/vars';
@import '~@Styles/mixins';
  
.cardContainer {
  position: relative;
  margin-bottom: 10px;
  padding: 25px;
  color: $lighterBlack;
  background-color: $white;
  border-radius: $border-radius-card;
  box-shadow: 1px 2px 3px rgba($gray, 0.05);
}


.buttonSpacing{
  margin-left: 10px;
}

.sendButtonSpacing{
  margin-top: 5px;
}

.mtop20{
  margin-top: 20px;
}
  
.pagination__link__active 
  {
  a {
    color: $white;
    background: $pineGreen;
    border: none;
  }
}
  
.pagination__link__disabled 
  {
  a {
    color: $gray;
    border: 1px solid $gray-light
  }
}

.chatInputBox {
  min-height: 70px;
  max-height: 75px !important;
  background-color: $gray-lighter;
}

.dateDisplay {
  justify-content: center;
  margin-bottom: 10px;
}

.header {
  padding: 16px 24px 12px 24px;
  border-bottom: 1px solid $dividerColor
}

.internalSpacing {
  padding:24px
}

.rightSpacing {
  margin-right: 8px
}

.emptyMsgSpacing {
  margin-top:10px;
  text-align: center;
}

.chatMessagesContainer{
  height: 300px;
  overflow-y: auto;
}


.logoWrapper {
  align-items: center;
  justify-content: center;
  // margin-top: 0.5rem;
  margin-right: 0.2rem;
  border-radius: $border-radius-round;
  img {
    max-width: 40px;
    max-height: 40px;
    border-radius: $border-radius-round;
  }
}

.emptyMessageContainer{
  height: 300px;
}

.chatMessageSpacing {
  line-height: 22.96px;
}

.bigBottomSpacing {
  margin-bottom: 25px;
}

.messageBoxContainer {
  display: flex;

  .messageInputBox {
    width: 90%;
  }
}


.personContainer{
  width: 40px;
  height: 40px;
  margin-right: 0.2rem;
  background: $accent;
  border-radius: $border-radius-round;
  
  .iconSpacing {
    margin-top: 5px;
  }
}

.chatMessageText {
  font-size: $fontSize-14;
}