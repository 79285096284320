@import '~@Styles/vars';

.contentLineSpacing {
  line-height: 31px;
}

.bulbIcon {
  path {
    fill: $buttonTextColor;
  }
}