@import '~@Styles/vars';

.spacing {
  margin-top: 17px;
  margin-bottom: 1px;
}

.tipContainer {
  gap: 8px;
  width: 77%;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
  border-radius: $border-radius-toggle;

  @media (max-width: $tablet) {
    width: unset !important;
  }
}

.iconSpacing {
  margin-right: 8px;

  path {
    fill: $buttonTextColor;
  }
}

.leftSide {
  padding-top: 16px;
  padding-left: 16px;
}

.rightSide {
  padding-top: 9px;
  padding-right: 40px;
  padding-bottom: 16px;
  line-height: 19.68px;
}

.tipBoxSpacing {
  gap: 12px;
}

.tipContentSpacing {
  line-height: 22.96px;
}

.handSymbol {
  cursor: pointer;
}

.topSpacing {
  margin-top: 54px;
}

.align {
  padding-left: 16px;
}

.titleOtherSpacing {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 40.3%;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (max-width: $tablet) {
    width: unset;
  }
}

.titleOtherSpacing:before {
  width: 8px;
  margin-right: 16px;
  background: #F3F3F3;
  border: 2px;
  content: "";
}

.bulbIcon {
  flex-shrink: 0;
  margin-right: 8px;

  path {
    fill: $footerColor;
  }
}

.mt10 {
  margin-top: 10px;
}

.pl8 {
  padding-left: 8px;   
}

.pl5{
  padding-left: 0.5px;
}

.bottomSpacing{
  margin-bottom: 17px;
}

.modalTopSpacing{
  margin-top: 7px;
}

.lh24{
  line-height: 26.24px;
}

.mt17{
  margin-top: 17px;
}

.containerPadding {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}