@import '~@Styles/vars';

.extraInfoBox {
  width: 374px !important;
  height: 147px;
  padding: 8px 32px 8px 8px;
  @media (max-width: $mobile) {
    width: 100% !important;
  }

}

.messageInfo {
  flex-shrink: 0;
  margin-right: 3px;
}

.topSpacing {
  margin-top: 40px;
}

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-16;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.bottomSpacing{
  margin-bottom: 10px;
}

.providerSubtitle {
  margin-top: 10px;
  line-height: 1.5;
} 

.shiftTextBox {
  padding-right: 38px;
}

.bigBottomSpacing{
  margin-bottom: 30px;
}

.container {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}