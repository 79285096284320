@import '~@Styles/vars';

.contactsContainer {
  margin-bottom: 20px;
}

.userContactDetails {
  padding-left: 10px;

  svg {
    min-width: 14px;
  }
}

.extraNote {
  margin-top: 5px;
}

.yotiContainer {
  display: flex;
  align-items: center;
  color: $gray;
  font-style: italic;
  svg {
    margin-right: 5px;
    margin-left: 5px;
  }
  @include textOverflow();
}

.yotiLogo {
  padding-top: 5px;
}

.deceasedContainer {
  margin-bottom: 24px;
}

.normalGrayPaper {
  background: $authContentColor;
}

.professionalPaper {
  background: $fileUploadBackground;
}

.sameHeight {
  flex: 1 1 auto;
}