
@import '~@Styles/vars';

.bottomSpacing {
  margin-bottom: 10px;
  @media (max-width: $tablet) {
    width: 83%
  }
  @media (max-width: $mobile) {
    width: unset;
  }
}

.navigationContainer {
  margin-top: 30px;
}

.leftSpacing {
  margin-left: 10px;
}

.documentSpacing {
  margin-top:5px;
}

.fileContainer {
  width: 60%;
  @media (max-width: $tablet) {
    width: 83%
  }
  @media (max-width: $mobile) {
    width: unset;
  }
}

.backToPrevStep {
  justify-content: start;
  height: auto !important;
  margin-top: 30px;
  padding: 0;
  color: $backToPrevStep;
  font-size: $fontSize-17;
  line-height: 22px;
  text-decoration: underline;
  border: none !important;

  &:hover {
    text-decoration: none;
    transform: none;
  }
}

.notificationForm {
  max-width: 558px;
  margin-right: auto;
  margin-left: auto;
}

.notificationContinueButton {
  max-width: 442px;
  margin-right: auto;
  margin-left: auto;
}

.mb30{
  margin-bottom: 30px;
}

.mb18{
  margin-bottom: -18px;
}
