@import '~@Styles/vars';
.spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.links {
  text-decoration-line: underline;
  color: $electricBlue;
  text-underline-position: below;
  font-weight: $semiBold;
}

.buttonPlacing {
  margin-top: 40px;
  margin-bottom: 10px;
}
.signedOutContainer {
  height: 38px;
  margin: 24px 0;
  padding: 8px 8px 8px 18px;
  background: $ellipsisColor;
}

.f14{
  font-size: $fontSize-14;
}
