@import '~@Styles/vars';

.mainContainer {
  height: 100%;
  background: $menuBackgrounndColor;
  @media (max-width: $mobile) {
    height: unset;
    background: $white;
  }
}

.mt30{
  margin-bottom: 24px;
  padding-top: 30px;
}

.successModal {
  height:100%;
  margin: auto;
  padding: 29px 30px 29px 32px;
  overflow: scroll;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  @media (max-width: $mobile) {
    width: unset;
    margin: 5px;
    padding: 16px 8px 0 8px;
    border-radius: $unset;
    box-shadow: $unset;
  }
}

.institueContainer{

  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(132px, 1fr));
  // display: flex;
  // flex-wrap: wrap;
  gap: 24px;

  .instituteSection{
    width: 100%;
    // @media (max-width: $tabletSurface) {
    //   width: 142px;
    // }

    // @media (max-width: $mobile) {
    //   flex-grow: 1;
    // }
  }

  .instituteBox {
    display: flex;
    justify-content: center; 
    height: 56px; 
    padding-top:9px;
    padding-bottom: 9px; 
    border: 1px solid $dividerColor;
    border-radius: $border-radius-toggle;
    cursor: pointer;

    &:hover {
      background: $hoverButtonColor;
    }

    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}



.profLogo{
  width: 38px;
  height: 38px;
  object-fit: contain;
  border-radius: $border-radius-round;
}

.mb10{
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border-new;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-14;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.visibilityToggleIcon {
  position: absolute;
  transform: translateX(-31px) translateY(6px);
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $dividerColor;
  }
}

.relPosition {
  position: relative;
}

.whiteBoxContainer {
  height: 81%;
  padding-right: 155px;
  padding-left: 155px;
  @media (max-width: $desktop-s) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (max-width: $mobile) {
    padding: unset;
  }
}

.backButtonSpacing {
  path {
    fill: $buttonTextColor;
  }
}

.backFlex {
  gap: 10px;
  cursor: pointer;
}

.cautionBox {
  margin-bottom: 32px;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.alphabet {
  margin-top: 28px;
}

.noLogoContainer {
  display: flex;
  justify-content: center;
  width: 38px;
  height: 38px;
  color: $white;
  font-weight: $bold;
  background: $violet-light;
  border-radius: $border-radius-round;
}

.chooseBankRowGap {
  gap: 185px;
}

.mb24{
  margin-bottom: 24px;
}

.mt24{
  margin-top: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb32{
  margin-bottom: 32px;
}

.handSymbol{
  cursor: pointer;
}