@import '~@Styles/vars';
/* The container */
.checkbox {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
  color: $lighterBlack;
  white-space: normal;
  cursor: pointer;
  user-select: none;
  .label {
    padding-left: 0.5em;
    font-size: $fontSize-16;
    line-height: 1.33;
  }
  .subLabel {
    color: $footerColor;
    font-size: $fontSize-16;
  }
  input {
    display: none;
    &[type='checkbox'] {
      & ~ .inputBox {
        background-color: $white;
        border-radius: $border-radius;
        &:after {
          position: absolute;
          bottom:3px;
          left: 4px;
          width: 9px;
          height:16px;
          border: 0.5px solid $electricBlue;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          opacity: 0;
          transition: opacity 0.3s;
          content: '';
        }
      }
      &:checked ~ .inputBox {
        border: 0.5px solid $checkboxBorder;
        &:after {
          opacity: 1;
          animation: bounceIn 0.5s ease-in-out;
        }
      }
    }
    &[type='radio'] {
      & ~ .inputBox {
        border-radius: $border-radius-round;
        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 16.36px;
          height: 16.36px;
          background: $pineGreen;
          border-radius: $border-radius-round;
          transform: scale(0.001) translate(-50%, -50%);
          transform-origin: left;
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
          content: '';
        }
      }
      &:checked ~ .inputBox:after {
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
      }
    }
  }
  .inputBox {
    position: relative;
    flex-shrink: 0;
    // top: 2px;
    // left: 0;
    width: 20px;
    height: 20px;
    background: $radioButtonBackground;
    border: 2px solid $radioButtonBackground;
    transition: background-color 0.3s, box-shadow 0.3s;
    &.disabled {
      filter: grayscale(100);
    }
    &.error {
      box-shadow: 0 0 0 1pt $red;
    }
  }
}

.consentLabel {
  padding-left: 0.5em;
  font-size: $fontSize-16;
  line-height: 1.33;
}

@keyframes bounceIn {
  0% {
    transform: scale(0) rotate(45deg);
  }
  80% {
    transform: scale(1.1) rotate(45deg);
  }
  100% {
    transform: scale(1) rotate(45deg);
  }
}