@import '~@Styles/vars';

.welcomeContainer {
  padding: 0 16px 0 16px;
  text-align: center;

  .lifeLedgerIcon {
    padding: 41.34px 0 31.75px 0;
    @media (max-width: $tablet) {
      padding: 41.34px 0 31.75px 0;
    }

    @media (max-width: $mobile) {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: $mobile) {
    padding: unset;
  }
}

.mb40 {
  margin-top: 8px;
  margin-bottom: 40px;
  padding: 0 38px;
}

.mb24 {
  margin-bottom: 24px;
}
