@import '~@Styles/vars';

.formContainer {
  position: relative;
  width: 475px;
  margin-top: 130px;
  margin-bottom: 51px;
  padding: 24px 32px 24px 24px;
  background: $authContentColor;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  opacity: unset !important;
  @media (max-width: $tablet) {
    width: 342px;
   
  }
  @media (min-width: $mobile) and (max-width: $tablet) {
    width: unset;
    margin-top: 16px;
    margin-right: 16px;
    margin-bottom: 26px;
    margin-left: 16px;
  }
  @media (max-width: $mobile) {
    width: 100%;
    margin-top: unset;
    margin-right: unset;
    margin-bottom: 26px;
    margin-left: unset;
  }
}

.absoluteContainer {
  position: absolute;
  width: 475px;
  margin-top: 160px;
  margin-bottom: 51px;
  padding: 24px 32px 24px 24px;
  background: $authContentColor;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  opacity: unset !important;
  @media (max-width: $tablet) {
    width: 352px;
  }
  @media (min-width: $tablet) and (max-width: $desktop-1181) {
    width: unset;
    margin-right: 16px;
    margin-left: 16px;
  }
  @media (max-width: $mobile) {
    width: unset;
    margin-top: unset;
    margin-right: 16px;
    margin-left: 16px;
  }
}

.spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.signUpContainer {
  margin-top: 20px;
  border-top: 1px solid $breakerColor;
}

.links {
  text-decoration-line: underline;
  color: $buttonTextColor;
  text-underline-position: below;
}

.checkBoxLabel {
  font-size: $fontSize-16;
}