@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  margin-bottom: 16px;
  &.noMargin {
    margin-bottom: 0;
  }
  h2 + h4 {
    margin-top: 10px;
  }

  p {
    margin-bottom: 5px;
  }
  @media (max-width: $tablet) {
    margin-bottom: 20px;
  }
}
