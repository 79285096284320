@import '~@Styles/vars';
@import '~@Styles/mixins';

.checkbox {
  display: flex;
  align-items: center;
  padding: 0.3em 0;
  color: $lighterBlack;
  white-space: normal;
  cursor: pointer;
  user-select: none;
  input {
    display: none;

    &[type='radio'] {
      & ~ .inputBox {
        flex-shrink: 0;
        border-radius: $border-radius-round;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 16.36px;
          height: 16.36px;
          background: $pineGreen;
          border-radius: $border-radius-round;
          transform: scale(0.001) translate(-50%, -50%);
          transform-origin: left;
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
          content: '';
        }
      }

      &:checked ~ .inputBox:after {
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  .inputBox {
    position: relative;
    // top: 2px;
    // left: 0;
    width: 24px;
    height: 24px;
    background: $radioButtonBackground;
    transition: background-color 0.3s, box-shadow 0.3s;

    &.disabled {
      filter: grayscale(100);
    }
    &.error {
      box-shadow: 0 0 0 1pt $red;
    }
  }

  .labelSpacing {
    padding-left: 0.5em;
    word-break: break-all;
  }
}

.radioButtonContainer {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 35px;
  color: $lighterBlack;
  white-space: normal;
  cursor: pointer;
  user-select: none;
  .label {
    font-size: $fontSize-16;
    line-height: 1.33;
  }
  .subLabel {
    color: $gray;
    font-size: $fontSize-14;
  }
  input {
    display: none;

    &[type='radio'] {
      & ~ .inputBox {
        flex-shrink: 0;
        border-radius: $border-radius-round;

        &:after {
          position: absolute;
          top: 51%;
          left: 50%;
          width: 20px;
          height: 20px;
          background: $pineGreen;
          border-radius: $border-radius-round;
          transform: scale(0.001) translate(-50%, -50%);
          transform-origin: left;
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
          content: '';
        }
      }

      &:checked ~ .inputBox:after {
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  .inputBox {
    position: absolute;
    top: 20px;
    left: 120px;
    width: 30px;
    height :30px;
    background: $white;
    border: 2px solid $pineGreen;
    transition: background-color 0.3s, box-shadow 0.3s;

    &.disabled {
      filter: grayscale(100);
    }
    &.error {
      box-shadow: 0 0 0 1pt $red;
    }
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0) rotate(45deg);
  }

  80% {
    transform: scale(1.1) rotate(45deg);
  }

  100% {
    transform: scale(1) rotate(45deg);
  }
}
