:global {
  .rdt {
    position: relative;
    height: 50px;
  }
  .rdtPicker {
    position: absolute;
    z-index: $zindex-dateInput !important;
    display: none;
    width: 285px;
    margin-top: 1px;
    padding: 4px;
    background: $white;
    border: 1px solid $gray;
    border-radius: $border-radius;
    box-shadow: $shadow-card;
    @media (max-width: $mobile) {
      width: 240px;
    }
  }

  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    position: static;
    box-shadow: $no-shadow;
  }

  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }

  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
  }
  .rdtPicker td {
    line-height: 39px;
    vertical-align: middle;
    cursor: pointer;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    color: $white;
    vertical-align: middle;
    background: $pineGreen;
    border-radius: $border-radius-round;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: $gray-lightest;
  }
  .rdtPicker td.rdtToday {
    position: relative;
    font-weight: $semiBold;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    color: $white;
    background-color: $pineGreen;
    border-radius: $border-radius-round;
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: $white;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    color: $gray;
    background: none;
    cursor: not-allowed;
  }

  .rdtPicker td span.rdtOld {
    color: $gray;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    color: $gray;
    background: none;
    cursor: not-allowed;
  }
  .rdtPicker th {
    margin-bottom: 20px;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    color: $pineGreen;
    vertical-align: middle;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    padding: 20px 10px;
    font-weight: $normal;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-weight: $normal;
    font-size: $datePickerFontSize;
  }

  .rdtPrev span,
  .rdtNext span {
    display: block;
    user-select: none;
  }

  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    color: $gray;
    background: none;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: $gray-lightest;
  }

  .rdtPicker tfoot {
    border-top: 1px solid $gray-lightest;
  }

  .rdtPicker button {
    background: none;
    border: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: $gray-lightest;
  }

  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }

  td.rdtMonth,
  td.rdtYear {
    width: 25%;
    height: 50px;
    line-height: 68px;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: $gray-lightest;
  }

  .rdtCounters {
    display: inline-block;
  }

  .rdtCounters > div {
    float: left;
  }

  .rdtCounter {
    width: 40px;
    height: 100px;
  }

  .rdtCounterSeparator {
    line-height: 100px;
  }

  .rdtCounter .rdtBtn {
    display: block;
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: $gray-lightest;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: $datePickerFontSize;
  }

  .rdtMilli {
    width: 48px;
    padding-left: 8px;
    vertical-align: middle;
  }

  .rdtMilli input {
    width: 100%;
    margin-top: 37px;
    font-size: $datePickerFontSize;
  }

  .rdtTime td {
    cursor: default;
  }
}
