@import '~@Styles/vars';

.fullContainer {
  display: flex;
}

.leftSection {
  width: 45%;
  padding:20px 40px 10px 40px;
  background: $white;
}

.rightSection {
  width: 55%;
  padding-right: 40px;
  background: $cookieDescBackground;
  box-shadow: inset 0 0 19px 0 $shadowColor;
}

.title {
  margin-bottom: 0 0 .5em 0;
  padding:20px 40px 10px 40px;
}

.bottomSpacing15{
  margin-bottom: 15px;
}

.bottomSpacing10{
  margin-bottom: 10px;
}

.cookieTypeContainer{
  margin-bottom: 10px;
  padding: 8px 20px 8px 20px;
  font-weight: $bold;
  font-size: $fontSize-13;
  background: $gray-lighter;
  border-radius: $border-radius-5;
  cursor: pointer;
  opacity: 1;
}

.selectedCookieContainer{
  margin-bottom: 10px;
  padding: 8px 20px 8px 20px;
  font-weight: $bold;
  font-size: $fontSize-13;
  border: 1px solid $buttonBorderColor;
  border-radius: $border-radius-5;
  opacity: 1;
}

.bottomSpacing30{
  margin-bottom: 50px;
}

.titleContainer {
  border-bottom: 1px solid rgba(50,50,50,.1);
}

.rightPanel{
  padding:20px 0 10px 40px;
}

.cookieTitle {
  margin: 0 0 .45em 0;
}

.switch {
  margin-right: 10px;
}

.buttonSpacing {
  margin: 0 0 8px 18px;
}

.closeIcon {
  position: absolute;
  top: 25px;
  right: 25px;
}

.mobileLayout{
  margin-top: 5rem;
  padding: 10px 20px 10px;
}

.contentSpacing {
  padding: 20px 20px 25px;
  border-right: 1px solid $buttonBorderColor;
  border-left: 1px solid $buttonBorderColor;
}

.mobileCookieHeader{
  padding: 8px 20px 8px 20px;
  font-weight: $bold;
  background: $gray-lighter;
  border: 1px solid $buttonBorderColor;
  cursor: pointer;
  opacity: 1;
}

.buttonDistance{
  gap:16px;
  padding-bottom: 24px;
}

.mt10 {
  margin-top: 10px;
}