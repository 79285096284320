@import '~@Styles/vars';

@for $i from 1 through 13 {
  $width: percentage(calc($i / 12));

  .grid-size#{$i} {
    flex-basis: $width;
    width: $width;
    max-width: $width;

    @supports (display: grid) {
      flex-basis: 100%;
      grid-column: span $i;
      width: 100%;
      max-width: 100%;
      &:not(.constant) {
        @media (max-width: $tablet) {
          grid-column: span 12;
        }
      }
    }

    @media (max-width: $tablet) {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

.alignSelfBottom{
  align-self: end;
}
