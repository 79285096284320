@import '~@Styles/vars';

.spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonCenter {
  text-align: center;
}

.links {
  text-decoration-line: underline;
  color: $electricBlue;
  text-underline-position: below;
}

.error {
  color: $red;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  
  path{
    fill: $red;
  }
}

.withErrorIcon {
  align-items: center;
  width: 100%;
  margin-top: 1px;
}
.message {
  vertical-align: top;
}
.iconPlacing {
  margin-right:6px;
}

.guidance{
  line-height: 19.68px;
}

.passwordCorrect {
  color: $successColor;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  
  path{
    fill: $successColor;
  }
}


.googleButton {
  width: 100%;

  div {
    margin-left: 2px;
    padding: 8px !important;
  }
  span {
    width: 100%;
    font-weight: $bold !important;
    font-size: $fontSize-17;
    text-align: center;
  }
}

.mt16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.w50 {
  width: 50% !important;
}

