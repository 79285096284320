@import '~@Styles/vars';

.paragraphSpacing {
  margin-top: 20px;
}

.linkSpacing {
  margin-left: 15px;
}

.topSpacing {
  margin-top: 15px;
}