
@import '~@Styles/vars';

.infoBox {
  gap: 8px;
  padding: 16px 40px 16px 16px;
  border-radius: $border-radius-toggle;
}
  
.infoIcon {
  margin-top: 2px;
  path {
    fill: $buttonTextColor;
  }
}
.fs14 {
  margin: 0;
  padding: 0;
  font-size: $fontSize-14;
}

.title {
  padding-left: 10px;
}