@import '~@Styles/vars';

.titleBottom{
  margin-bottom: 10px;
}

.infoBottom{
  margin-bottom: 9px;
}

.removeTextSpacing {
  margin-left: 6px;
}

.topSpacing {
  margin-top: 10px;
}

.removeContainer {
  cursor: pointer;
}

.relationshipLayout {
  width:-moz-fit-content;
  width: fit-content;
  padding: 4px 12px;
  color: $white;
  background: $buttonTextColor;
  border-radius: $border-radius-43;
  
}
.relationshipGap {
  flex-wrap: wrap;
  gap:2px;
}

.fullWidth {
  width: 100%;
}
