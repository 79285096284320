@import '~@Styles/vars';
@import '~@Styles/mixins';

.buttonSpacing {
  margin-right: 5px;
}

.buttonDataField {
  float: right;
  margin-bottom: 5px;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: 68px;
}

.displayOrderContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: 22px;
}

.tipContainer{
  gap: 8px;
  background:$adminBgColor;
  border: 1px solid $electricBlue;
  border-radius: $border-radius-toggle;
}

.titleContainer {
  width: 58%;
  margin-bottom: 8px;
}

.iconSpacing{
  margin-right: 8px;
  path {
    fill: $buttonTextColor;
  }
}

.leftSide {
  padding-top: 16px;
  padding-left:16px;
}

.rightSide {
  padding-top: 9px;
  padding-right: 40px;
  padding-bottom: 16px;
  line-height: 19.68px;
}

.addCompanyBlock {
  margin-top:30px;
  margin-bottom:20px;
  padding: 40px 20px 40px 20px;
  border: 3px dashed $footerColor;
  border-radius: $border-radius-toggle;
}

.requiredFieldContainer {
  margin-bottom: 8px;
  padding: 24px 31px 24px 16px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
}

.formSection {
  width: calc(max(75%, 500px));
  margin-top: 55px;
  padding-left: 25px;
  @media (max-width: $tablet) {
    width: calc(max(90%, 365px));
  }
}

.handSymbol {
  cursor: pointer;
}

.previousSpacing {
  margin-top: 30px;
}

.topSpacing {
  margin-top: 20px;
}

.previewContainer {
  padding: 32px 24px;
  background: $white;
  border-radius: $border-radius-toggle;
}


.logoWrapper {
  display: flex;
  align-items: center;
  margin-right: 6px;
  border-radius: $border-radius-round;
  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-round;
  }
}

.leftSpacing {
  margin-left: 10px;
}

.closeIcon {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  path{
    fill: $buttonTextColor;
  }
}

.containersWidth {
  width: 90%;
}

.blueStrip {
  height: 4px;
  background: $electricBlue;
  border-radius: $border-radius-35;
}