@import '~@Styles/vars';

.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;
  button {
    &:first-child {
      margin-right: 10px;
    }
  }

  &.column {
    flex-direction: column;
  }
}

.formContainer {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.bottomSpacing {
  margin-bottom: 20px;
}

.mt18{
  margin-top: 18px;
}
