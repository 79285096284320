@import '~@Styles/vars';

.containers {
  margin-top: 30px;
  margin-bottom: 16px;
  padding: 16px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
}

.titleIconGap {
  gap: 9px;
}

.buttonSpacing {
  margin-top: 24px;
}

.bodyContainer{
  width: 100%;
  max-width: 627px;
}

.manualButton{
  height: 80px;
  margin-top: 15px;
  background-color:$white;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px rgba(0, 0, 0, 0.10);
  cursor: pointer;
}

.textHeight{
  line-height: 5rem;
}

.link{
  margin-top: 21px;
  color: $backToPrevStep;
  a{
    color: $backToPrevStep;
  }
}