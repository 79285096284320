@import '~@Styles/vars';

.spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonCenter {
  text-align: center;
}

.links {
  text-decoration-line: underline;
  color: $darkOrange;
  text-underline-position: below;
}

.verticalLine {
  height: 177px;
  margin-top:10px;
  border-right: 1px solid $dividerColor;
}

.bereavementSection {
  width: 80%;
  margin-bottom: 10px;
  padding: 16px 16px 16px 16px;
  background-color: $pineGreen;
  border-radius: $border-radius-toggle;
  box-shadow: 0 24px 30px -14px $shadowColor;
  cursor: pointer;
  @media (max-width: $mobile) {
    width: 100%;
  }
}

.professionalSection {
  width: 80%;
  padding: 16px 16px 16px 16px;
  background-color: $authContentColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 24px 30px -14px $shadowColor;
  cursor: pointer;
  @media (max-width: $mobile) {
    width: 100%;
  }
}

.container {
  @media (max-width: $tablet) {
    margin-top:100px;
    margin-left: 100px;
  }

  @media (max-width: $mobile) {
    margin-top:unset;
    margin-left: unset
  }
}

