@import '~@Styles/vars';
@import '~@Styles/mixins';

.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }
  }
  
  &.column {
    flex-direction: column;
  }
}
.modalHeader {
  margin-bottom: 20px;
  font-weight: $semiBold;
  font-size: $fontSize-24;
  line-height: 31px;
}

.formContainer {
  flex-basis: 100%;
  width: 100%;
  min-height: 400px;
}

.providerRoleSection {
  margin-top: 24px;
}
