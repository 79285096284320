@import '~@Styles/vars';

h1,
h2,
h4,
h5 {
  font-weight: $semiBold;
}

h1 {
  font-size: $fontSize-h1;
}

h2 {
  font-size: $fontSize-h2;
  @media (max-width: $tablet) {
    font-size: $fontSize-24;
  }
}

h3 {
  font-weight: $thin;
  font-size: $fontSize-h3;
}

h4 {
  font-weight: $normal;
  font-size: $fontSize-h4;
}

h5 {
  font-size: $fontSize-h5;
}

p{
  margin: 0;
  padding: 0;
}

.htmlContent {
  position: relative; //Placeholder prop in case this class will be used
}

.withNormalLineHeight {
  line-height: 1.9;
}
//Weights

.bold {
  font-weight: $semiBold;
}

.bolder {
  font-weight: $bold;
}

.normalFontWeight {
  font-weight: $normal;
}

//Alignment
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}

//Colors
.black {
  color: $black;
}

.lightBlack {
  color: $lighterBlack;
}
.green {
  color: $green;
}

.pineGreen {
  color: $pineGreen;
}

.gray {
  color: $gray-light;
}

.gray2 {
  color: $gray2
}

.white {
  color: $white;
}

.red {
  color: $red;
}

.orange {
  color: $orange;
}

.darkOrange{
  color: $darkOrange;
}

.mobileDescColor {
  color: $mobileDescColor;
}

.newGray{
  color: $buttonTextColor;
}

.yellowishOrange {
  color: $yellowishOrange
}

.footerColor {
  color: $footerColor;
}

.profRegisterBackground {
  color: $profRegisterBackground;
}

.successColor{
  color: $successColor;
}

.dividerColor{
  color: $dividerColor;
}

.electricBlue{
  color: $electricBlue;
}

.gray3 {
  color: $gray3;
}

.mixedGray {
  color: $mixedGray;
}

//Sizes

.xxs {
  font-size: $fontSize-10;
}

.xs {
  font-size: $fontSize-12;
}

.xss {
  font-size: $fontSize-13;
}

.s {
  font-size: $fontSize-14;
}

.m {
  font-size: $fontSize-16;
}
.mb{
  font-size: $fontSize-17;
}

.l {
  font-size: $fontSize-18;
}

.lb {
  font-size: $fontSize-19;
}

.lbx {
  font-size: $fontSize-20;
}

.lcx {
  font-size: $fontSize-21;
}

.xl {
  font-size: $fontSize-24;
}

.xxl {
  font-size: $fontSize-32;
}

.xxxl {
  font-size: $fontSize-43;
}

.h1l {
  font-size: $fontSize-54;
}

.helvetica {
  font-family: $helveticaFamily;
}

.palatino {
  font-family: $title-font-family;
}

.Montserrat{
  font-family: $MontserratFamily;
}

.underline {
  text-decoration: underline;
  text-underline-position: under;
}

.textCenter {
  text-align: center;
}
