@import '~@Styles/vars';
@import '~@Styles/mixins';

.userContainer {
  margin-bottom: 10px;
  padding: 18px;
  background-color: $white;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $case-shadow;
}

.pendingUsersContainer {
  margin-bottom: 10px;
  padding: 18px;
  background-color: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $case-shadow;
}



.container {
  &.noMargin {
    margin-bottom: 0;
  }
  h2 + h4 {
    margin-top: 10px;
  }

  p {
    margin-bottom: 5px;
  }

  &.Select__control {
    max-height: 2px!important;
  }
  @media (max-width: $tablet) {
    margin-bottom: 20px;
  }
}

.topSpacing5{
  margin-top:5px;
}

.floatRight{
  float: right;
  @media (max-width: $tablet) {
    float: unset;
  }
}

.adminPanel {
  padding: 3px 12px 7px 10px;
  background: $adminBgColor;
  border-radius: $border-radius-info;

  path {
    fill : $electricBlue;
  }
  
}

.regularPanel {
  padding: 3px 12px 7px 10px;
  background: $dividerColor;
  border-radius: $border-radius-info;
  
  path {
    fill : $white;
  }
  
}

.personIcon {
  position: absolute;
  margin-top: 7px;
}

.roleText {
  padding-left: 25px;
}

.viewNotificationIcon {
  margin-top: 6px;
  margin-right: 5px;
}

.deathDateSpacing{
  margin-right: 8px;
}

.clickable {
  cursor: pointer;
}

.gap4 {
  gap:4px;
  padding-left: 8px;
}