.mt10 {
  margin-top: 10px;
  padding-bottom: 70px;
}

.category{
  margin-bottom: 12px;
  margin-left: 4px;
}

.subCategory{
  margin-bottom: 20px;
  margin-left: 16px;
  cursor: pointer;
}