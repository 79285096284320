@import '~@Styles/vars';

.orBorder {
  border-bottom: 1px solid $dividerColor;
}

.orSpacing{
  gap:25px;
  margin-top:32px;
  margin-bottom: 32px;
}

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    color: $gray-light;
    font-size: $fontSize-18;
    font-family: $helveticaFamily;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.textBoxSpacing {
  margin-top: 10px;
}

.leftSpacing {
  margin-left: 10px;
}

.container {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.mt20 {
  margin-top: 24px;
}
