@import '~@Styles/vars';
@import '~@Styles/mixins';

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-16;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown+.label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    &+.label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }

  @include textOverflow();
}

.gap16 {
  gap: 16px;
}

.mt28 {
  margin-top: 28px;
}

.notifyContainer {
  width: 444px;
  margin-top: 16px;
  margin-bottom: 40px;
  padding: 32px 32px 40px 24px;
  background: $white;
  border: 1px solid $borderGray;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
}

.commentsLayout {
  gap: 20px;
  padding-top: 22px;
  padding-bottom: 22px;

}

.nameDate {
  gap: 15px;
}

.fs18{
  letter-spacing: 0.015em;
  white-space: pre-wrap;
  word-break: break-all;
  ol, ul {
    padding-left: 19px;
  }
}

.separator {
  border-bottom: 1px solid $verticalSeparatorColor;
}

.personContainer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: $border-radius-round;
}

.quillArea {
  width: 100%;

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    &+.label::before {
      color: $electricBlue;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
}

.quillHeight {
  height: 107px !important;
}

.mt40 {
  gap: 20px;
  margin-top: 40px;
}

.mt8 {
  gap: 10px;
  width: 50%;
  margin-top: 8px;
  margin-left: 65px;
}

.mt60 {
  gap: 10px;
  width: 50%;
  margin-top: 60px;
  margin-left: 65px;
}

.buttonWidth {
  width: 50%
}

.cancelButton {
  background: $white !important;
  border: 1px solid $black !important;
}

.labelFont {
  margin: 15px 0 1px 0;
  font-weight: $bold;
}