
@import '~@Styles/vars';

.transUnionIntro {
  width: calc(min(600px, 80%));
}

.navigationContainer {
  margin-top: 30px;
  padding-bottom: 8rem;
}

.checkBoxContainer {
  margin-top: 40px;
  font-size: $fontSize-16 !important;
  font-family: $helveticaFamily;
}

.transUnionHeader {
  font-weight: $bold;
  font-size: $fontSize-18;
  line-height: 30px;
}

.transUnionInfo {
  padding: 20px 0;
  font-weight: $normal;
  font-size: $fontSize-16;
  font-family: $helveticaFamily;
  line-height: 23px;
}

.notificationForm {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}
