@import '~@Styles/vars';
$shadow-card: 0 0 24px rgba($black, 0.1);

.paper {
  display: inline-block;
  padding: 24px;
  overflow-y: auto;
  background: $menuBackgrounndColor;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;

  &.depth0 {
    box-shadow: $no-shadow;
  }

  &.depth1 {
    box-shadow: $shadow-card;
  }

  &.width-m {
    width: 100%;
    max-width: 446px;
  }

  &.width-banner {
    width: 100%;
    max-width:800px;
    height: 600px;
  }

  &.width-verification {
    width: 100%;
    max-width:800px;
    height: 280px;
  }


  &.width-l {
    width: 100%;
    max-width: 720px;
  }

  &.width-yoti {
    width: 100%;
    max-width: 804px;
    height: 464px;
  }
  &.width-full {
    width: 100%;
  }

  &.width-transunion {
    width: 100%;
    max-width: 804px;
  }

  &.marginBottom {
    margin-bottom: 80px;
  }

  &.bordered {
    border: 1px solid $gray-lightest;
  }
}

.noInsideSpacingPaper {
  padding:unset !important;
  box-shadow: 0 4px 23px 4px $shadowColor;
}
