@import '@Styles/vars';

$footerLinkColor: #ffffff;
$links: 18px;
$iconSize: 30px;

.footerWrapper {
  background-color: $gray-border;

  .footerContent {
    padding: 50px 158px 55px 114px;
    .elementorContainer {
      position: relative;
      display: flex;
      width: 100%;
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;

      .socialIcon {
        .iconWidths {
          width: 0.8em;
          font-size: $fontSize-27;
          opacity: 0.7
        }
      }
      .elementorCol20 {
        width: 60%; 

        .elementorWidgetWrap {
          display: flex;
          flex-direction: column;
          text-align: left;
          .textSpacing {
            margin-bottom: 10px;
          }
          @media (max-width: $tablet) {
            text-align: center;
          }
        }

        @media (max-width: $tablet) {
          width: unset;
        }
      }
      .elementorCol15 {
        display: flex;
        flex-direction: column;
        width: 23%;
        padding-left: 120px;

        .textSpacing {
          margin-bottom: 10px;
        }

        .socialIcon{
          display: flex;
          justify-content: flex-end;
          margin-top: 148px;

          .iconWidths {
            width: 0.8em;
            font-size: $fontSize-27;
            opacity: 0.7
          }
          @media (max-width: $tablet) {
            margin-top: 110px !important;
          }
          @media (max-width: $mobile) {
            margin-top: 80px !important;
          }
        } 
        @media (max-width: $desktop-983) {
          width: 30% !important;
          padding-left: 20px !important;
        }
        @media (max-width: $desktop-1323) {
          width: 25%;
          padding-left: 44px;
        }   
        @media (max-width: $tablet) {
          display: unset !important;
          width: unset !important;
          padding-left: unset !important;
          text-align: center;
        }
      }

      .elementorHelp {
        display: flex;
        flex-direction: column;
        width: 13%;
        padding: 0 0 0 24px; 

        .textSpacing {
          margin-bottom: 10px;
        }

        .socialIcon {
          display: flex;
          justify-content: flex-start;
          margin-top: 77px;
        }

        @media (max-width: $tablet) {
          display: unset;
          padding: unset;
          text-align: center;
        }
      }

      .elementorLegal {
        display: flex;
        flex-direction: column;
        width:13%; 
        padding: 0 0 0 50px; 

        .textSpacing {
          margin-bottom: 10px;
        }

        @media (max-width: $tablet) {
          width: unset;
          padding: unset;
          text-align: center;
        }
      }

      .hide {
        display: none;
      }
      .elementorAddress {
        display: flex;
        flex-direction: column; 
        justify-content: flex-end;
        width: 100%;
        padding: 49px 10px 10px 10px;
        text-align: left;
        
        .textSpacing {
          margin-bottom: 10px;
        }

        @media (max-width: $tablet) {
          width: unset; 
          padding:10px;
          text-align: center;
        }
      }

      .border{
        width: 50%;
        margin-top: 6px;
        margin-left: 10px;
        border-top: 1px solid #FFFFFF4D;
      }

      .elementorPrivacy {
        display: flex;
        flex-direction: row;
        gap:35px;
        margin-top: 16px;
        margin-left: 10px;
        @media (max-width: $tablet) {
          display: unset;
          margin-bottom: 10px;
          margin-left: unset;
        }
      }

      a{
        text-decoration: none;
      }
      @media (max-width: $tablet) {
        display: unset;
      }
    }
    
    @media (max-width: $tablet) {
      display: block;
      padding: 50px 0;
    }
  }

  .logoWrapper {
    padding: 10px 0 0 0;

    .elementorContainer {
      position: relative;
      display: flex;
      width: 100%;
      max-width: 1262px;
      margin-right: auto;
      margin-left: auto;

      .ledgerIcon {
        width: 30%;
        padding: 10px;

        @media (max-width: $tablet) {
          width: unset;
          padding: unset;
          text-align: center;
        }
      }

      .otherIcons {
        width: 50%;
        padding: 10px;
        text-align: right;
        
        .cyberIconContainer {
          padding: 20px 0 20px 0;
        }

        @media (max-width: $tablet) {
          width: unset;
          padding: unset;
          text-align: center;
        }
      }

      .iconWidths {
        width: 0.8em;
      }

      .socialIcon {
        display: flex;
        justify-content: space-around;
        width: 18%;
        padding: 25px 0 30px 30px;
        text-align: right;

        @media (max-width: $tablet) {
          gap: 25px;
          justify-content: center;
          width: unset;
          padding: unset;
        }

      }

      @media (max-width: $tablet) {
        display: block;
      }
    }

    @media (max-width: $tablet) {
      padding: unset;
    }
  }

  @media (max-width: $tablet) {
    height: unset;
  }
}

.socialIcon{
  display: flex;
  gap: 100px;
  justify-content: center;
  margin: 20px 0 10px 0;
  font-size: $fontSize-27;
}

.mb10{
  margin-bottom: 10px;
}

.handSymbol {
  cursor: pointer;
}
