@import '~@Styles/vars';

.bottomSpacing {
  margin-bottom: 8px;
}

.emptyContainer {
  padding: 11px 17px 11px 17px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
  @media (max-width: $mobile) {
    height: unset;
  }
}

.emptyIcon {
  path{
    fill: $dividerColor
  }
}

.messageWidth{
  max-width: 382px;
  margin-right: auto;
  margin-left: auto;
}

.emptyNotificationContainer {
  height: 104px;
  padding: 11px 17px 11px 17px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
  @media (max-width: $mobile) {
    height: unset;
  }
}

.handSymbol {
  cursor: pointer;
}

.cautionBox {
  width: 91.7%;
  margin-bottom: 8px;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
  @media (max-width: $mobile) {
    width: unset;
  }
}

.blueCircle {
  width: 10px;
  height: 10px;
  background: $electricBlue;
  border-radius: $border-radius-round;
}

.mt45 {
  gap:1rem;
  // justify-content: space-between;
  margin-top: 45px;
  @media (max-width: $tablet) {
    gap:24px;
  }
}

.gap14{
  gap:14px;
}

.gap13{
  gap:11px;
}

.blueBorder {
  height: 93px;
  padding-left: 4px;
  border-right: 2px solid $electricBlue;
}

.transparentBorder {
  height: 93px;
  padding-left: 4px;
  border-right: 2px solid $transparent;
}

.packageBox {
  padding: 24px 16px 32px 16px;
  background: $white;
  // border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
}

.mt24 {
  margin-top: 24px;
}

.tenure {
  margin: 29px 0 0 0;
}

.fromLabel {
  margin-top: 33px;
}

.ticks {
  flex-shrink: 0;
}

.listItemGap {
  gap: 8px;
}

.lineHeight {
  line-height: 29.52px;
}

.mt18{
  margin-top: 18px;
}

.mt12 {
  margin-top: 12px;
}

.packageCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 19px 12px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  @media (max-width: $mobile) {
    margin: unset;
  }
}

.recommendedCard {
  position: relative;
  border: 2px solid $electricBlue;
}

.recommendedStrip {
  position: absolute;
  top: -13px;
  right: 0.2rem;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  width: 116px;
  padding: 2px 16px;
  background: $electricBlue;
  border-radius: $border-radius-18;
}

.f16 {
  font-size: $fontSize-16;
}

.footerColor {
  color: $footerColor;
}

.mb21 {
  margin-bottom: 21px;
}

.unavailable {
  flex-shrink: 0;
  path {
    fill: $dividerColor;
  }
}