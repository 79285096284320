@import '~@Styles/vars';

.bottomSpacing   {
  margin-bottom: 24px;
}

.container {
  width: 60%;
  @media (max-width: $mobile) {
    width: unset;
  }
}

.bigTopSpacing{
  margin-top: 130px;
}

.titleSelect {
  path {
    fill: $electricBlue;
  }
}
.spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}
