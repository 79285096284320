@import '~@Styles/vars';

.actions {
  display: flex;
  flex: 1;
  margin-top: 12px;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }
  }

  &.column {
    flex-direction: column;
  }
  @media (max-width: $mobile) {
    margin-top: 180px;
  }
}
.formContainer {
  flex-basis: 100%;
  width: 100%;
  min-height: 400px;
}
.providerTitle {
  margin: 10px 0;
  color: $lighterBlack;
}

.providerSubtitle {
  margin-top: 10px;
  line-height: 1.5;
}

.link {
  display: block;
  padding-top: 20px;
}

.radioTitle {
  margin-bottom: 10px;
}

.sendButtonSpacing {
  margin-top: 40px;
}

.buttonGaps {
  margin-top: 20px;
}

.cautionBox {
  margin-top: 16px;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.handSymbol {
  cursor: pointer;
}

.innerCircle {
  display: inline-block;
  width: 56px;
  height: 56px;
  margin:3px;
  border-radius: $border-radius-round;
}

.profLogo{
  width: 56px;
  height: 56px;
  border-radius: $border-radius-round;
}

.profLogoCompany {
  gap: 16px;
  margin-top: 33px;
}

.choosCompanyOpaque {
  margin-top: 28px;
  opacity: 0.4;
}

.companyFormModal {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding: 16px 8px;
  }
}

.spSelected {
  margin-top: 8px;
}

.initialLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-36;
  background: $violet-light;
  border-radius: $border-radius-bigger;
}

.pb40{
  padding-bottom: 40px;
  @media (max-width: $mobile) {
    padding-bottom: 100px;
  }
}