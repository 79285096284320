@import '~@Styles/vars';

.topSpacing{
  margin-top: 10px;
  margin-bottom: 17px;
}

.topSpacing15{
  margin-top: 15px;
}

.cautionBox {
  margin-top: 7px;
  margin-bottom: 17px;
  background: $inProgessBackgroundColor;
  border: 1px solid $yellowishOrange;
}