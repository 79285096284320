@import '~@Styles/vars';

.container {
  padding: 7px;
}

.topSpacing {
  padding-top:7px;
}

.handSymbol {
  cursor: pointer;
}

.blockAccess {
  z-index: $z-index1;
  background: $blockAccessColor !important;
  pointer-events: none;
}

.lockIcon {
  position: absolute; 
  right: 0; 
  left: 0;
  z-index: $z-index1; 
  width: 100px; 
  margin-right: auto; 
  margin-left: auto;
  path {
    fill: $white;
  }
}

.flexBox {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.alignCenter {
  margin: auto 0;
}

.cardHover {
  cursor: pointer;
  &:hover{
    background: $hoverButtonColor;
  }
  @media (max-width: $tablet) {
    padding: 15px 24px 24px 24px !important;
  }
}

.mt12 {
  margin-top: 11px;
  @media (max-width: $tablet) {
    padding: 10px 20px;
  }
}

.filePreview {
  cursor: pointer;
  &:hover {
    .overlay {
      opacity: 0.3;
    }
  }
}

.overlay {
  position: absolute;
  width: 64px;
  height: 46px;
  padding: 2px 0 0 11px;
  background: $fileHover;
  transition: .5s ease;
}

.eyeIcon {
  path {
    fill: $white;
  }
}

.hide {
  display: none;
}

.mobileViewLink {
  margin-top: 15px;
  margin-left: 19px;
}

.mb10{
  margin-bottom: 10px;
}