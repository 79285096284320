@import '~@Styles/vars';

.settingsMenuContainer {
  padding-top: 70px;
  padding-left: 28px;
  color: $settingsMenuFontColor;

  .title {
    margin-bottom: 50px;
    font-weight: $bold;
    font-size: $fontSize-24;

    span {
      display: flex;
      align-self: center;
      font-size: $fontSize-24;
      line-height: 31px;

      &.iconSpacing {
        margin-right: 10px;

        .settingsIcon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .settingsSection {
    margin-bottom: 40px;
    cursor: pointer;
  }
  .settingsHeader {
    margin-bottom: 8px;
    line-height: 20px;
    opacity:0.5;
  }

  path {
    fill: $settingsMenuFontColor;
  }
  .linksContainer {
    .logoutContainer {
      margin-top: 100px;
      @media (max-width: $tablet) {
        display: none;
      }
    }
    .navigationItem {
      margin-bottom: 10px;
      cursor: pointer;
   
      .navigationLinkContainer {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        width: 100%;
        color: $settingsMenuFontColor;
        
        &.active {
          position: relative;
        }
        &.active:after {
          position: absolute;
          right: 0;
          width: 6px;
          height: 100%;
          background-color: $settingsSelectedBorderColor;
          content: '';
          // @media (max-width: $tablet) {
          //   content: none;
          // }
        }

        a {
          color: $settingsMenuFontColor;
          font-size: $fontSize-16;
        }
        @media (max-width: $tablet) {
          padding-right: 25px;
        }
      }
      .activeIcon {
        path {
          fill: $settingsSelectedBorderColor;
        }
      }
      .iconSpacing {
        margin-right: 10px;
      }
      // @media (max-width: $tablet) {
      //   min-height: 60px;
      //   border-right: unset;
      //   border-bottom: 2px solid $dividerColor;
      // }
    }
  }
  @media (max-width: $tablet) {
    padding-left:10px;
  }
}

.isvalidationUnfinishedSpacing {
  line-height: 1.2rem;
}

.settingsMenuItem {
  color: $settingsMenuFontColor;
  font-size: $fontSize-16;
}
