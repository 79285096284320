@import '~@Styles/vars';

.bottomSpacing {
  margin-bottom: 24px;
}

.icon {
  margin-top: 8px;
  margin-right: 10px;
}

.buttonSpacing {
  margin: 16px 0 24px 0;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: $border-radius-round;
  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-round;
  }
}

.addCompanyBlock {
  margin-top:30px;
  margin-bottom:20px;
  padding: 20px;
  border: 3px dashed $footerColor;
  border-radius: $border-radius-toggle;
}

.caseBox{
  width: 83%;
  margin-top: 30px;
  padding: 25px;
  border: 1px solid $dividerColor;
  border-radius: $border-radius-card;

  @media (max-width: $mobile) {
    width: unset;
  }
}

.missingInfoBox {
  margin-bottom: 24px;
  padding: 16px;
  background: $missingInfoBackground;
  border: 1px solid $red;
  border-radius: $border-radius-toggle;
}

.infoIcon {
  margin-top: 2px;
  padding-right: 10px;
  path {
    fill: $buttonTextColor;
  }
}

.topSpacing {
  margin-top: 3px;
}

.missingItems {
  margin-top: 7px;
  path {
    fill: $red;
  }
}

.handSymbol {
  cursor: pointer;
}

.sent {
  padding-left: 38px;
}

.companyNamePlacement {
  margin-top: 15px;
}

.outerCircle {
  display: inline-block;
  width: 68px;
  height: 68px;
  margin-top: 4px;
  margin-right: 16px;
  background: $white;
  border-radius: $border-radius-round;
  box-shadow: $case-card;
}

.innerCircle {
  display: inline-block;
  width: 62px;
  height: 62px;
  margin:3px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-round;
}

.profLogo{
  width: 62px;
  height: 62px;
  border-radius: $border-radius-round;
}

.uploadOuterCircle {
  display: inline-block;
  width: 68px;
  height: 68px;
  margin-top: 4px;
  margin-right: 16px;
  background: $white;
  border-radius: $border-radius-round;
  box-shadow: $case-card;

  &:hover {
    background: $circleHover;
  }
}

.uploadInnerCircle {
  display: inline-block;
  width: 62px;
  height: 62px;
  margin:3px;
  padding: 20px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-round;

  &:hover {
    background: $circleHover;
  }
}

.uploadIcon {
  transform: rotate(180deg);
  path {
    fill: $buttonTextColor;
  }
}

.caseBoxSpacing{
  margin-top: 30px;
}

.noWhiteSpace {
  white-space: nowrap;
}



