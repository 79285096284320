@import '~@Styles/vars';

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.personContainer{
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: $border-radius-round;
}

.commentsLayout {
  gap:20px;
  padding-top: 22px;
  padding-bottom: 22px;
  
}

.nameDate {
  gap:15px;
}

.fs18{
  letter-spacing: 0.015em;
  white-space: pre-wrap;
  word-break: break-all;
  ol, ul {
    padding-left: 19px;
  }
}

.textArea {
  width: 100%;
  height: 107px;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family:  $font-family !important;
  white-space: normal!important;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;
     
  &.error {
    background: $statusErrorColor;
    border: 1px solid $red;
  }

  &::placeholder {
    color: $gray-light;
    font-size: $fontSize-14;
    font-family: $helveticaFamily;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $electricBlue;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
}

.quillArea {
  width: 100%;
  height: 107px !important;

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $electricBlue;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
}

.mt40{
  gap: 20px;
  margin-top: 40px;
}

.mt8{
  gap: 10px;
  width:50%;
  margin-top: 8px;
  margin-left: 65px;
}

.mt60{
  gap: 10px;
  width:50%;
  margin-top: 60px;
  margin-left: 65px;
}

.buttonWidth{
  width:50%
}

.cancelButton{
  background: $white !important;
  border: 1px solid $black !important;
}

.separator {
  border-bottom: 1px solid $verticalSeparatorColor;
}

.mb40{
  width: 50%;
  margin-bottom: 40px;
}