@import '@Styles/vars';

.textContainer {
  padding: 70px 0 25px 0;
}

.logosContainer {
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 48px;
  padding: 0 0 70px 0;

  @media (max-width: $tablet) {
    padding: 29px;
  }
  @media (max-width: $mobile) {
    padding: unset;
  }
}

.opaque{
  color: $newsLetter;
  opacity: 0.8;
}

.newsIcons{
  path{
    fill: $newsLetter;
  }
}
