.mb20{
  margin-bottom: 20px;
}

.mb8{
  margin-bottom: 8px;
}

.mb50{
  margin-bottom: 50px;
}