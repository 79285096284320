@import '~@Styles/vars';

.error {
  // position: absolute;
  color: $red;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  &.withErrorIcon {
    align-items: center;
    width: 100%;
    margin-top: 1px;
  }
  .message {
    vertical-align: top;
  }
  .iconPlacing {
    margin-top: 2px;
    margin-right:6px;
  }
  path{
    fill: $red;
  }
}

.normalError {
  color: $red;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  &.withErrorIcon {
    align-items: center;
    width: 100%;
  }
  .message {
    vertical-align: top;
  }
  .iconPlacing {
    margin-top: 2px;
    margin-right: 6px;
  }
  path{
    fill: $red;
  }
}
