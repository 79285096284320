@import '~@Styles/vars';

.bulbColor {
  path {
    fill: $dividerColor;
  }
}

.welcomeContainer {
  padding: 0 60px 0 60px;
  text-align: center;
  @media (max-width: $mobile) {
    padding: unset;
  }
}

.mb28 {
  margin-bottom: 28px;
}