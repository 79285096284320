@import '@Styles/vars';

.logosContainer {
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 35px;
  padding: 35px 0 2rem 0;
  @media (max-width: $tablet) {
    padding: 35px 0 2rem 0;
  }
}

.letterFonts {
  font-weight: $thin;
  font-size: $fontSize-36;
  line-height: normal;
  @media (max-width: $tablet) {
    font-size: $fontSize-27;
  }
}

.pt80{
  padding-top: 2rem;
}

.border{
  width: 1px;
  height: 50px;
  border-right: 1px solid $buttonTextColor;
}

.displayNone {
  @media (max-width: $tablet) {
    display: none;
  }
}
