
@import '~@Styles/vars';
@import '~@Styles/mixins';

.layoutHorizontal {
  display: flex;
  .elementorNavMenu {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    list-style: none;
    .itemMenuLink {
      padding: 13px 20px;
      color: $black;
      // font-weight: $normal;
      font-size: $fontSize-16;
      font-style: normal;
      letter-spacing: 0.025em;
      text-decoration: none;
      &:hover {
        color: $electricBlue;
      }
    }
    .chevronIconWrapper {
      margin-left: 0.5rem;
    }

    li:hover ul {
      display: block;
      margin-top: 0.5rem;
      background-color: $menuBackgrounndColor;
      border-radius: $border-radius-sub-menu;
    }

    .sub {
      position: absolute;
      z-index: $zindex-dateInput;
      display:none;
      padding: 0;
      
      li {
        display: block;
        margin: 20px;
        padding: 20px;
        line-height: 1.3rem;

        &:hover {
         
          background-color: $subMenuLinkColor;
          border-radius: $border-radius-sub-menu;
        }

        a {
          color: $black;
          text-decoration: none;
        }

      }
    }
    .totalHeader{
      display: flex;
      width: 100%;
      .inner {
        width: 80%;
        margin-right:50px !important;

        .mainHead {
          margin-bottom: 4px;
        }

        .bodyText {
          color: $footerColor;
          font-size: $fontSize-16;

        }
      }
      .arrow {
        padding: 20px;
      }
    }
  }
}