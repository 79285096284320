@import '~@Styles/vars';

.actions {
  display: flex;
  flex: 1;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-left: 10px;
      @media (max-width: $tablet) {
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }

  &.column {
    flex-direction: column;
  }
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.bottomSpacing{
  padding-bottom: 20px;
}

.title {
  margin-bottom: 10px;
  font-size: $fontSize-16 ;
}

.lockIcon {
  path {
    fill: $dividerColor;
  }
}
