@import '~@Styles/vars';

.bulbColor {
  path {
    fill: $dividerColor;
  }
}

.authorityContainer {
  margin-top: 18px;
  margin-bottom: 10px;
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding: unset;
  }
}

.confirmContainer {
  margin-top: 4px;
  padding: 0 76.5px 0 76.5px;
  line-height: 26.24px;
  opacity: 0.7;
  @media (max-width: $mobile) {
    padding: unset;
  }
}

.lh {
  line-height: 31.39px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb24 {
  margin-bottom: 24px;
}

.mt32 {
  margin-top: 32px;
}

.gridContainer {
  padding: 16px 16px 24px;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $case-shadow;
  cursor: pointer;
}

.label {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 0 4px;
}

.checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkboxContent {
  line-height: 24px;
  text-underline-position: under;
}
