
@import '~@Styles/vars';

.kpiContainer {
  width: 214px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
}

.statusContainer {
  padding: 9px 0 19px 0;
}

.rightSpacing {
  margin-right: 5px;
}

.kpiValueClass {
  font-weight: $bold;
  font-size: $fontSize-43;
}

.boxBottomSpacing {
  padding-bottom: 40px;
}