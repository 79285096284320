@import '~@Styles/vars';

.input {
  max-height: $input-control-height;
  padding: 10px 9px 8px 10px;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &.error {
    background: $statusErrorColor;
    border: 1px solid $red;
  }

  &.disabled {
    color: $inputDisabledColor;
    background: $disabled;
    pointer-events: none;
  }


  &::placeholder {
    color: $gray-light;
    font-size: $fontSize-14;
    font-family: $helveticaFamily;
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.flexGap{
  gap: 16px;
}

.ddMMWidth {
  width: 4rem;
}

.yyWidth {
  width: 6rem;
}