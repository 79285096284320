@import '~@Styles/vars';

$offset: 187;
$duration: 1.4s;

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullscreen {
    width: 100vw;
    height: 100vh;
  }

  svg {
    animation: rotator $duration linear infinite;
  }

  .spinnerPath {
    transform-origin: center;
   
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
  }

  .spinnerColor {
    animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
  }

  .grayColor {
    animation: dash $duration ease-in-out infinite, grayColors ($duration * 4) ease-in-out infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes colors {
  0% {
    stroke: $green;
  }

  100% {
    stroke: $blue;
  }
}

@keyframes grayColors {
  0% {
    stroke: $dividerColor;
  }

  100% {
    stroke: $dividerColor;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
