@import '~@Styles/vars';

.bodyContainer{
  display: flex;
  align-items: center;
  width: auto;
  height: 76px;
  background-color: $authContentColor;
  border-radius: $border-radius-toggle;
  cursor: pointer;
}

.flex{
  position: absolute;
  left: 17px;
  display: flex;
  gap: 24px;
  align-items: center;
}

.headerContainer{
  padding: 10px 42px 40px 42px;
}

.mb10{
  margin-bottom: 10px;
}

.mb56{
  margin-bottom: 38px;
}
