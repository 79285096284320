@import "~@Styles/vars";
@import "~@Styles/mixins";

.container {
  position: relative;
  height: 100%;

  .tooltip {
    display: none;
  }

  &:hover .tooltip {
    display: inline-block;
  }
  .listContainer {
    margin: 0.5em 0;
    white-space: pre-wrap;

    .listItem {
      display: flex;
      flex-basis: 100%;
      flex-direction: row;
      margin-bottom: 7px;
      padding: 8px 10px;
      color: $buttonTextColor;
      font-weight: $fontStyle;
      font-size: $fontSize-16;
      font-family: $AssistantFamily;
      font-style: $fontStyle;
      line-height: 164%;
      letter-spacing: 0.045em;

      .flexDisplay {
        display: flex;
        gap:5px;
      }

      .label {
        flex-grow: 1;
        padding: 0.25em;

        &.active {
          font-weight: $bold;
        }
      }
      .icon {
        padding: 0.25em;

        &.active {
          color: $successColor;
        }
      }
    }
  }
}

.checkBox {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: 6px;
  background: $white;
  border: 0.5px solid $checkboxBorder;
  border-radius: $border-radius;
  cursor: pointer;
}

.opaqueCheckBox {
  opacity: 0.4;
}

.checkIcon {
  padding: 0 18px 17px 0;
}

.handSymbol {
  cursor: pointer;
}

.selectedLayout {
  background: $statusSuccessColor;
  border-radius: $border-radius-toggle;
}

.infoIcon {
  flex-shrink: 0;
}

.tooltipMaxWidth {
  width: 270px;
  white-space: pre-wrap;
}

.noBold {
  font-weight: $thin !important;
}
