@import '~@Styles/vars';

.marginTop33{
  margin-top: 33px;
}

.marginRight16{
  margin-right: 16px;
}

.topSpacing {
  margin-top: 10px;
}

.noOpenChats {
  padding: 29px 0 107px 0;
}

.margintop32{
  margin-top: 32px;
  margin-bottom: 32px;
}

.mb8{
  margin-bottom: 8px;
}

.modalOverlay {
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index: $z-index2  ;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $scrollColor;
}

.modalContent {
  position: relative;
  z-index: $z-index1;
  min-width: 320px;
  padding: 14px 24px;
  background: $white;
  border-radius: $border-radius-toggle;
}

.closeIcon {
  position: absolute;
  top: 25px;
  right: 25px;
}

.bottomSpacing{
  padding-bottom: 20px;
}

.btnsGap{
  gap:8px;
}