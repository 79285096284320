@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  position: relative;
  height: 72px;
  margin-top: 8px;
  overflow: hidden;
  background: $menuBackgrounndColor;
  border: 1px solid $breakerColor;
  border-radius: $border-radius;

  .svg {
    display: block;
    width: 100%; /* Make SVG fill the width */
    height: 100%; /* Make SVG fill the height */
    animation: expandAndBlur 1.5s ease-in-out infinite;
  }
}

@keyframes expandAndBlur {
  0% {
    transform: scale(1) translate(0, 0);
    filter: blur(0);
  }
  50% {
    transform: scale(3.5) translate(0, 0); /* Scale to match the final size */
    filter: blur(31px); /* Final blur effect */
  }
  100% {
    transform: scale(1) translate(0, 0);
    filter: blur(0);
  }
}



