@import '~@Styles/vars';

.wrapperBlock {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 236px;
  padding: 20px;
  text-align: center;
  background-color: $fileUploadBackground;
  border: 2px dashed $fileUploadBorder;

  cursor: pointer;
  &.unsetMinHeight {
    @media (max-width: $mobile) {
      min-height: unset;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
 
  @media (max-width: $tablet) {
    margin-bottom: 10px;
  }
}

.margintop32{
  margin-top: 32px;
}

.topSpacing {
  margin-top: 5px;
}

.cardRightSpacing {
  margin-right: 16px;
}

.emptyDocumentContainer {
  min-height: 214px;
  margin-top: 35px;
}

.recentlyUploadedContainer {
  height: 94%;
  margin-bottom: 10px;
}

.pageWidth {

  @media (max-width: $tablet) {
    width: 90%;
  }

  @media (max-width: $mobile) {
    width: unset;
  }
}

.handSymbol {
  cursor: pointer;
}

.emptyContainerWidth {
  width: 92%;
  @media (max-width: $mobile) {
    width: 100%;
  }
}

.mb8{
  margin-bottom: 8px;
}

