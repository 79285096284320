@import '~@Styles/vars';

.checkBoxContainer {
  margin-top: 40px;
}

.marginBottom30{
  margin-bottom: 24px;
}

.bulbIcon {
  flex-shrink: 0;
  margin-right: 8px;
  path{
    fill: $footerColor;
  }
}

.cautionBox {
  margin-bottom: 16px;
  background: $inProgessBackgroundColor;
  border: 1px solid $yellowishOrange;
}