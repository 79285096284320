@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  position: fixed;
  top:0;
  bottom:0;
  z-index: $z-index1;
  width: 235px;
  overflow-x: hidden;
  overflow-y: auto;
  background: $menuBackgrounndColor;
  box-shadow: 0 4px 23px 4px $shadowColor;

  .lifeLedgerIcon {
    padding:30px 38px 35px 24px;
    @media (max-width: $tablet) {
      padding:30px 12px 35px 12px;
    }

    @media (max-width: $mobile) {
      display: flex;
      justify-content: space-between;

    }
  }

  .dashboardContainer {
    padding-left: 28px;
    color: $buttonTextColor;
    font-weight: $bold;
    border-right: 4px solid $profRegisterBackground; 
    .icon {
      margin-top: 3px;
      margin-right: 10px;
    }

    .linkContainer{
      padding-bottom: 7px;
      @include textOverflow();
    }

    path {
      fill: $profRegisterBackground;
    }
    @media (max-width: $tablet) {
      padding-left: 10.5px;
    }
  }

  .draftContainer {
    padding-left: 28px;
    color: $buttonTextColor;
    font-weight: $bold;
    border-right: 4px solid $profRegisterBackground; 
    .icon {
      margin-top: 3px;
      margin-right: 10px;
    }

    .linkContainer{
      padding-bottom: 7px;
      @include textOverflow();
    }

    path {
      fill: $red;
    }
    @media (max-width: $tablet) {
      padding-left: 10.5px;
    }
  }

  .unselecteddashboardContainer {
    margin-bottom: 32px;
    padding-left: 28px;
     
    .icon {
      margin-top: 3px;
      margin-right: 10px;
    }

    .linkContainer{
      padding-bottom: 7px;
      @include textOverflow();
    }

    @media (max-width: $tablet) {
      padding-left: 10.5px;
    }
  }

  .caseTitle{
    margin-bottom: 16px;
    padding-left:12px;
    opacity: 0.7;
    @media (max-width: $tablet) {
      padding-left: 8px;
    }
  }

  .casesContainer {
    padding-left: 28px;
    color: $buttonTextColor;
    cursor: pointer; 
    .icon {
      margin-top: 3px;
      margin-right: 10px;
    }

    .linkContainer{
      padding-bottom: 7px;
      @include textOverflow();
    }

    &:hover {
      background: $hoverButtonColor;
      border-radius: $border-radius;
      transform: scale(0.95);
    }

    @media (max-width: $tablet) {
      padding-left: 12px;
    }
  }

  .bottomSpacing {
    margin-bottom: 32px;
  }

  .bigBottomSpacing {
    margin-bottom: 40px;
  }

  .settingsContainer {
    padding-left: 28px;
    cursor: pointer;
     
    .icon {
      margin-top: 3px;
      margin-right: 10px;
    }

    .linkContainer{
      padding-bottom: 7px;
      @include textOverflow();
    }

    path {
      fill: $buttonTextColor;
    }

    @media (max-width: $tablet) {
      padding-left: 12px;
    }
  }

  .clickSection {
    cursor: pointer;
  }

  @media (max-width: $tablet) {
    width: 150px;
  }

  @media (min-width: $greaterThanTablet) and (max-width: $tabletSurface) {
    width: 170px;
  }

  @media (max-width: $mobile) {
    position: unset;
    width: unset;
    background: unset;
    box-shadow: unset;
  }
}

.missingItemsIcon {
  position: absolute;
  margin-top: 1px;
  margin-left: 11px;
  path {
    fill: $red !important;
  }
}

.sent {
  padding-left: 13px;
  color:$red;
}

.casesSection{
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
}

.logoutSpacing{
  margin-top: 10px;
}

.divSpacing {
  line-height: 1.2rem;
}

.nameSpacing{
  margin-top:3px;
  text-transform: capitalize;
}

.mb32{
  margin-bottom: 32px;
}

.clickSection {
  cursor: pointer;
}

.closeIcon {
  margin-top: 12px;
  path{
    fill: $buttonTextColor;
  }
}

.backToMenuContainer {
  display: flex;
  .backToText {
    padding-left: 30px;
  }

}

.iconPosition {
  position: absolute;
  margin-top: 2px;
  path {
    fill: $buttonTextColor;
  }
}

.settingsCloseIcon {
  margin-top: 6px;
  path{
    fill: $buttonTextColor;
  }
}

.title {
  margin-bottom: 50px;
  font-weight: $bold;
  font-size: $fontSize-24;

  span {
    display: flex;
    align-self: center;
    font-size: $fontSize-24;
    line-height: 31px;

    &.iconSpacing {
      margin-right: 10px;

      .settingsIcon {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.leftSpacing {
  margin-left: 10px;
  a{
    text-decoration: none;
  }
}

.accountMgmt{
  margin-bottom: 5px;
  opacity: 0.7;
}

.textContainer {
  width: 85%;
}

.chevronRightContainer{
  width: 15%;
  margin-top: 5px;
}

.settingsLink {
  display: flex;
  margin-bottom: 8px;
  color: $buttonTextColor;
  cursor: pointer;
}

.caseNames {
  text-transform: capitalize;
  @media (max-width: $tablet) {
    @include textOverflow();
  }
}

.newNotify {
  width: 8px;
  height: 8px;
  margin-top: 11px;
  margin-left: 8px;
  background: $darkOrange;
  border-radius: $border-radius-round;
}

.bigTopSpacing{
  margin-top: 35px;
}

.settingsIconRight {
  margin-top: 1px;
  margin-left: auto;
}

.professionalCasesSection{
  height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.noDeathCaseIcon {
  path {
    fill: $gray3
  }
}

.redFill {
  path {
    fill: $red !important;
  }
}


