@import '~@Styles/vars';

.mainContentLayout {
  background-color: $menuBackgrounndColor;
  background-image: url("../../../../images/register-life.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.gapBetween {
  position: relative;
  gap: 60px;
  height: auto;
  padding-right: 5%;
  padding-left: 5%;
  @media (max-width: $tablet) {
    padding-right: 18px;
    padding-left: 18px;
  }
}

.pl9{
  padding-left: 9%;
  @media (max-width: $tablet) {
    padding-left: unset;
  }
}

.statsContentLayout {
  width: 100%;
  height: auto;
  background: $profRegisterBackground;
}

.statsGap{
  gap: 20px;
  padding-bottom: 64px;
  padding-left: 9%;
  @media (max-width: $tablet) {
    padding-left: 4%;
  }
}

.statsContent{
  padding-top: 80px;
  @media (max-width: $tablet) {
    text-align: center;
  }
}

.mt30{
  display: none;
  margin-top: 30px;
  @media (max-width: $tablet) {
    display: block;
  }
}

.tipsContent{
  padding-top: 111px;

  @media (max-width: $tablet) {
    padding-top: 25px;
    padding-right: 2%;
  }
}

.iconStyle{
  gap: 48px;
  padding: 20px 0 15px 3px;
  @media (max-width: $tablet) {
    gap: unset;
  }
}

.fontStyle{
  color: $white; 
  font-weight: $thin;
  font-size: $fontSize-36;
}

.tipsStyle {
  color: $white;
  font-weight: $thin;
  font-size: $fontSize-32;
  line-height: normal;
}

.tipsContentStyle {
  color: $white;
  line-height: normal;
}

.mt35{
  gap: 9px;
  margin-top: 35px;
}

.mt20{
  gap: 9px;
  margin-top: 20px;
}

.mt5{
  margin-top: 4px;
}

.iconFontStyle {
  color: $pineGreen;
  font-size: $fontSize-48;
  line-height: 48px;
}

.iconTextStyle {
  color: $white;
  font-weight: $semiBold;
  line-height: normal;
}

.pt12{
  padding-top: 12px;
}

.divider{
  width: 379px;
  height: 1px;
  background: $greenLightest;
  @media (max-width: $tablet) {
    width: 96%;
  }
}

.infoContent {
  padding-top: 7rem;
  @media (max-width: $tablet) {
    padding-top: 60px;
  }
}

.mb8 {
  margin-top: 40px;
  margin-bottom: 8px;
  font-size: $fontSize-34;
}

.w68{
  line-height: normal;
}

.closeFreeze {
  margin-top: 2rem;
  line-height: normal;
  @media (max-width: $mobile) {
    margin-top: 32px;
  }
}

.whiteContentLayout {
  width: 100%;
  max-width: 100%;
  height: auto;
  background: $white;
  @media (max-width: $mobile) {
    padding-right: 18px;
    padding-left: 18px;
  }
}

.alignCenter{
  text-align: center;
}

.workFonts{
  margin-top: 80px;
  margin-bottom: 35px;
  color: $black;
  font-weight: $thin;
  font-size: $fontSize-36;
  line-height: normal;
  text-align: center;
}

.circleFonts{
  margin-top: -15px;
  font-size: $fontSize-20;
  line-height: normal;
  text-align: center;
}

.circle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: $border-radius-round;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
}

.rectangle{
  position: relative;
  top: -28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 48px;
  background: #fff;
  border-radius: $border-radius-hyphen;
}

.smallRectangle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 33px;
  background-color: $pineGreen;
  border-radius: $border-radius-hyphen;
}

.gap65{
  gap: 65px;
}

.ml10{
  margin-bottom: 35px;
}

.mb30{
  margin-bottom: 30px;
  line-height: normal;
}

.mb80{
  margin-bottom: 80px;
  @media (max-width: $tablet) {
    padding: 0 25px;
  }
  @media (max-width: $mobile) {
    margin-bottom: 46px;
    padding: unset;
  }
}

.mt56{
  margin-top: 56px;
  margin-bottom: 80px;
}

.p95{
  margin-bottom: 80px;
  padding: 0 90px;
  @media (max-width: $mobile) {
    padding: 0;
  }
}

.signupRect{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 322px;
  height: 46px !important;
  text-align: center;
  background-color: $pineGreen;
  border-radius: $thumb-radius;
}

.signupRect:hover {
  opacity: 0.8;
}

.rotateButton{
  position: fixed;
  top: 448px;
  right: -93.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 46px !important;
  font-weight: $semiBold;
  font-size: $fontSize-18;
  text-align: center;
  background-color: $pineGreen;
  border-radius: $thumb-radius;
  transform: rotate(-90deg);
  transform-origin: center;
  @media (max-width: $tablet) {
    top: unset;
    right: 0;
    bottom: 0;
    z-index: $z-index3;
    width: 100%;
    transform: rotate(0deg);
  }
}

.rotateButton:hover{
  transform: rotate(-90deg) !important;
  opacity: 0.8;
  @media (max-width: $tablet) {
    transform: rotate(0deg) !important;
  }
}

.lightDivider {
  width: 78%;
  height: 1px;
  background: $lightDivider;
  @media (max-width: $tablet) {
    width: 90%;
  }
}

.partnerFonts {
  font-weight: $thin;
  font-size: $fontSize-36;
  line-height: normal;
  text-align: center;
}

.companyIcons{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin: 0;
  padding: 25px 0;
  border-right: 1px dashed #d8d8d8;
  border-bottom: 1px dashed #d8d8d8;

  &:nth-child(5n-5) {
    border-right: 0;
  }
  
  &:nth-child(n+11) {
    border-bottom: 0;
  }
  @media (max-width: $tablet) {
    width: 50%;
    border-right: 0;
    &:nth-child(2n-1) {
      border-right: 1px dashed #d8d8d8 !important;
    }
    &:nth-child(n+11) {
      border-bottom: 1px dashed #d8d8d8 !important;
    }
    &:nth-child(n+13) {
      border-bottom: 0 !important;
    }
    &:nth-child(n+15) {
      display: none;
    }
  }
}


.explainIcons{
  box-sizing: content-box;
  padding: 50px 21.5px 43px 21.5px;
  text-align: center;
  background: $white;
  border-radius: $default-bottom-spacing;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.10);
  @media (max-width: $tablet) {
    padding: 50px 0 43px 0;
  }
}

.freeFonts {
  color: $pineGreen;
  font-size: $fontSize-40;
  line-height: normal;
}

.commentSectionLayout{
  width: 100%;
  height: auto;
  background: $ellipsisColor;
}

.mt70{
  margin-top: 70px;
  margin-bottom: 30px;
  padding-left: 9.5%;
}

.commentFonts{
  margin-bottom: 8%;
  padding-right: 12.5%;
  padding-left: 8.6%;
  font-size: $fontSize-16;
  line-height: 26px;
}

.virginPos{
  margin-bottom: 200px;
  padding-right: 12.5%;
  line-height: normal;
  text-align: right;
  @media (max-width: $tablet) {
    margin-bottom: 60px;
    padding-right: unset;
    text-align: center;
  }
}

.pl11{
  padding-left: 11.5%;
  @media (max-width: $tablet) {
    padding-left: unset;
  }
}

.commentSection{
  position: relative;
  width: 100%;
  height: 100%;
  background: $successColor;
  @media (max-width: $tablet) {
    padding-right: 24px;
    padding-bottom: 44px;
    padding-left: 24px;
  }
}

.whiteComment{
  position: absolute;
  top: 0;
  left: -80px;
  box-sizing: content-box;
  width: 80%;
  height: auto;
  padding: 80px 66px 0 70px;
  line-height: normal;
  background: $white;
  border-radius: $border-radius-comment;
  box-shadow: 0 25px 35px 0 rgba(42, 89, 77, 0.28);
  @media (max-width: $tablet) {
    position: unset;
    left: unset;
    width: unset;
    padding: 30px;
  }
}

.whiteHeaderFonts{
  margin-top: 35px;
  margin-bottom: 15px;
  padding-right: 30px;
  font-size: $fontSize-22;
  line-height: 22px;
}

.lh34{
  margin-bottom: 20px;
  font-size: $fontSize-22;
  line-height: 34px;
}

.gap3{
  gap: 3px;
  margin-bottom: 35px;
}

.gap15{
  gap: 15px;
  margin-bottom: 20px;
}

.smallCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width:  81px;
  height: 81px;
  background: $successColor;
  border-radius: $border-radius-round;
}

.ft30{
  color: $white;
  font-size: $fontSize-30;
  line-height: normal;
}

.userFonts{
  color: $userInitialsColor;
  font-size: $fontSize-20;
  line-height: normal;
}

.textColor{
  margin-bottom: 10px;
  color: $gray-light !important;
}

.flexDisplay{
  display: flex;
  gap: 8px;
}

.verifyFonts {
  color: $gray-light;
  font-size: $fontSize-13;
  line-height: normal;
}

.footer{
  background: $gray-border;
}