@import '~@Styles/vars';

.infoBottom{
  margin-bottom: 4px;
}

.titleBottom{
  margin-bottom: 7px;
}

.eachContactSpacing {
  margin-bottom: 24px;
}

.addressContainer {
  margin-top: 7px;
  margin-bottom: 30px;
  padding: 14px 0 24px 24px;
  background: $summaryColor;
  border-radius: $border-radius-toggle; 
}