@import '~@Styles/vars';

$zindex-tooltip: 10000;
//Tooltip
$tooltip-width: 250px;
$tooltip-shadow: -5px 0 15px 5px rgba($black, 0.1);

.tooltipContainer {
  position: relative;
  height: 100%;
  
  &:hover {
    .tooltip {
      display: inline-block;
    }
  }

  .tooltip {
    position: absolute;
    z-index: $zindex-tooltip;
    display: none;
    min-width: $tooltip-width;
    padding: 15px;
    background: $buttonTextColor;
    border-radius: $border-radius-toggle;
    box-shadow: 0 4px 23px 4px $shadowColor;

    &:before {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }

    &.top {
      bottom: 100%;
      left: 50%;
      margin-bottom: 15px;
      transform: translateX(-50%);

      &:before {
        bottom: -15px;
        left: calc(50% - 15px);
        border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
        border-width: 15px 15px 0 15px;
      }
    }

    &.bottom {
      top: 100%;
      left: 50%;
      margin-top: 15px;
      transform: translateX(-50%);

      &:before {
        top: -15px;
        left: calc(50% - 15px);
        border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
        border-width: 0 15px 15px 15px;
      }
    }

    &.right {
      top: 50%;
      left: 100%;
      margin-left: 15px;
      transform: translateY(-50%);

      &:before {
        top: calc(50% - 15px);
        left: -10px;
        border-top: 10px solid transparent; 
        border-right:10px solid $buttonTextColor;
        border-bottom: 10px solid transparent; 
        border-left: none;
      }
    }

    &.left {
      top: 50%;
      right: calc(100% + 15px);
      transform: translateY(-50%);

      &:before {
        top: calc(50% - 15px);
        left: 100%;
        border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
        border-width: 15px 0 15px 15px;
      }
    }

    .text {
      line-height: 24px;
      text-align: left;
    }
  }
}

.tooltipContainerSpacing {
  position: absolute;
  height: 100%;
  margin-right: 5px;
  
  &:hover {
    .tooltip {
      display: inline-block;
    }
  }

  .tooltip {
    position: absolute;
    z-index: $zindex-tooltip;
    display: none;
    min-width: $tooltip-width;
    padding: 15px;
    background: $buttonTextColor;
    border-radius: $border-radius;
    box-shadow: $tooltip-shadow;

    &:before {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }

    &.top {
      bottom: 100%;
      left: 50%;
      margin-bottom: 15px;
      transform: translateX(-50%);

      &:before {
        bottom: -15px;
        left: calc(50% - 15px);
        border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
        border-width: 15px 15px 0 15px;
      }
    }

    &.bottom {
      top: 100%;
      left: 50%;
      margin-top: 15px;
      transform: translateX(-50%);

      &:before {
        top: -15px;
        left: calc(50% - 15px);
        border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
        border-width: 0 15px 15px 15px;
      }
    }

    &.right {
      top: 50%;
      left: 100%;
      margin-left: 15px;
      transform: translateY(-50%);

      &:before {
        top: calc(50% - 15px);
        left: -15px;
        border-width: 15px 15px 15px 0;
      }
    }

    &.left {
      top: 50%;
      right: calc(100% + 15px);
      transform: translateY(-50%);

      &:before {
        top: calc(50% - 15px);
        left: 100%;
        border-color: $buttonTextColor $transparent $buttonTextColor $transparent;
        border-width: 15px 0 15px 15px;
      }
    }

    .text {
      line-height: 24px;
      text-align: left;
    }
  }
}

.tooltipContent {
  margin: 0;
  padding: 0;
  color: $white;
  font-size: $fontSize-14;
}
