@import '~@Styles/vars';

.container {
  margin-top: 2rem;
  text-align: center;
}

.bottomSpacing {
  margin-bottom: 20px;
}

.mt18{
  margin-top: 18px;
}
