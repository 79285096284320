@import '~@Styles/vars';

.googleButton {
  div {
    margin-left: 2px;
    padding: 8px !important;
  }
  span {
    width: 100%;
    font-weight: $bold !important;
    font-size: $fontSize-17;
    font-family: inherit !important;
    text-align: center;
  }
}

.mt16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.microsoftButton {
  display: flex;
  margin-top: 7px;
  padding: 10px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-17;
  font-family: inherit !important;
  background-color: $mcColor;
  border-radius: $border-radius;
  box-shadow: $mobileLoginBoxShadowColor 0 2px 2px 0, $mobileLoginBoxShadowColor 0 0 1px 0;
  cursor: pointer;
}

.gap62 {
  gap: 62px;
}

.gaprem{
  gap: 0.6rem;
}


.w100 {
  width: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
