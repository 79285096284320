@import '~@Styles/vars';

.headerTitle{
  margin-top: 23px;
  margin-bottom: 28px;
}

.mb28{
  margin-bottom: 28px;
}

.gap16{
  flex-shrink: 0;
  gap:16px;
  padding-left: 6px;
}

.mt35{
  margin-top: 35px;
}

.icon {
  flex-shrink: 0;
}