@import '~@Styles/vars';
@import '~@Styles/mixins';

.notesBottomSpacing {
  margin-bottom: 12px;
}

.pageWidth {
  width:80%;
  @media (max-width: $tablet) {
    width: unset;
  }

  @media (max-width: $mobile) {
    width: unset;
  }
}

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}