@import "~@Styles/vars";
@import "~@Styles/mixins";

.container {
  position: relative;
  height: 100%;

  .tooltip {
    display: none;
  }

  &:hover .tooltip {
    display: inline-block;
  }
}

.label {
  margin: 10px 0;
  font-size: $fontSize-16;
}

.inline {
  position: relative;
  display: inline-block;
}
