@import '~@Styles/vars';

.container {
  text-align: center;
}

.bottomSpacing {
  margin-bottom: 20px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.rightSpacing {
  padding-right: 10px;
  @media (max-width: $mobile) {
    margin-bottom: 20px;
    padding-right: unset;
  }
}