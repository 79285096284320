@import '~@Styles/vars';

.summaryHeadSection {
  width: 75%;
  margin-bottom: 20px;

  p {
    line-height: 23px;

    &.listHeader {
      margin-top: 16px;
      font-weight: $bold;
    }
  }

  @media (max-width: $tablet) {
    width: 90%;
  }

  @media (max-width: $mobile) {
    width: unset;
  }
}

.summaryContainer {
  padding: 0;
}

.backToPrevStep {
  justify-content: start;
  height: auto !important;
  margin-top: 30px;
  padding: 0;
  color: $backToPrevStep;
  font-size: $fontSize-17;
  line-height: 22px;
  text-decoration: underline;
  border: none !important;

  &:hover {
    text-decoration: none;
    transform: none;
  }
}

.rightSpacing {
  margin-right: 20px;
  @media (max-width: $tablet) {
    margin-bottom: 70px;
  }
}

.infoBox {
  margin-top: 24px;
  margin-bottom: 10px;
  padding: 16px 40px 16px 16px;
  background: $inProgessBackgroundColor;
  border: 1px solid $yellowishOrange;
  border-radius: $border-radius-toggle;
}

.infoIcon {
  margin-top: 2px;
  padding-right: 10px;
  path {
    fill: $buttonTextColor;
  }
}
.listFontStyle {
  margin: 0;
  padding: 0;
  font-size: $fontSize-14;
  list-style: auto;
  @media (max-width: $tablet) {
    margin-left: 21px;
  }
}

.accountFormWidth {
  width: 75%;
  @media (max-width: $tablet) {
    width: 90%;
  }
  @media (max-width: $mobile) {
    width: unset;
  }
}