@import '@Styles/vars';

.footerWrapper {  
  margin: 0 100px;
  margin-bottom: 48px;
  background: $gray-border;

  .footerContent{
    padding: 48px 0;

    .textSpacing {
      font-size: $fontSize-16;
      line-height: normal;
      @media (max-width: $tablet) {
        text-align: center;
      }
    }
    
    .lhNormal {
      line-height: normal;
      @media (max-width: $tablet) {
        text-align: center;
      }
    }

    .displayFlex{
      display: flex;
      align-items: center;
      @media (max-width: $tablet) {
        display: block;
        text-align: center;
      }
    }
    @media (max-width: $tablet) {
      gap: 10px;
    }
  }
  @media (max-width: $tablet) {
    margin: unset;
  }
}

.pb24 {
  padding-bottom: 40px;
}

.border{
  width: 50%;
  margin-top: 6px;
  margin-left: 10px;
  border-top: 1px solid #FFFFFF4D;
}

.elementorPrivacy {
  display: flex;
  gap:35px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px;
  border-top: 1px solid #FFFFFF4D;
  a{
    text-decoration: none;
  }
  @media (max-width: $mobile) {
    justify-content: center;
  }
}