@import '~@Styles/vars';

.container {
  width:43%;
  margin-top: 7rem;
  padding: 15px 16px 15px 16px;
  background-image: url(https://lifeledger.com/wp-content/uploads/2021/08/Ellipse-17-2.svg);
  background-repeat: no-repeat;
  background-position: -34px 543px;
  border-top-left-radius: $border-radius-toggle;
  border-bottom-left-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;
}

.mobileIndividualContainer {
  margin-top: 7rem;
  padding: 15px 16px 15px 16px;
  background-color: $ellipsisColor;
  border-top-left-radius: $border-radius-toggle;
  border-top-right-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor
}

.listItemSpacing {
  margin-top: 20px;
}

.textRight {
  text-align: right;
}

.profContainer {
  width:43%;
  margin-top: 7rem;
  padding: 15px 16px 15px 16px;
  color: $white;
  background-color: $profRegisterBackground;
  background-image: url(https://lifeledger.com/wp-content/uploads/2021/08/Ellipse-17-2.svg);
  background-repeat: no-repeat;
  background-position: -34px 543px;
  border-top-left-radius: $border-radius-toggle;
  border-bottom-left-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;
}

.profMobileContainer {
  margin-top: 7rem;
  padding: 15px 16px 15px 16px;
  color: $white;
  background-color: $profRegisterBackground;
  border-top-left-radius: $border-radius-toggle;
  border-top-right-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $shadowColor;
  path {
    fill: $pineGreen;
  }
  .tickSpacing {
    padding-right: 7px;
  }
}

.listSpacing {
  margin-bottom: 16px;
  line-height: 1.5rem;
}