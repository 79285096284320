@import '~@Styles/vars';

.container {
  text-align: center;
}
  
.bottomSpacing {
  margin-bottom: 20px;
}
  
.marginBotto25 {
  margin-bottom: 18px;
}
  
.rightSpacing {
  padding-right: 10px;
}

.tenure {
  margin-top: 30px;
}

.tick {
  position: absolute;
  margin-top: 8px;
}

.advantageMessage {
  padding-left: 29px;
}

.headersSpacing {
  margin-left: 10px;
}

.modeCard{
  margin-bottom: 8px;
  padding: 21px 24px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  cursor: pointer;
}

.annually {
  letter-spacing: 0.205em
}

.offerContainer{
  width: fit-content;
  padding: 2px 16px;
  background: $profRegisterBackground;
  border-radius: $border-radius-43;
  cursor: pointer;
}

.paymentModeIcons {
  gap:30px;
}

.bodyContainer{
  padding: 29px 35px 29px 37px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

