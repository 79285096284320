@import '~@Styles/vars';

.secondLine {
  flex-direction: column;
  padding: 25px 0;
  
}
.linkButton {
  margin-top: 20px;
}
.question {
  padding-right: 4px;
}
.lineHeight {
  line-height: 1.5;
}
.spaceBetween {
  margin-top: 20px;
}

.alignTextCenter {
  text-align: center;
}