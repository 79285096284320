@import '~@Styles/vars';

.radioButtonContainer {
  margin: 10px 0;
  overflow: hidden;
  @media (max-width: $mobile) {
    margin: 5px 0;
  }
}

.actions {
  display: flex;
  flex: 1;
  margin-top: 40px;

  &.column {
    flex-direction: column;
  }
  @media (max-width: $mobile) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.toggleSwitchWrapper {
  margin-bottom: 10px;
}

.topSpacing {
  margin-top: 5px;
}

.container {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

