@import '~@Styles/vars';

.iframeContainer {
  width: 100%;
  height: 600px;
  border: none;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.manualUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.title {
  margin-top: 20px;
  font-size: $fontSize-36;
  text-align: center;
  &.error {
    margin-bottom: 20px;
  }
}

.verified{
  background: $statusSuccessColor;
}

.userStatusPanel {
  padding:32px;
  border-radius: $border-radius-toggle;
  @media (min-width: $desktop-l) {
    max-width: 1129px;
  }
}

.errorSymbol {
  position: absolute;
  left:45px;
  margin-top: 8px;
}

.bigTopSpacing{
  margin-top: 60px;
}

.verificationFailureContainer {
  width:60%;
  @media (max-width: $tablet) {
    width: 90%;
  }
  @media (max-width: $mobile) {
    width: unset;
  }
}

.needsReview {
  background: $infoMissingColor;
  path {
    fill: $red;
  }
}

.personIcon {
  path{
    fill: $dividerColor;
  }
}

.alertTriangle {
  position: absolute;
  right: 284px;
  margin-top: 12px;
}

.subtitleSpacing {
  margin-top: 20px;
}

.topSpacing {
  margin-top: 10px;
}

.statusIconContainers {
  position: relative;
}