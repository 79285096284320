@import '~@Styles/vars';

.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100%;
  .rightSideContainer {
    @media (max-width: $tablet) {
      padding-left: unset;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
    @media (max-width: $desktop-s) {
      padding-bottom: 25px;
    }
  }

  .secondLeftSideContainer {
    z-index: $z-index1;
    min-width: 209px;
    @media (max-width: $tablet) {
      min-width: 160px;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: 0.3em;

    .leftSide,
    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 241px 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 146px 1fr;
  }

  @media (min-width: $greaterThanTablet) and (max-width: $tabletSurface) {
    grid-template-columns: 180px 1fr;
  }

  @media (max-width: $mobile) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
    min-height: unset;
    margin-top: unset;
  }
}

