@import '~@Styles/vars';

.addCompanyBlock {
  margin-top:30px;
  margin-bottom:20px;
  padding: 40px 20px 40px 20px;
  border: 3px dashed $footerColor;
  border-radius: $border-radius-toggle;
  @media (min-width: $desktop-l) {
    max-width: 537px;
  }
}

.spacingTop {
  margin-top: 20px;
}

.bottomSpacing{
  margin-bottom: 100px;
}

.professionalHelpContainer {
  margin-top: 25px;
  margin-bottom: 25px;
}

.professionalHelpCard {
  width: 91.9%;
  margin-bottom: 40px;
  padding: 15px 17px 27px 16px;
  background: $white;
  border: 2px solid $pineGreen;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $case-shadow;

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
  
    img {
      max-height: 100%;
      border-radius: $border-radius;
    }
  }

  .initialLogoContainer{
    width: 64px;
    height: 51px;
    color: $white;
    font-weight: $bold;
    font-size: $fontSize-36;
    background-color: $violet-light;
    border-radius: $border-radius-bigger;
    
    @media (max-width: $mobile) {
      margin-bottom: 24px;
    }
  } 

  .detailsSpacing {
    padding-left: 16px;
    @media (max-width: $mobile) {
      margin-top: 22px;
      padding-left: unset;
    }
  }

  .flexDisplay{
    display: flex;
    gap: 19px;
    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 15px;
    }
  }

  .rightSpacing {
    margin-right: 5px;
  }

  .companyName {
    position: absolute;
    margin-top: -8px;
    margin-left: 23px;
  }

  .flexBox {
    display: flex;
    gap:8px;
    align-items: center;
  }

  .mobileCompanyName {
    margin: auto 0;
    padding-left: 16px;
  }

  @media (max-width: $tablet) {
    width: 92%;
    padding: 11px 17px 26px 17px;
  }

  @media (max-width: $mobile) {
    width: 100%;
    padding: 11px 17px 38px 17px;
  }

  @media (min-width: $tablet) and (max-width: $desktop-s) {
    width: 92%;
    padding: 11px 17px 38px 17px;
  }

  @media (min-width: $desktop-l) {
    width: unset;
  }
}

.tabletTopSpacing {
  margin-top: 10px;
}

.readyToSendContainer {
  padding: 16px 0 36px 34px;
  background: $menuBackgrounndColor;
  @media (max-width: $tablet) {
    padding: 16px 0 36px 14px;
  }
  @media (max-width: $mobile) {
    padding: 16px 9px 36px 9px;
  }
}

.infoIcons {
  flex-shrink: 0;
  path {
    fill: $dividerColor;
  }
}

.rowGap{
  gap:17px;
}

.mobileCompanyBlockSpacing{
  @media (max-width: $mobile) {
    padding-right: 9px;
    padding-left: 9px;
  }
 
}

.mb8{
  margin-bottom: 10px;
}

.logoAndNameGap {
  gap: 16px;
  margin-bottom: 10px;
}

.mt5 {
  margin-top: 5px;
}

.res1400Max {
  @media (min-width: $desktop-l) {
    max-width: 1037px;
  }
}

.res1400MaxRest {
  @media (min-width: $desktop-l) {
    max-width: 1073px;
  }
}

.mt15{
  margin-top: 15px;
}

.eb {
  color: $electricBlue;
  text-decoration-color: $electricBlue;
}

.res1400MaxProbate {
  @media (min-width: $desktop-l) {
    max-width: 987px;
  }}


