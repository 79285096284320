@import '~@Styles/vars';

.swapIcon {
  position: absolute;
  margin-top: 6px;
}
  
.switchCase {
  padding-left: 30px;
}

.breaker {
  margin-bottom: 16px;
  border: 1px solid $dividerColor;
}

.underline {
  color: $black;
  text-decoration: underline;
}
  
.viewNotificationIcon {
  margin-top: 9px;
  margin-right: 5px;
}

.bottomSpacing {
  margin-bottom: 16px;
}