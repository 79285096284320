@import '~@Styles/vars';


.bodyContainer {
  background: url('../../../../images/parabola.png');
  background-color: $menuBackgrounndColor;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.flex {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px 0 0 16px;
  cursor: pointer;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile) {
    display: block;
  }
}

.gap14 {
  gap: 16px;
}

.gap13 {
  gap: 11px;
}

.blueBorder {
  height: 93px;
  padding-left: 4px;
  border-right: 2px solid $electricBlue;
}

.mt12 {
  margin-top: 12px;
}

.mb32 {
  margin-bottom: 16px;
}

.mb8 {
  margin-bottom: 8px;
}

.ticks {
  flex-shrink: 0;
}

.transparentBorder {
  height: 93px;
  padding-left: 4px;
  border-right: 2px solid $transparent;
}

.blueCircle {
  width: 10px;
  height: 10px;
  background: $electricBlue;
  border-radius: $border-radius-round;
}



.rightSideContainer {
  padding: 32px 68px 100px 80px;
  @media (max-width: $res1118) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (max-width: $mobile) {
    margin: unset;
    padding: unset;
  }
}

.seperator {
  padding-right: 68px;
  border-right: 2px solid rgba(0, 0, 0, 0.20);

  @media (max-width: $desktop-s) {
    padding: unset;
    border: unset;
  }
  @media (max-width: $mobile) {
    padding: unset;
    border: unset;
  }
}

.packageCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 462px;
  margin-top: 104px;
  margin-left: 28px;
  padding: 21px 33px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  @media (max-width: $ipadAir) {
    max-width: 93%;
    margin-top: unset;
  }
  @media (max-width: $mobile) {
    max-width: 88%;
    margin-top: unset;
    margin-left: 26px;
  }
}

.discoveryPackDesc {
  a {
    color: $electricBlue;
    font-size: $fontSize-14;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;
  }

}

.f16 {
  font-size: $fontSize-16;
}

.listItemGap {
  gap: 8px;
}

.mb21 {
  margin-bottom: 21px;
}

.footerColor {
  color: $footerColor;
}

.mt24 {
  margin-top: 24px;
  margin-bottom: 8px;
}

.f18 {
  font-size: $fontSize-18;
}

.subscribeButton {
  margin-top: auto;
}

.ml32{
  gap:1rem;
  margin-left: 32px;
  @media (max-width: $tablet) {
    gap:24px;
  }
}

.tenure {
  margin: 35px 0 0 0;
}

.mt33{
  margin-top: 33px;
}

.linkText{
  width: 456px;
  margin-top: 11px;
  margin-left: 120px;
}

