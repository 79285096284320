@import '~@Styles/vars';

.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;
  button {
    &:first-child {
      margin-right: 10px;
    }
  }

  &.column {
    flex-direction: column;
  }
}
.formContainer {
  flex-basis: 100%;
  width: 100%;
  min-height: 450px;
  overflow: hidden;
  overflow-y: auto;
  .Select {
    &__container {
      .Select__menu {
        position: relative;
      }
    }
  }

  @media (max-width: $mobile) {
    min-height: unset;
  }
}
.container {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.bottomSpacing {
  margin-bottom: 20px;
}

.mt18{
  margin-top: 18px;
}

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}