@import '~@Styles/vars';

.addCompanyBlock {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 40px 20px 40px 20px;
  border: 3px dashed $footerColor;
  border-radius: $border-radius-toggle;
}

.profAddCompanyBlock {
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 40px;
  margin-left: 34px;

  @media (min-width: $desktop-l) {
    max-width: 1000px;
  }

  @media (max-width: $tablet) {
    margin-left: 20px;
  }
  @media (max-width: $lessThanTablet) {
    padding-right: 34px;
  }

  @media (max-width: $mobile) {
    margin-right: unset;
    margin-left: unset;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.normalCompanyAddContainer {
  position: relative;
  padding: 16px;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
}

.flashIcon {
  margin-top: 4px;
  margin-right: 8px;
}

.tickIcon {
  flex-shrink: 0;
  margin-right: 8px;
}

.mb9 {
  margin-bottom: 11px;
}

.ml5 {
  margin-left: 12px;
}

.mt10 {
  margin-top: 10px;
  cursor: pointer;
}

.mt40 {
  width: 572px;
  margin-top: 40px;
  margin-left: 25px;
  @media (max-width: $mobile) {
    margin-bottom: 25px;
    margin-left: unset;
  }
}

.lh24 {
  position: relative;
  line-height: 24px;
}

.llDiscoveryStrip {
  gap: 3px;
  width: fit-content;
  margin-left: auto;
  padding: 0 4px 0 4px;
  letter-spacing: 0.045em;
  background: $electricBlue;
  border-radius: $border-radius;

  path {
    fill: $white;
  }
}

.extendspacing {
  padding-top: 36px;
}

.shadowCard {
  box-shadow: $case-card;
}

.notDiscovered {
  background: $menuBackgrounndColor;

  .afterImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.05;
    path {
      fill: $black;
    }
  }
}

.stripBg {
  background: $dividerColor;
}

.tickIconGray {
  path {
    fill: $footerColor;
  }
}

.btnAddCompanyManual {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0 16px 16px 16px;

  @media (max-width: $lessThanTablet) {
    position: unset;
    right: unset;
    bottom: unset;
    width: unset;
    padding: unset;
  }
}

.btnDisAddCompanyManual {
  position: absolute;
  right: 0;
  bottom: 2.2vw;
  width: 100%;
  padding: 0 16px 16px 16px;

  @media (max-width: $lessThanTablet) {
    position: unset;
    right: unset;
    bottom: unset;
    width: unset;
    padding: unset;
  }
}

.readyToSendContainer {
  padding: 16px 0 36px 34px;
  background: $menuBackgrounndColor;
  
  @media (max-width: $tablet) {
    padding: 16px 0 36px 14px;
  }

  @media (max-width: $mobile) {
    padding: 16px 9px 36px 9px;
  }
}

.spacingTop {
  margin-top: 20px;
}

.bottomSpacing {
  margin-bottom: 8px;
}

.handSymbol {
  cursor: pointer;
}

.emptyContainer {
  height: 100px;
  padding: 11px 17px 11px 17px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;

  @media (max-width: $mobile) {
    height: unset;
  }
}

.emptyIcon {
  path {
    fill: $dividerColor;
  }
}

.messageWidth {
  max-width: 382px;
  margin-right: auto;
  margin-left: auto;
}

.emptyNotificationContainer {
  height: 104px;
  padding: 11px 17px 11px 17px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;

  @media (max-width: $mobile) {
    height: unset;
  }
}

.requestedCard {
  margin-bottom: 10px;
  padding: 15px 24px 15px 106px;
  background: $menuBackgrounndColor;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  @media (max-width: $res986) {
    gap: 3px;
  }
  @media (max-width: $mobile) {
    padding-left: 97px;
  }
}

.statusPanel {
  display: flex;
  width: fit-content;
  margin-left: 25px;
  padding: 4px 8px;
  white-space: nowrap;
  background: $adminBgColor;
  border-radius: $border-radius-info;

  path {
    fill: $electricBlue;
  }

  .statusTitle {
    padding-left: 7.5px;
    color: $electricBlue;
    font-weight: $bold;
    font-size: $fontSize-14;
    line-height: 164%;
    letter-spacing: 0.045em;
    white-space: nowrap;
  }
}

.completedDate {
  margin-right: 4px;

  path {
    fill: $gray2;
  }
}

.rightSpacing {
  margin-right: 5px;
}

.pointOneDiscovery {
  color: $footerColor;
  font-size: $fontSize-14;
}
.addCompany {
  color: $buttonTextColor;
  font-weight: $bold;
  font-size: $fontSize-18;
}

.pb25 {
  @media (max-width: $tabletSurface) {
    padding-bottom: 40px;
  }
}
.opaque {
  opacity: 0.7;
}

.mb14 {
  @media (max-width: $lessThanTablet) {
    margin-bottom: 23px;
  }
}

.readyToSendBoxes {
  @media (min-width: $desktop-l) {
    max-width: 1037px;
  }
}

.newSpCompanies {
  @media (min-width: $desktop-l) {
    max-width: 950px;
  }
}

.heightCard {
  height: 220px;
}

.obConfigureImpact {
  bottom: 0.2vw;
}

.accountInfo {
  font-weight: $bold;
  font-size: $fontSize-10;
}

.footColor {
  color: $footerColor;
  path {
    fill: $footerColor;
  }
}

.greenColor {
  color: $successColor;
}

.gap2{
  gap: 2px;
}
