@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  position: relative;
  padding: 11px 17px 11px 17px;
  background: $white;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  box-shadow: 0 4px 23px 4px $case-shadow;

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: $border-radius-bigger;

    img {
      max-width: 91%;
      max-height: 100%;
      border-radius: $border-radius-bigger;
    }
  }
  
  .nameDetails {
    padding-left: 20px;
  }

  .todoTitle{
    padding-left: 18px;
  }

  .statusPanel {
    display: flex;
    width: fit-content;
    margin-left: 25px;
    padding: 4px 8px;
    white-space: nowrap;
    border-radius: $border-radius-info;

    .statusTitle {
      padding-left: 7.5px;
      font-weight: $bold;
      font-size: $fontSize-14;
      line-height: 164%;
      letter-spacing: 0.045em;
      white-space: nowrap;
    }
  }

}

.handSymbol {
  cursor: pointer;
}

.bottomSpacing {
  margin-bottom: 10px;
}

.sentDate{
  margin-right: 4px;
  path {
    fill: $footerColor;
  }
}

.flexDiv {
  display: flex;
}

.iconSpacing {
  margin-top: 5px;
}

.rightSpacing {
  margin-right: 5px;
}

.completedDate{
  margin-right: 4px;
}

.readyToSendActions {
  flex-wrap: wrap;
  padding-top: 17px;
}

.utilityIcon {
  position: absolute;
  margin-top: 9px;
  path {
    fill: $dividerColor;
  }
}

.switchButton {
  padding-left: 22px;
}

.sendHoverState {
  position: absolute;
  margin-top: 1.8rem;
  margin-left: 48rem;
  padding: 0 27px;
  &:hover  {
    background:$hoverButtonColor;
  }

  @media (max-width: $tablet) {
    right:5.3vw;
    &:hover  {
      background:$hoverButtonColor;
    }
  }
  @media (max-width: $mobile) {
    right: 1.3vw;
    margin-top: 29.4vw;
    &:hover  {
      background:$hoverButtonColor;
    }
  }
}

.globeIcon {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 4px;
  path {
    fill: $dividerColor;
  }
}

.cardClickable {
  cursor: pointer;
  &:hover  {
    background:$hoverButtonColor;
  }
}

.combined{
  @media (max-width: $mobile) {
    display: flex;
  }
}

.splitSpNameAndStatusPanel {
  display: flex;
  @media (max-width: $mobile) {
    display: flex;
    justify-content: space-between;
  }
}

.draftSplitSpNameAndStatusPanel {
  display: flex;
}

.moreEllipsis {
  padding-left: 5px;
}

.moreHoriz{
  margin-top: 7px;
}

.alignRight{
  text-align: right;
}

.initialLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-36;
  border-radius: $border-radius-bigger;
}

.editLink {
  text-decoration: underline;
  cursor: pointer;
}

.infoIcon {
  margin-left: 8px;
  path {
    fill: $red;
  }
}

.replyIcon {
  flex-shrink: 0;
  margin-right: 3px;
}

.noWrap {
  white-space: nowrap;
}

.spName {
  @include textOverflow();
}

.flexGap {
  gap: 13px;
}

.lineHeight {
  line-height: 23px;
}

.mt50 {
  margin-top: 50px;
  @media (max-width: $mobile) {
    margin-top: unset;
  }
}

.mt80 {
  margin-top: 80px;
}

.viewDetailsGap {
  gap: 8px;
}