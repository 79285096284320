@import '~@Styles/vars';

.missingContainer {
  margin-bottom: 8px;
  padding: 16px;
  background: $menuBackgrounndColor;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
}

.handSymbol{
  cursor: pointer;
}

.relationshipLayout {
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 12px;
  color: $white;
  background: $buttonTextColor;
  border-radius: $border-radius-43;
}

.capitalize {
  text-transform: capitalize;
}

.relationshipGap {
  flex-wrap: wrap;
  gap:2px;
}