@import '~@Styles/vars';

.actions {
  display: flex;
  flex: 1;
  margin-top: 12px;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }
  }

  &.column {
    flex-direction: column;
  }
  @media (max-width: $mobile) {
    margin-top: 180px;
  }
}
.formContainer {
  flex-basis: 100%;
  width: 100%;
  min-height: 400px;
}
.providerTitle {
  margin: 10px 0;
  color: $lighterBlack;
}

.providerSubtitle {
  margin-top: 10px;
  line-height: 1.5;
}

.link {
  display: block;
  padding-top: 20px;
}

.radioTitle {
  margin-bottom: 10px;
}

.sendButtonSpacing {
  margin-top: 40px;
}

.buttonGaps {
  margin-top: 20px;
}

.cautionBox {
  margin-top: 16px;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.handSymbol {
  cursor: pointer;
}

.innerCircle {
  display: inline-block;
  width: 56px;
  height: 56px;
  margin:3px;
  border-radius: $border-radius-round;
}

.profLogo{
  width: 56px;
  height: 56px;
  border-radius: $border-radius-round;
}

.profLogoCompany {
  gap: 8px;
}

.choosCompanyOpaque {
  margin-top: 28px;
  opacity: 0.4;
}

.companyFormModal {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding: 16px 8px;
  }
}

.spSelected {
  margin-top: 8px;
}

.initialLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-36;
  background: $violet-light;
  border-radius: $border-radius-bigger;
}

.pb40{
  padding-bottom: 40px;
  @media (max-width: $mobile) {
    padding-bottom: 100px;
  }
}

.indentFlex {
  display: flex;
  gap:16px;
}

.indentBar {
  width: 8px;
  margin-left: 6px;
  background: $grayOpacity;
  border-radius: $border-radius;
}

.w100 {
  width: 100%;
}

.addressContainer {
  gap: 9px;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.input {
  width: 100%;
  max-height: $input-address-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-16;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.visibilityToggleIcon {
  position: absolute;
  transform: translateX(-31px) translateY(6px);
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $buttonTextColor;
  }
}

.topSpacing {
  margin-top: 0.3rem;
}

.searchButtonContainer {
  margin-left: 0.5rem;
  @media (max-width: $tablet) {
    margin-top: 0.3rem;
    margin-left: unset;
  }
}

.adresslink {
  margin: 1.25em 0;
  color: $electricBlue;
  font-size: $fontSize-17;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 22px;
  text-decoration-line: underline;
}

.w50 {
  width: 40%;
}

.mt40{
  margin-top: 40%;
}

.docTitle {
  gap: 8px;
}

.blueBox {
  gap:12px;
  align-items: flex-start;
  margin-bottom: 0.6rem;
  padding: 16px 40px 16px 16px;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
  border-radius: $border-radius-toggle;
}

.infoContainer {
  gap: 17px;
  margin-top: 50px;
  padding: 17px 16px;
  background: $sentStatusColor;
  border-top-left-radius: $border-radius-toggle;
  border-top-right-radius: $border-radius-toggle;
}

.flexShrink {
  flex-shrink: 0;
}

.caseQuestionContainer {
  margin-bottom: 20px;
  padding: 13px 16px 24px 16px;
  background: $menuBackgrounndColor;
  border-bottom-right-radius: $border-radius-toggle;
  border-bottom-left-radius: $border-radius-toggle;
}

.grayBlock {
  width: 16px;
  background: $grayOpacity;
}

.whiteBlock {
  padding: 16px;
  background: $white;
}

.mb8{
  margin-bottom: 8px;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px auto;
  padding: 16px;
  background-color: $softCyan; // Soft cyan background
  border: 1px solid $lightBlue; // Light blue border
  border-radius: $border-radius-bigger;
  box-shadow: 0 4px 8px $scrollColor;
}

.notificationMessage {
  margin-bottom: 12px;
  color: $darkTeal; // Dark teal for text
  font-size: $fontSize-18;

  strong {
    color: $tealEmphasis; // Teal color for emphasis
    font-weight: $bold;
  }
}

.cantNotifySection {
  margin-top: 24px;
  padding: 8px;
  background: $datePickerBorderRightPrevDisHoverColor;
  border-radius: $thumb-radius;
}
