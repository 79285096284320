@import '~@Styles/vars';

.legalForm {
  margin-top:30px;
  .separator {
    width: 100%;
    height: 2px;
    background-color: $dividerColor;
  }

  .bottomSpacing {
    margin-bottom: 10px;
  }
}

.notesContainer {
  padding-bottom: 10px;
  font-size: $fontSize-16;
  line-height: 1.2;

  @media (max-width: $tablet) {
    padding-top: 0;
  }
}

.notesBottomSpacing {
  margin-bottom: 12px;
}

.pageWidth {
  width:80%;
  @media (max-width: $tablet) {
    width: unset;
  }
}

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.mt18 {
  margin-top: 18px;
}

.biggerBottomSpacing {
  margin-bottom: 20px;
}