@import '~@Styles/vars';
@import '~@Styles/mixins';



.headerContainer {
  padding: 16px 12px 1px 34px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-bottom: unset;
    padding-left: 8px;
  }
}


.gap3{
  gap:6px;
}

.pb30{
  padding: 10px 40px !important;
}

.mt6{
  margin-top: 6px;
}

.letterSpacing {
  letter-spacing: 0.015em;
  cursor: pointer;
}
