@import '@Styles/vars';

.icon {
  display: inline-block;

  &.spin {
    :global {
      animation: spin 1.5s linear infinite;
    }
  }
}
