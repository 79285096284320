@import '~@Styles/vars';

.layout{
  padding: 32px 24px 48px 24px;
}

.profLogo{
  width: 145px;
  height: 93px;
  margin-top: 28px;
  margin-bottom: 22px;
  object-fit: contain;
  border-radius: $border-radius-round;
}

.noLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin-top: 28px;
  margin-bottom: 22px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-27;
  background: $violet-light;
  border-radius: $border-radius-round;
}