@import '~@Styles/vars';

.bottomSpacing{
  padding-bottom: 20px;
}

.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }
  }
  
  &.column {
    flex-direction: column;
  }
}

.subtitle {
  line-height: 1.5;
}