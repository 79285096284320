@import '~@Styles/vars';
@import '~@Styles/mixins';

$underlineColor: rgba($light-orange, 0.5);

.mobileContainer {
  padding-bottom: 20px;
  background-color: $white;
}

.backLink {
  color: $footerColor;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 30px;
  &.mobileHeader {
    padding-bottom: 10px;
    border-bottom: 2px solid rgba($gray-light, 0.1);
  }
  .logoContainer {
    padding: 2px;
  }

  .settingsContainer {
    .settingsIcon {
      position: relative;

      svg {
        padding-top: 1px;
      }
    }

    .settingsMissing {
      &:before {
        position: absolute;
        top: calc(50% + 3px);
        left: calc(50% + 4px);
        display: block;
        width: 7px;
        height: 7px;
        background-color: $orange;
        border-radius: $border-radius-round;
        content: '';
      }
    }
    @media (max-width: $tablet) {
      margin: 15px auto;
    }
  }

  .linksContainer {
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;

      div + a,
      svg + a,
      svg + span {
        margin-left: 10px;
      }
    }

    span + span {
      // margin-left: 50px;
      @media (max-width: $tablet) {
        margin-left: unset;
      }
    }

    .separator {
      height: 80%;
      margin-right: 30px;
      margin-left: 50px;
      border-left: 2px solid #e2e2e2;
    }

    .logOutContainer {
      margin-top: 10px;
      margin-left: 2px;
      outline: none;
      cursor: pointer;
      @media (max-width: $tablet) {
        margin: 15px auto 30px;
        padding-right: 13px;
      }
    }

    .spMenuContainer {
      margin-left: 2px;
      outline: none;
      cursor: pointer;
      @media (max-width: $tablet) {
        margin: 15px auto 30px;
        padding-right: 13px;
      }
    }

    .accMgmtContainer {
      outline: none;
      cursor: pointer;
      @media (max-width: $tablet) {
        margin: 15px auto 30px;
        padding-right: 13px;
      }
    }

    .settingsContainer {
      position: relative;

      &.active {
        path:last-child {
          fill: $underlineColor;
        }

        span {
          font-weight: $bold;
        }

        &:after {
          position: absolute;
          top: 30px;
          left: -5px;
          width: calc(100% + 10px);
          height: 3px;
          background-color: $underlineColor;
          border-radius: $border-radius-toggle;
          content: '';
        }
      }
    }
    @media (max-width: $tablet) {
      display: none;
      &.openInTablet {
        position: absolute;
        top: 82px;
        z-index: $zindex-hamburger;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: -5%;
        background-color: $white;
        transition: opacity 0.1s;
        pointer-events: all;
      }
    }
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  a {
    @include linkAnimation($footerColor);
  }
}

.leftSpacing {
  margin-left: 10px;
}

.nameContainer {
  outline: none;
  cursor: pointer;
}

.demoMenu {
  position: absolute;
  top: 64px;
  right:15px;
  z-index: $z-index2;
  width: 30%;
  margin: .8rem auto auto;
  padding: .8rem 1rem;
  vertical-align: top;
  background: $white;
  border-radius: $datePickerBorderRadius;
  box-shadow: 0 0.2rem 0.4rem rgba($gray-light, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: transform .2s,opacity .2s,visibility 0s linear .2s;
  will-change: transform;
   
  &.visible {
    transform: translateY(-.3rem);
    visibility: visible;
    opacity: 1;
    transition: transform .2s,opacity .2s,visibility linear;
  }
}
