@import '~@Styles/vars';

.swapIcon {
  position: absolute;
  margin-top: 6px;
}

.bottomSpacing {
  margin-bottom: 25px;
}

.title {
  margin-bottom: 10px;
  font-size: $fontSize-16 ;
}

.test {
  margin-top: 2px;
}

.iconColor {
  path {
    fill: $dividerColor;
  }
}

.linkFont {
  flex: none;
}

.container {
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
}

.mb33{
  margin-bottom: 33px;
}

