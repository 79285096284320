@import 'vars';

.stepperNew {
  //  position: relative;
  display: flex;
  flex-direction: column;
  .step {
    position: relative;
    z-index: $z-index-0;
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0.1em 0.75em;
    font-size: $fontSize-16;
    span + span {
      margin-left: 20px;
    }

    .numberContainer {
      display: inline-flex;
      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin: auto;
        padding-bottom: 2px;
        color: $white;
        font-size: $fontSize-14;
        background-color: $pineGreen;
        border-radius: $border-radius-round;
        opacity: 0.5;

        &.active {
          width: 30px;
          height: 30px;
          font-size: $fontSize-16;
          opacity: 1;
        }
      }
      @media (max-width: $tablet) {
        margin-bottom: 10px;
      }
    }

    &.active,
    &.before,
    &.after {
      font-weight: $medium;
    }

    &.active {
      color: $black;
      .number {
        border: 2px solid $white;
        box-shadow: 0 0 0 2px $pineGreen;
      }
      .title {
        font-weight: $bold;
      }
    }

    &.before {
      color: $black;

      .number {
        background-color: $black;
        border: 2px solid $white;
        box-shadow: 0 0 0 2px $black;
        opacity: 0.8;
      }
    }

    &.after {
      color: $gray-light;

      .number {
        background-color: $gray-light;
      }
    }
    &:last-child {
      span {
        right: 10px;
        left: unset;
      }
    }
   

    .title {
      margin-left: 0.5em;
      padding-left: 20px;  
      font-style: normal;
      line-height: 164%;
      letter-spacing: 0.015em;
      transition: color 0.3s;

      &.active {
        font-weight: $bold;
      }

      &.before {
        color: $successColor;
      }

      @media (max-width: $tablet) {
        left: 10px;
      }
    }

    .titleWrapper {
      display: flex;
      flex-direction: column;
      max-width: 270px;
      margin: 30px 0 15px 0;
      text-align: center;
    }

    .clickable {
      cursor: pointer;
    }
    @media (max-width: $tablet) {
      margin: 0.1em 0.75em 0.5em 0.75em;
    }
  }

}

.arrowsIcon {
  position: absolute;
  margin-top: 1px;
}

.tickIcon {
  position: absolute;
  top: 5px;
}

.exitButtonPosition {
  position: relative;
  top:300px;
  padding-left: 12px;
  cursor: pointer;
}

.exitButton {
  padding-left: 25px;
}

.exitIcon {
  position: absolute;
  margin-top: 6px;
}
