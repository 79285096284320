@import '~@Styles/vars';

.link {
  text-decoration: none;
  @include linkAnimation($footerColor);
}

.breadcrumbNavigation {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  .elementText {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > .breadcrumbItem {
    display: contents;
  
  }
}

.chevronIcon {
  margin: 0 8px 0 8px;
}

.makeBold {
  font-weight: $bold;
}
