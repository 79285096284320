@import '~@Styles/vars';

.dCRefNumberFormWrapper {
  display: flex;
  
  .rightSpacing {
    padding-right: 18px;
  }

  .handSymbol {
    cursor: pointer;
  }

  .textBoxStyle{
    width: 274px;
    height: 39px;
    padding-left: 8px;
    font-size: $fontSize-16;
    font-family: $helveticaFamily;
    background: $white;
    border: 0.5px solid $buttonTextColor;
    border-radius: $border-radius;

    @media (max-width: $mobile) {
      width: unset;
      height:38px;
    }
  }

  .saveButton {
    height: 39px;
    margin-left: 8px;
    @media (max-width: $mobile) {
      margin-left: unset;
    }
  
  }
  .iconSpacing {
    margin-top: 5px;
    cursor: pointer;
  }

  .editSpacing {
    padding-right: 10px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    gap:8px;
  }
}
