@import '~@Styles/vars';

.activityContainer {
  margin-bottom: 2px;
  padding: 8px 10px 6px 20px;
  font-size: $fontSize-16;
  background: $menuBackgrounndColor;
}

.seenIcon {
  color: $seenGray;
  path{
    fill: $seenGray;
  }
}

.unSeenIcon {
  color: $buttonTextColor;
  path{
    fill: $buttonTextColor;
  }
}

.newNotify {
  position: absolute;
  width: 8px;
  height: 8px;
  margin-bottom: 13px;
  margin-left: 14px;
  background: #e97258;
  border-radius: $border-radius-round;
}

.rightSpacing {
  margin-right: 14px;
}

.handSymbol {
  cursor: pointer;
}