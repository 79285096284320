.container {
  text-align: center;
}

.bottomSpacing {
  margin-bottom: 20px;
}

.marginBotto25 {
  gap: 10px;
  margin-bottom: 25px;
}

.marginBotto28 {
  gap: 10px;
  margin-bottom: 28px;
}

.rightSpacing {
  padding-right: 10px;
}

.currency {
  margin-right: 50px;
}

.tenure {
  position: absolute;
  margin-top: 30px;
  margin-left: 50px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.mt18 {
  margin-top: 18px;
}

.padding{
  margin-top: 6px;
  padding: 0 90px;
}
