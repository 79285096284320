@import '~@Styles/vars';
@import '~@Styles/mixins';

.checkbox {
  display: flex;
  align-items: center;
  padding: 0.3em 0;
  color: $lighterBlack;
  white-space: normal;
  cursor: pointer;
  user-select: none;
  input {
    display: none;

    &[type='radio'] {
      & ~ .inputBox {
        border-radius: $border-radius-round;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 16.36px;
          height: 16.36px;
          background: $pineGreen;
          border-radius: $border-radius-round;
          transform: scale(0.001) translate(-50%, -50%);
          transform-origin: left;
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
          content: '';
        }
      }

      &:checked ~ .inputBox:after {
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  .inputBox {
    position: absolute;
    top:10px;
    left: 0;
    width: 24px;
    height: 24px;
    background: $radioButtonBackground;
    transition: background-color 0.3s, box-shadow 0.3s;

    &.disabled {
      filter: grayscale(100);
    }
    &.error {
      box-shadow: 0 0 0 1pt $red;
    }
  }

  .labelSpacing {
    padding-left: 2.0em;
  }

  .subLabel {
    color: $footerColor;
    font-size: $fontSize-16;
  }

}

.radioButtonContainer {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
  color: $lighterBlack;
  white-space: normal;
  cursor: pointer;
  user-select: none;
 
  input {
    display: none;

    &[type='radio'] {
      & ~ .inputBox {
        border-radius: $border-radius-round;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 16.36px;
          height: 16.36px;
          background: $pineGreen;
          border-radius: $border-radius-round;
          transform: scale(0.001) translate(-50%, -50%);
          transform-origin: left;
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
          content: '';
        }
      }

      &:checked ~ .inputBox:after {
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
      }
     
      
    }
  }

  .inputBox {
    position: absolute;
    top:18px;
    left: 0;
    width: 24px;
    height: 24px;
    background: $radioButtonBackground;
    transition: background-color 0.3s, box-shadow 0.3s;

    &.disabled {
      filter: grayscale(100);
    }
    &.error {
      box-shadow: 0 0 0 1pt $red;
    }
  }
  

  @keyframes bounceIn {
    0% {
      transform: scale(0) rotate(45deg);
    }

    80% {
      transform: scale(1.1) rotate(45deg);
    }

    100% {
      transform: scale(1) rotate(45deg);
    }
  }
}
