@mixin textOverflow() {
  & {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin scrollbar($width, $thumb-color, $thumb-radius, $background-color) {
  ::-webkit-scrollbar {
    width: $width;
    height: $width; //horizontal scrollbar size.
  }

  ::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: $thumb-radius;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }
}

@mixin transition-with-delay($count, $type, $transitionTime: 0.3s, $initialDelay: 0) {
  @for $i from 1 through $count {
    &:nth-child(#{$i}) {
      transition: $type $transitionTime #{strip-unit($initialDelay) + ($i * 0.1)}s;
    }
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@function size($size) {
  $remSize: calc($size / 16px);
  @return #{$remSize}rem;
}

@mixin linkAnimation($color) {
  position: relative;
  padding-bottom: 5px;
  &:hover {
    &:before {
      width: 100%;
    }
  }
  &:before {
    position: absolute;
    bottom: -5%;
    left: 50%;
    width: 0;
    height: 1px;
    background: $color;
    transform: translateX(-50%);
    transition: width .3s ease, height .3s ease;
    content: "";
  }
}
