@import '~@Styles/vars';
.container {
  width: 100%;
}
.htmlContainer {
  margin-bottom: 35px;
}
.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-left: 10px;
      @media (max-width: $tablet) {
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }

  &.column {
    flex-direction: column;
  }
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}
