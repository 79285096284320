@import '~@Styles/vars';

.notesContainer {
  padding-top: 10px;
  color: $footerColor;
  font-weight: $fontStyle;
  font-size: $fontSize-14;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 164%;
  letter-spacing: 0.045em;

  @media (max-width: $tablet) {
    padding-top: 0;
  }
}

.whiteSpaceUnset {
  white-space: unset;
}

.notesBottomSpacing {
  margin-top: 4px;
  margin-bottom: 12px;
}

.cautionBox {
  display: none;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.cautionBox1 {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.yellowCautionBox {
  background: $inProgessBackgroundColor;
  border: 1px solid $yellowishOrange;
}
