@import '~@Styles/vars';

.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }
  }

  &.column {
    flex-direction: column;
  }
}
.formContainer {
  flex-basis: 100%;
  width: 100%;
  min-height: 400px;
}
.providerTitle {
  margin: 10px 0;
  color: $lighterBlack;
}

.providerSubtitle {
  margin-top: 10px;
  color: $gray-light;
  line-height: 1.5;
}

.link {
  display: block;
  padding-top: 20px;
}

.gap24{
  gap:24px;
  margin-top: 70px;
}
