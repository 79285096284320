@keyframes :global(fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes :global(spin) {
  to {
    -webkit-transform: rotate(359deg);
  }
}
