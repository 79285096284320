@import '~@Styles/vars';

.buttonSpacing {
  margin-top: 45px;
}

.bodyContainer {
  width: 85%;
}

.blueBox {
  gap: 10px;
  margin-top: 14px;
  padding: 16px 40px 16px 18px;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
  border-radius: $border-radius-toggle;
}

.infoIcon {
  flex-shrink: 0;
  path {
    fill: $buttonTextColor;
  }
}

.companyNameContainer {
  padding: 8px 24px 16px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius;
}

.ml5{
  margin-left: 10px;
}

.knownNameSpacing {
  position: relative;
  display:flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: $tablet) {
    width: unset;
  }
}

.knownNameSpacing:before {
  width:8px;
  margin-right: 16px;
  margin-left: 9px;
  background: #F3F3F3;
  border: 2px ;
  content: "";
}

.mt35{
  margin-top: 35px;
}
