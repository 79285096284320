@import '~@Styles/vars';

.referCaseLayout {
  width: 70%;
  @media (max-width: $tablet) {
    width: 90%;
  }
  
  @media (max-width: $mobile) {
    width: unset;
  }
}
  
.titleRefCase{
  margin-bottom: 24px;
  line-height: 41.86px;
}
  
.referCaseContainer{
  margin-top: 16px;
  margin-bottom: 56px;
  padding: 31px 31px 31px 20px;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
}
  
.personContainer{
  width: 48px;
  height: 48px;
  margin-right: 24px;
  padding:3px 1px 3px 3px;
  text-align: center;
  background-color: $violet-light;
  border-radius: $border-radius-round;
}
  
.confirmButton{
  margin-bottom: 16px;
}