@import '~@Styles/vars';

.mt40 {
  width: 572px;
  margin-top: 40px;
  margin-left: 25px;
  @media (max-width: $mobile) {
    margin-bottom: 25px;
    margin-left: unset;
  }
}
.pb25 {
  @media (max-width: $mobile) {
    padding-bottom: 25px;
  }
}

.paddingBody {
  padding: 14px 24px;
  @media (max-width: $mobile) {
    padding: 14px 8px !important;
  }
}
