@import '~@Styles/vars';

.row {
  display: flex;

  &:not(.constant) {
    @media (max-width: $tablet - 1) {
      flex-direction: column;
    }
  }
}

@for $i from 1 through 13 {
  $width: percentage(calc($i / 12));

  .size#{$i} {
    flex-basis: $width;
    width: $width;
    max-width: $width;
    &:not(.constant) {
      @media (max-width: $tablet - 1) {
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.inline {
  display: inline-flex;
}

.column {
  flex-flow: column;
}

.columnMobile {
  @media (max-width: $mobile) {
    flex-flow: column;
  }
 
}

.columnTablet{
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.wrap {
  flex-wrap: wrap;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyAround {
  justify-content: space-around;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
  @media (max-width: $tablet) {
    text-align: center;
  }
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.lineHeightNormal{
  line-height: 1.2;
}