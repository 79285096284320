@import '~@Styles/vars';
@import '~@Styles/mixins';

.wrapperBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 213px;
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
  background-color: $fileUploadBackground;
  border: 2px dashed $fileUploadBorder;
  
  cursor: pointer;
  &.unsetMinHeight {
    @media (max-width: $mobile) {
      min-height: unset;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
 
  @media (max-width: $tablet) {
    margin-bottom: 10px;
  }
}

.disabled{
  opacity: 0.5;
}

.noCursor{
  cursor: not-allowed !important;
}

.margintop32{
  margin-top: 32px;
  margin-bottom: 32px;
}

.topSpacing {
  margin-top: 5px;
}

.cardRightSpacing {
  margin-right: 16px;
}

.emptyDocumentContainer {
  min-height: 214px;
  margin-top: 35px;
}

.recentlyUploadedContainer {
  height: 94%;
  margin-bottom: 10px;
}

.fileContainer {
  padding:10px !important;
}

.flex{
  display: flex;
  gap: 8px;
}

.textWidth{
  width: 100%;
  max-width: 334px;
}