@import '~@Styles/vars';

.billingContainer {
 
  margin-top: 8px;
  padding: 16px 4px 16px 32px;
  border-radius: $border-radius-toggle;
  @media (max-width: $res986) {
    flex-wrap: wrap;
  }
}

.unpaidContainer {
  background: $menuBackgrounndColor;
}

.paidContainer {
  background: $white;
  box-shadow: $case-card;
}

.designation {
  display: flex;
  path {
    fill: $dividerColor;
  }

  .personIcon {
    margin-top: 5px;
    margin-right: 9px;
    path {
      fill: $electricBlue;
    }
  }
}

.unpaidPanel {
  padding: 3px 12px 7px 10px;
  background: $inProgessBackgroundColor;
  border-radius: $border-radius-info;
  
  path {
    fill : $yellowishOrange;
  }
    
}
  
.paidPanel {
  padding: 3px 12px 7px 10px;
  background: $statusSuccessColor;
  border-radius: $border-radius-info;
    
  path {
    fill : $successColor;
  }
    
}
  
.paymentStatusIcon {
  position: absolute;
  margin-top: 9px;
}
  
.roleText {
  padding-left: 25px;
}

.dateColumn {
  display: flex;
  gap: 6px;
  align-items: center;
}

.tickIcon {
  margin-top: 7px;
  path {
    fill: $footerColor;
  }
}

.doubleTickIcon {
  path {
    fill: $footerColor;
  }
}

.downloadIcon {
  margin-top: 8px;
  cursor: pointer;
  path {
    fill: $buttonTextColor;
  }
}

