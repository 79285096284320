@import '~@Styles/vars';

.menuLayout {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 0;
  border-radius: $border-radius-toggle;

  .menuContentLayout {
    position: relative;
    width: 100%;
    max-width: 930px;
    margin-right: auto;
    margin-left: auto;

    .clockContainer {
      margin-right: 15px;
      @media (max-width: $mobile) {
        margin-top: 5px;
      }
    }

    .shrinkClockContainer {
      @media (max-width: $mobile) {
        margin-right: 8px;
      }
    }

    .shrinkStatusButtonContainer {
      margin-top: 8px;
      @media (max-width: $mobile) {
        margin-bottom: 13px;
      }
    }

    @media (max-width: $tablet) {
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-right: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
    }
  }

  @media (min-width: $desktop-l) {
    max-width: 895px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 0 57px;
  text-align: center;
  @media (max-width: $tablet) {
    padding-top: 76px;
    padding-left: unset;
  }
}

.progressContainer::before {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: $z-index1;
  width: 63%;
  height: 2px;
  background-color: $successColor;
  transform: translateY(-50%);
  content: '';
}

.progressContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 576px;
  max-width: 100%;
  margin-bottom: 60px;

  @media (min-width: $desktop-l) {
    max-width: 576px;
  }
  @media (max-width: $desktop-m) {
    max-width: 400px;
  }
  @media (max-width: $tablet) {
    max-width: 300px;
  }
  @media (max-width: 400px) {
    max-width: 240px;
  }
}

.progressContainer::after {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: $z-index1;
  width: 25%;
  height: 0;
  border: none;
  border-top: 2px dashed $successColor;
  transform: translate(276%, -50%);
  content: '';
}

.progressActiveContainer::before {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: $z-index1;
  width: 63%;
  height: 2px;
  background-color: $successColor;
  transform: translateY(-50%);
  content: '';
}

.progressActiveContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 576px;
  max-width: 100%;
  margin-bottom: 65px;

  @media (min-width: $desktop-l) {
    max-width: 576px;
  }
  @media (max-width: $desktop-m) {
    max-width: 400px;
  }
  @media (max-width: $tablet) {
    max-width: 300px;
  }
  @media (max-width: 400px) {
    max-width: 240px;
  }
}
.progressActiveContainer::after {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: $z-index1;
  width: 25%;
  height: 2px;
  background-color: $successColor;
  border: none;
  border-top: 0 dashed $successColor;
  transform: translate(275%, -50%);
  content: '';
}

.progress {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: $z-index-1;
  width: 0%;
  height: 2px;
  background-color: $blue;
  transform: translateY(-10%);
  transition: 0.4s ease;
}

.circle::before {
  position: absolute;
  bottom: 100%;
  display: block;
  width: 130px;
  padding: 4px;
  color: $footerColor;
  font-weight: $normal;
  font-size: $fontSize-14;
  line-height: 22.96px;
  content: attr(data-tooltip);
  @media (max-width: $tablet) {
    width: 92px;
  }
}

.circle {
  z-index: $z-index2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: $gray-light;
  background-color: $white;
  
  border-radius: $border-radius-round;
  transition: 0.4s ease;
  &.active {
    background-color: $successColor;
    border: 2px solid $successColor;
  }
  &.notFound {
    background-color: $footerColor;
  }
}

.circle::after {
  position: absolute;
  top: 100%;
  display: block;
  width: 130px;
  padding: 4px;
  color: $footerColor;
  font-weight: $normal;
  font-size: $fontSize-14;
  line-height: 22.96px;
  content: attr(data-tooltip1);
  @media (max-width: $tablet) {
    width: 92px;
  }
}

.finalCircle::before {
  position: absolute;
  bottom: 100%;
  display: block;
  width: 130px;
  padding: 4px;
  color: $footerColor;
  font-weight: $bold;
  font-size: $fontSize-14;
  line-height: 22.96px;
  content: attr(data-tooltip);
  @media (max-width: $tablet) {
    width: 92px;
  }
}

.finalCircle {
  z-index: $z-index2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: $gray-light;
  background-color: $white;
  border-radius: $border-radius-round;
  transition: 0.4s ease;
  &.active {
    background-color: $successColor;
    border: 2px solid $successColor;
  }
  &.notFound {
    background-color: $footerColor;
  }
  &.inProgess {
    border: 2px solid $successColor;
  }
}

.iconColor {
  path {
    fill: $white;
  }
}

.iconInactiveColor {
  path {
    fill: $gray-light;
  }
}

.panelInsideSpacing {
  padding: 18px 32px 24px 24px;

  @media (max-width: $mobile) {
    padding-bottom: 10px;
  }
}

.removePanelInsideSpacing {
  padding: unset;
}

.inProgessBackgroundColor {
  background-color: $inProgessBackgroundColor;
}

.statusSuccessColor {
  background-color: $statusSuccessColor;
}

.statusErrorColor {
  background-color: $statusErrorColor;
}

.sentStatusColor {
  background-color: $sentStatusColor;
}

.adminBgColor {
  background-color: $adminBgColor;
}

.yellowishOrange {
  color: $yellowishOrange;
}

.profRegisterBackground {
  color: $profRegisterBackground;
}

.red {
  color: $red;
}

.black {
  color: $black;
}

.electricBlue {
  color: $electricBlue;
  path {
    fill: $electricBlue;
  }
}

.mt7 {
  margin-top: 14px;
  @media (max-width: $tablet) {
    margin-top: unset;
  }
}

.gap8 {
  gap: 8px;
  @media (max-width: $tablet) {
    gap: unset;
  }
}

.lh {
  line-height: 26.24px;
}

.flexDisplay {
  display: flex;
  gap: 24px;
  @media (max-width: $tablet) {
    gap: 8px;
  }
}

.inProgessBorderColor {
  border: 1px solid $yellowishOrange;
}

.statusSuccessBorderColor {
  border: 1px solid $profRegisterBackground;
}

.statusErrorBorderColor {
  border: 1px solid $red;
}

.sentStatusBorderColor {
  border: 1px solid $black;
}

.adminBorderColor {
  border: 1px solid $electricBlue;
}

.mr100 {
  margin-right: 100px;
  margin-left: 1rem;
  @media (max-width: $mobile) {
    margin-right: unset;
    margin-left: unset;
  }
}

.containerClass {
  margin: unset;
  padding-left: unset;
}

.notFoundContainer::before {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: $z-index1;
  width: 63%;
  height: 2px;
  background-color: $footerColor;
  transform: translateY(-50%);
  content: '';
}

.notFoundContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 576px;
  max-width: 100%;
  margin-bottom: 65px;

  @media (min-width: $desktop-l) {
    max-width: 576px;
  }
  @media (max-width: $desktop-m) {
    max-width: 400px;
  }
  @media (max-width: $tablet) {
    max-width: 300px;
  }
  @media (max-width: 400px) {
    max-width: 240px;
  }
}
.notFoundContainer::after {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: $z-index1;
  width: 25%;
  height: 2px;
  background-color: $footerColor;
  border: none;
  border-top: 0 dashed $footerColor;
  transform: translate(275%, -50%);
  content: '';
}

.notFoundFinalCircle {
  background: $footerColor !important;
}
