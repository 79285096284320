@import '~@Styles/vars';
@import '~@Styles/mixins';

.container {
  position: relative;
  margin-bottom: 5px;
  white-space: nowrap;
  &.fullWidth {
    width: 100%;
  }
  &.wrapWhiteSpace {
    white-space: normal;
  }
}

.label {
  margin: 15px 0 1px 0;
  color: $buttonTextColor;
  font-weight: $fontStyle;
  font-size: $fontSize-18;
  font-style: normal;
  line-height: 164%;
  letter-spacing: 0.015em;
  white-space: pre-wrap;

  &.helperText {
    line-height: 164%;
    letter-spacing: 0.045em;
  }
}

.relative {
  position: relative;
}

// Properties are needed for css types generation, right now correct styles for disabled and required are not defined

.disabled {
  cursor: not-allowed;
}

.required {
  color: $red;
}


.tooltipMaxWidth {
  width: 270px;
  white-space: pre-wrap;
}

.flexDisplay {
  display: flex;
  flex-shrink: 0;
}

.helperDisplay{
  display:flex ;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
}

.bold {
  font-weight: $bold;
}

.ml4{
  margin-left: 4px;
}

.nearLabel {
  line-height: 130%;
  opacity: 0.8;
}
