@import '~@Styles/vars';
.sidebarContainer {
  position: fixed;
  top:0;
  bottom:0;
  width: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  background: $menuBackgrounndColor;
  box-shadow: 0 4px 23px 4px $shadowColor;
  
  .title {
    margin: 32px 38px 89px 24px;
    font-weight: $bold;
    font-size: $fontSize-24;
    @media (max-width: $tablet) {
      margin: 32px 12px 89px 12px;
    }
  }
  .linksContainer {
    .navigationItem {
      margin-bottom: 15px;
      .navigationLinkContainer {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        width: 100%;
        margin-left: 10px;
        padding-right: 70px;
        cursor: pointer;
        a + span {
          margin-top: 5px;
        }
        &.active {
          border-right: 2px solid $pineGreen;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    width: 150px;
  }

  @media (min-width: $greaterThanTablet) and (max-width: $tabletSurface) {
    width: 180px;
  }

}

.handSymbol{
  cursor: pointer;
}
