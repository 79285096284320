@import '~@Styles/vars';

.WillAdminForm {
  .separator {
    width: 100%;
    height: 2px;
    background-color: $dividerColor;
  }

  .WillAdminFormFieldWrapper {
    margin-bottom: 20px;
    div {
      span {
        font-weight: $normal;
      }
    }
  }

  .WillAdminFormEmailFieldWrapper {
  
    div {
      span {
        font-weight: $normal;
      }
    }
  }

  .bottomSpacing {
    margin-bottom: 10px;
  }

  .biggerBottomSpacing {
    margin-bottom: 20px;
  }

  .cautionBox {
    display: none;
    margin-bottom: 20px;
    background: $adminBgColor;
    border: 1px solid $electricBlue;
  }
}

.notesContainer {
  padding-bottom: 10px;
  color: $footerColor;
  font-weight: $fontStyle;
  font-size: $fontSize-14;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 164%;
  letter-spacing: 0.045em;

  @media (max-width: $tablet) {
    padding-top: 0;
  }
}

.bottomSpacing20 {
  margin-bottom: 20px;
}

.mt18{
  margin-top: 18px;
}
