@import '~@Styles/vars';

.headerLayout {
  position: fixed;
  z-index: $z-index1;
  width: 100%;
  padding: 10px 0 10px 0;
  padding: 15px 0 12px 40px;
  background: $menuBackgrounndColor;
}

.mainContentLayout {
  width: 100%;
  background: $fileUploadBackground;
}

.infoContent {
  padding-top: 130px;
  @media (max-width: $tablet) {
    padding-left: 34px;
  }
  @media (max-width: $mobile) {
    padding: 80px 16px 0 16px;
  }
}

.closeFreeze {
  margin-bottom: 24px;
  font-size: $fontSize-24;
  line-height: 35px;

  @media (max-width: $tablet) {
    font-size: $fontSize-20;
  }
}

.tickIcon {
  flex-shrink: 0;
}

.points{
  gap: 18px;
  margin-bottom: 16px;
  @media (max-width: $mobile) {
    margin-top: 32px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.greenEllipsis {
  position: absolute;
  top: 57px;
  left: 761px;
  width: 603px;
  height: 603px;  
  background-color: $ellipsisColor;
  border-radius: $border-radius-round;
}

.gapBetween {
  position: relative;
  gap: 20px;
}

.mb40 {
  @media (max-width: $tablet) {
    margin-bottom: 100px;
  }
}

.pb100 {
  @media (max-width: $tablet) {
    padding-bottom: 103px;
  }
}
