@import '~@Styles/vars';

.addCompanyBlock {
  margin-top:30px;
  margin-bottom:30px;
  padding: 40px 20px 40px 20px;
  border: 3px dashed $footerColor;
  border-radius: $border-radius-toggle;
}

.smallTopSpacing {
  margin-top: 10px;
}

.bigTopSpacing {
  margin-top: 50px;
}

.messageInfo {
  margin-right: 8px;
}

.leftSpacing{
  margin-left: 50px;
}

.leftPermissionSpacing{
  margin-left: 13px;
}