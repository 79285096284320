
@import '~@Styles/vars';
@import '~@Styles/mixins';

.notesBottomSpacing {
  margin-bottom: 12px;
}

.pageWidth {
  width:80%;
  @media (max-width: $tablet) {
    width: unset;
  }
}

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.bar{
  width: 8px;
  margin-left: 6px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius;
}

.flexGap {
  display: flex;
  gap: 16px;
}

.rightDiv {
  flex-grow: 1;
}