@import '~@Styles/vars';
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  list-style: none;
  cursor: pointer;
  li{
    padding: 3px;
  }
  a {
    padding: 9px 16px;
    font-weight: $bold;
    font-size: $fontSize-12;
    background: $menuBackgrounndColor;
    border-radius: $border-radius;
    outline:none;
  }
}
  
.pagination__link__active 
    {
  a {
    color: $white;
    background: $profRegisterBackground;
    border: none;
  }
}
    
.pagination__link__disabled 
    {
  a {
    color: $gray;
  }
}
  
.cardsPagination{
  margin-top: 75px;
}
  
.previousButton {
  background: $white !important;
  path {
    fill : $footerColor;
  }
}
  
.navigationIconsPlacement {
  margin-top: 7px;
}