@import '~@Styles/vars';
@import '~@Styles/mixins';

$modal-shadow: 0 14px 24px rgba($black, 0.1), 0 0 14px rgba($black, 0.1);
$backdrop-color: rgba(0, 0, 0, 0.2);

.container {
  position: fixed;
  z-index: $zindex-modal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  animation: fadeIn 0.35s;

  .modalPaper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-height: 95vh;
    padding: 14px 24px;
    box-shadow: $modal-shadow;
    &.fixedFormHeight {
      min-height: 650px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 7px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $scrollColor;
        border-radius: $thumb-radius;
        -webkit-box-shadow: $scrollShadow;
      }

      @media (max-width: $mobile) {
        min-height: unset;
        overflow: scroll;
      }
    }
    svg {
      cursor: pointer;
    }

    .closeIcon {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: $z-index1;
    }

    .paymentModal {
      margin-top: 20px;
      text-align: center;
    }

    .header {
      margin-top: 20px;
    }

    @media (max-width: $tablet) {
      // align-self: flex-end;
      margin: 8px;
      padding: 15px 25px;
    }

    @media (max-width: $mobile) {
      padding: 15px 8px;
    }
  }

  .modalNoPaddingPaper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-height: 95vh;
    box-shadow: $modal-shadow;
    &.fixedFormHeight {
      height: 392px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 7px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $scrollColor;
        border-radius: $thumb-radius;
        -webkit-box-shadow: $scrollShadow;
      }

      @media (max-width: $mobile) {
        min-height: unset;
        overflow: scroll;
      }
    }
    svg {
      cursor: pointer;
    }

    .closeIcon {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: $z-index1;
    }

    .paymentModal {
      margin-top: 20px;
      text-align: center;
    }

    .header {
      margin-top: 20px;
    }
    @media (max-width: $tablet) {
      margin: 10px;
    }

    @media (max-width: $mobile) {
      height: 100%;
      max-height: unset;
      margin: unset;
      padding: unset;
      border-radius: $unset !important;
    }
  }

  .modalFullScreenPaper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-height: 95vh;
    box-shadow: $modal-shadow;
    &.fixedFormHeight {
      height: 392px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 7px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $scrollColor;
        border-radius: $thumb-radius;
        -webkit-box-shadow: $scrollShadow;
      }

      @media (max-width: $mobile) {
        min-height: unset;
        overflow: auto;
      }
    }
    svg {
      cursor: pointer;
    }

    .closeIcon {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: $z-index3;
    }

    .paymentModal {
      margin-top: 20px;
      text-align: center;
    }

    .header {
      margin-top: 20px;
    }
    @media (max-width: $tablet) {
      margin: 10px;
      //padding: 15px 25px;
    }

    @media (max-width: $mobile) {
      height: 100%;
      max-height: unset;
      margin: unset;
      overflow-y: hidden;
      border-radius: $unset !important;
    }
  }

  .body {
    position: relative;
    margin-top: 3px;
    .title {
      font-weight: $bold;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .notificationBody {
    position: relative;
    .title {
      font-weight: $bold;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .paymentBody {
    position: relative;
    justify-content: center;
    max-height: 95vh;
    .title {
      font-weight: $bold;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .backdrop {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $z-index-1;
    width: 100vw;
    height: 100vh;
    background-color: $backdrop-color;
    transform: translate3d(-50%, -50%, 0);
  }

  @media (max-width: $mobile) {
    overflow-y: hidden;
  }
}

.scrollBar{
  ::-webkit-scrollbar {
    width: 8px;
    height: 9px;
    -webkit-appearance: none;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: $gray-light;
    border-radius: $border-radius-18;
    -webkit-box-shadow: $scrollShadow;
  }
}

.bottomSpacing {
  margin-bottom: 10px;
  @media (max-width: $mobile) {
    margin-top: 20px;
  }
}

.grayPaper {
  background: $menuBackgrounndColor;
}

.notificationCloseIcon {
  z-index: $z-index1;
  path {
    fill: $buttonTextColor;
  }
}

.mobileCloseIcon {
  position: fixed;
  right: 20px;
  z-index: $z-index3;
  margin-top: 1rem;
  path {
    fill: $buttonTextColor;
  }
}
.divWidth {
  width: 95%;
}

.notVisible {
  display: none;
}

.emptyDiv {
  outline: none !important;
}
