@import '~@Styles/vars';
@import '~@Styles/mixins';

.radioButtonContainer {
  overflow: hidden;
  @media (max-width: $mobile) {
    margin: 5px 0;
  }
}
  
.actions {
  display: flex;
  flex: 1;
  margin-top: 20px;
  
  &.column {
    flex-direction: column;
  }
  @media (max-width: $mobile) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}