@import '~@Styles/vars';
@import '~@Styles/mixins';

.formContainer {
  flex-basis: 100%;
  width: 100%;
}

.actions {
  display: flex;
  flex: 1;
  margin-top: 40px;
  button {
    &:first-child {
      margin-right: 10px;
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }
  }
  
  &.column {
    flex-direction: column;
  }
}

.bottomSpacing{
  margin-bottom: 10px;
}

.providerSubtitle {
  margin-top: 10px;
  line-height: 1.5;
}  

.input {
  width: 100%;
  max-height: $input-control-height;
  padding: $input-padding;
  font-size: $fontSize-18;
  font-family: inherit;
  border: $input-border;
  border-radius: $border-radius;
  filter: none;
  transition: border 0.2s ease;
  caret-color: $green;

  &::placeholder {
    font-size: $fontSize-16;
    font-family: $helveticaFamily;
    opacity: 40%;
  }

  &:placeholder-shown + .label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
  }

  &:focus {
    border: 1px solid $electricBlue;
    outline: none;

    & + .label::before {
      color: $pineGreen;
      transform: translate3d(0, -55px, 0) scale3d(0.82, 0.82, 1);
    }
  }
  @include textOverflow();
}

.shiftTextBox {
  padding-right: 38px;
}