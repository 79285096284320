@import '~@Styles/vars';

.legalForm {
  margin-top:13px;
  .separator {
    width: 100%;
    height: 2px;
    background-color: $dividerColor;
  }

  .bottomSpacing {
    margin-bottom: 10px;
  }

  .biggerBottomSpacing {
    margin-bottom: 20px;
  }
}

.notesContainer {
  padding-bottom: 10px;
  font-size: $fontSize-16;
  line-height: 1.2;

  @media (max-width: $tablet) {
    padding-top: 0;
  }
}

.pageWidth {
  width:84%;
  @media (max-width: $tablet) {
    width: unset;
  }
}

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}

.indentFlex {
  display: flex;
  gap:16px;
}

.indentBar {
  width: 8px;
  margin-left: 6px;
  background: $menuBackgrounndColor;
  border-radius: $border-radius;
}

.fullWidth {
  width: 100%;
}
 