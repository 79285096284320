@import '~@Styles/vars';

.cautionBox {
  margin-top: 7px;
  margin-bottom: 17px;
  background: $inProgessBackgroundColor;
  border: 1px solid $yellowishOrange;
}

.topSpacing {
  margin-top: 10px;
}

.topSpacing15{
  margin-top: 15px;
}

.mt54{
  margin-top: 54px;
}

.width{
  width: 100%;
  max-width: 603px;
}

.mt135{
  margin-bottom: 135px;
}