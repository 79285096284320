@import '~@Styles/vars';

.container {
  margin-top: 16px;
  padding: 24px;
  background: $white;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
}

.personContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: $violet-light; 
  border-radius: $border-radius-round;
  opacity: 0.7;
}

.opace {
  opacity: 0.7;
}

.gap24{
  gap:24px;
}

.ml3{
  margin-left: 1.3rem;
}

.mt13{
  margin-top: 13px;
}

.mt70 {
  margin-top: 70px;
}

.mt8{
  margin-top: 6px;
  cursor: pointer;
}