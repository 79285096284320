@import '~@Styles/vars';

.notesContainer {
  padding-top: 10px;
  color: $footerColor;
  font-weight: $fontStyle;
  font-size: $fontSize-14;
  font-family: $AssistantFamily;
  font-style: $fontStyle;
  line-height: 164%;
  letter-spacing: 0.045em;

  @media (max-width: $tablet) {
    padding-top: 0;
  }
}

.topSpacing {
  margin-top: 54px;
}


.tipContainer{
  gap: 8px;
  width: 77%;
  background:$adminBgColor;
  border: 1px solid $electricBlue;
  border-radius: $border-radius-toggle;
  
  @media (max-width: $tablet) {
    width: unset !important;
  }
}

.iconSpacing{
  margin-right: 8px;
  path {
    fill: $buttonTextColor;
  }
}

.leftSide {
  padding-top: 16px;
  padding-left:16px;
}

.rightSide {
  padding-top: 9px;
  padding-right: 40px;
  padding-bottom: 16px;
  line-height: 19.68px;
}

.tipBoxSpacing {
  gap:12px;
}

.tipContentSpacing {
  line-height: 22.96px;
}

.handSymbol {
  cursor: pointer;
}

.titleOtherSpacing {
  position: relative;
  display:flex;
  flex-direction: row;
  width: 40.3%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.titleOtherSpacing:before {
  
  width:8px;
  background: #F3F3F3;
  border: 2px ;
  content: "";
}

.nickNameLink {
  gap: 13px;
  cursor: pointer;
}

.knownNameSpacing {
  position: relative;
  flex-direction: row;
  width: 40.3%;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: $tablet) {
    width: unset;
  }
}

.knownNameSpacing:before {
  width:8px;
  margin-right: 16px;
  background: #F3F3F3;
  border: 2px ;
  content: "";
}

.lastNameRow {
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: $desktop-1323) {
    flex-direction: column;
  }
}

.lastNameField {
  width: 40.25%;
  @media (max-width: $tablet) {
    width: unset;
  }
}