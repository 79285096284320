@import '~@Styles/vars';

.statusLayout {
  gap: 21px;
  margin-top: 32px;
  margin-bottom: 20px;
  padding: 24px;
  border-radius: $border-radius-toggle;
}

.titleLayout {
  font-weight: $bold;
  font-size: $fontSize-18;
}

.topSpacing {
  margin-top: 20px;
}

.staticHeader {
  position: sticky;
  top: 0;
  z-index: $z-index2;
}

.iconSpacing {
  margin-top: 4px;
  margin-right: 8px;
  path {
    fill: $footerColor;
  }
}

.logoWrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-top: 4px;
  margin-right: 6px;
  border-radius: $border-radius-round;
  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-round;
  }
}

.logoWrapper1 {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  border-radius: $border-radius-round;
  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-round;
  }
  @media (max-width: $mobile) {
    margin-top: 10px;
  }
}

.leftSpacing {
  margin-left: 30px;
}

.tabSpacing {
  margin-top: 40px;
}

.bottomSpacing {
  margin-bottom: 40px;
}

.noAccountFoundInfo {
  margin: 0;
  padding: 0;
  font-size: $fontSize-16;
}

.messagesTab {
  text-decoration: underline;
  cursor: pointer;
}

.headerSection {
  padding: 48px 82px 1px 32px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding: 48px 8px 0 8px;
  }
}

.headerSection1 {
  padding: 4px 82px 1px 32px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  @media (max-width: $mobile) {
    padding: 4px 8px 0 8px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  z-index: $z-index1;
}

.bodyContainer {
  padding: 32px 82px 40px 32px;
  @media (max-width: $tablet) {
    padding: 32px 60px 40px 8px;
  }
  @media (max-width: $mobile) {
    margin-top: 32px;
    padding: 0 8px 40px 8px;
  }
}

.gdSpacing {
  margin-bottom: 12px;
}

.flexBox {
  display: flex;
  flex-shrink: 0;
  gap: 12px;
}

.mb8 {
  margin-bottom: 8px;
}

.flexGap {
  gap: 16px;
}

.mobileHeaderSection {
  position: fixed;
  z-index: $z-index1;
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  max-width: 1016px;
  padding: 12px 8px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  border-top-left-radius: $border-radius-toggle;
  border-top-right-radius: $border-radius-toggle;
  transition: all 1s;
  @media (max-width: $mobile) {
    top: 0;
  }
}

.initialLogoContainer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin-right: 8px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-36;
  border-radius: $border-radius-bigger;
}

.miniLogoWrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 24px;
  border-radius: $border-radius-round;
  img {
    max-width: 45%;
    max-height: 50%;
    border-radius: $border-radius-round;
  }
}

.miniInitialLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 24px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-14;
  border-radius: $border-radius-bigger;
}

.breakWord {
  white-space: break-spaces;
}

.handSymbol {
  cursor: pointer;
}

.titleLogo {
  align-items: center;
  @media (max-width: $tablet-700) {
    align-items: flex-start;
  }
}

.lh2 {
  line-height: 2.2rem;
}

.icon {
  display: inline-block;

  &.spin {
    :global {
      animation: spin 1.5s linear infinite;
    }
  }
}

.infoBox {
  gap: 8px;
  padding: 16px 40px 16px 16px;
  background: $adminBgColor;
  border: 1px solid $electricBlue;
  border-radius: $border-radius-toggle;
}
  
.infoIcon {
  margin-top: 2px;
  margin-right: 10px;
  path {
    fill: $buttonTextColor;
  }
}
.fs14 {
  margin: 0;
  padding: 0;
  font-size: $fontSize-14;
}
p
.title {
  margin-left: 50px;
}
.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}