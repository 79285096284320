@import '~@Styles/vars';

.buttonSpacing {
  margin-top: 13px;
}

.bottomSpacing{
  margin-bottom: 24px;
}

.gap24{
  gap: 24px;
}

.searchButtonContainer {
  margin-bottom: 24px;
  .searchButton {
    background: white;
    border: 0.2em solid black;
  }

}

.companiesheader {
  margin-bottom: 33px;
  padding: 65px 0 14px  34px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $dividerColor;
  @media (max-width: $mobile) {
    margin-bottom: unset;
    padding: 30px 8px 10px 8px;
    background: unset;
    border-bottom: unset
  }
}

.bodyContainer {
  width: 80%;
  margin-bottom: 44px;
  padding-bottom:7rem;
  padding-left: 34px;
  @media (max-width: $res653) {
    width: 90%;
  }
  @media (max-width: $mobile) {
    width: unset;
    padding-right: 8px;
    padding-left: 8px;
  }
  @media (min-width: $desktop-l) {
    max-width: 1000px;
  }
}

.footer{
  position: fixed;
  bottom:0;
  width: 100%;
  padding: 15px 24px 0 34px;
  background: $white;
  border-top: 1px solid $dividerColor;  
  @media (max-width: $mobile) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.emptyCompany {
  margin-bottom: 40px;
  padding: 27px;
  text-align: center;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
}

.mobileMenu {
  position: fixed;
  z-index: $z-index1;
  width: 100%;
  padding: 17px 20px 17px 12px;
  background: $settingsHeaderBackground;
  border-bottom: 1px solid $breakerColor;
  path{
    fill: $buttonTextColor;
  }
}

.mt54 {
  margin-top: 20px;
}

.mb4{
  margin-bottom: 8px;
}

.mobileBody {
  @media (max-width: $mobile) {
    position: absolute;
    margin-top: 5rem;
  }
}

.noCompaniesFound {
  padding: 9% 27% 9% 27%;
  text-align: center;
  background: $menuBackgrounndColor;
  border-radius: $border-radius-toggle;
  @media (max-width: $mobile) {
    padding: 5% 13% 5% 13%;
  }
}

.progressBarFont {
  margin-bottom: 4px;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.27px;
}