@import '~@Styles/vars';
@import '~@Styles/mixins';

.missingContainer {
  margin-bottom: 8px;
  padding: 14px 24px 14px 24px;
  background: $menuBackgrounndColor;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  cursor: pointer;

  &:hover  {
    background:$hoverButtonColor;
  }

  @media (max-width: $tablet) {
    padding: 14px 18px 14px 18px;
  }
}

.personContainer{
  width: 48px;
  height: 48px;
  padding:3px 1px 3px 3px;
  text-align: center;
  border-radius: $border-radius-round;
}

.activeContainer {
  margin-bottom: 8px;
  padding: 14px 24px 14px 24px;
  background: $white;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  cursor: pointer;

  &:hover  {
    background:$hoverButtonColor;
  }
  
  @media (max-width: $tablet) {
    padding: 14px 18px 14px 18px;
  }

}

.nameDetails {
  padding-left: 24px;
}

.notificationTitle {
  padding-left: 24px;
  white-space: nowrap;
}

.moreIcon {
  position: absolute;
  margin-top: 1px;

  path{
    fill: $footerColor;
  }

}

.readyToSend {
  padding-left: 21px;
  white-space: nowrap;
}

.sent {
  padding-left: 25px;
}

.deathDateSpacing{
  margin-right: 8px;
}

.viewNotificationIcon {
  margin-top: 6px;
  margin-right: 5px;
}

.actionItems {
  align-self: flex-end;
  cursor: pointer;
  @media (max-width: $tablet) {
    padding-top: 17px;
  }
}

.underline {
  color: $black;
}

.swapIcon {
  position: absolute;
  margin-top: 6px;
}

.switchCase {
  padding-left: 30px;
}

.missingInfoPanel {
  margin-left: 24px;
  padding: 3px 12px 7px 13px;
  background: $infoMissingColor;
  border-radius: $border-radius-info;
  
  .alertTriangle {
    position: absolute;
    margin-top: 7px;
  }

  @media (max-width: $tablet) {
    position: absolute;
    padding: 1px 10px 1px 5px;
  }

  @media (max-width: $mobile) {
    margin-top: 28px;
    margin-left: unset;
  }
    
}

.handSymbol {
  cursor: pointer;
}

.actionLinks {
  padding-top: 15px;
}

.missingInfoItemsSpacing {
  margin-right: 11px;
}

.missingItemsIcon {
  position: absolute;
  margin-top: 8px;
  path {
    fill: $dividerColor;
  }
}


.lifeCaseEdit {
  align-self: center;
  margin-top: 34px;
  @media (max-width: $mobile) {
    margin-top: 20px;
  }
}
.moreEllipsis {
  padding-left: 5px;
}

.moreHoriz{
  margin-top: 7px;
}

.combined{
  margin-top: 16px;
  @media (max-width: $mobile) {
    display: flex;
    margin-top: unset;
  }
}

.splitNameAndInfoPanel{
  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
  }
  @include textOverflow();
}

.mobileDelete{
  margin-top: 3px;
}

.moreDelete{
  margin-top: 3px;
  margin-left: auto;
}

.completeMissingInfonHover {
  position: absolute;
  z-index: $z-index1;
  display: flex;
  margin-top: 4.3rem;
  padding-left: 10px;
  border-radius: $border-radius;
  cursor: pointer;
  &:hover  {
    background:$hoverButtonColor;
  }
  @media (min-width: $greaterThanTablet) and (max-width: $desktop-s) {
    right:16.1vw;
  }
  
  @media (max-width: $tablet) {
    right:15.3vw;
    margin-top: 3.3rem;
  }
}

.completeMissingInfoRight{
  right:22.1vw;
  @media (min-width: $greaterThanTablet) and (max-width: $desktop-s) {
    right:16.1vw;
  }
  @media (max-width: $tablet) {
    right:15.3vw;
  }
}

.profViewRight{
  right:28.1vw;
  @media (max-width: $desktop-s) {
    right:14.7vw;
  }
}

.flexDisplay {
  display: flex;
  align-items: center;
}
.gap4{
  gap: 4px;
}
