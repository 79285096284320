@import '~@Styles/vars';
@import '~@Styles/mixins';

.missingContainer {
  margin-bottom: 8px;
  padding: 14px 24px 1px 24px;
  background: $menuBackgrounndColor;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  cursor: pointer;
  &:hover  {
    background:$hoverButtonColor;
  }
  
  @media (max-width: $tablet) {
    padding: 14px 18px 14px 18px;
  }
}

.cardHeight{
  @media (min-width: $tablet) and (max-width: $desktop-s) {
    height: $card-height;
  }
} 

.profCardHeight{
  @media (min-width: $tablet) and (max-width: $res875) {
    height: 110px;
  }
} 

.personContainer{
  width: 48px;
  height: 48px;
  padding:3px 1px 3px 3px;
  text-align: center;
  border-radius: $border-radius-round;
}

.activeContainer {
  position: relative;
  margin-bottom: 8px;
  padding: 14px 24px 14px 24px;
  background: $white;
  border: 1px solid $breakerColor;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  cursor: pointer;

  &:hover  {
    background:$hoverButtonColor;

    @media (max-width: $tablet) {
      background: unset;
    }

    @media (max-width: $mobile) {
      background: unset;
    }
  }

  @media (max-width: $tablet) {
    padding: 14px 18px 7px 18px;
  }
}

.nameDetails {
  padding-left: 24px;
}

.notificationTitle {
  padding-left: 24px;
}

.moreIcon {
  position: absolute;
  margin-top: 2px;
}

.readyToSend {
  padding-left: 21px;
}

.sent {
  padding-left: 25px;
}

.deathDateSpacing{
  margin-right: 8px;
}

.viewNotificationIcon {
  margin-top: 6px;
  margin-right: 5px;
}

.missingInfoPanel {
  margin-left: 24px;
  padding: 3px 12px 7px 13px;
  white-space: nowrap;
  background: $infoMissingColor;
  border-radius: $border-radius-info;

  .alertTriangle {
    position: absolute;
    margin-top: 7px;
  }
 
  @media (max-width: $tablet) {
    position: absolute;
    padding: 1px 10px 1px 5px;
  }

  @media (max-width: $mobile) {
    margin-top: 28px;
    margin-left: unset;
  }

}

.handSymbol {
  cursor: pointer;
}

.missingInfoItemsSpacing {
  margin-right: 11px;
}

.missingItemsIcon {
  position: absolute;
  path {
    fill: $dividerColor;
  }
}

.actionLinks {
  @media (max-width: $tablet) {
    padding-top: 34px;
  }

  @include textOverflow();
}

.notificationStatusCount{
  margin-left: 17px;
}

.missingInfoText {
  position: relative;
  bottom:7px;
  padding-left: 25px;
}

.combined{
  margin-top: 16px;
  @media (max-width: $mobile) {
    display: flex;
    margin-top: unset;
  }
}

.mobileDelete{
  margin-top: 3px;
}

.missingInfoDelete{
  margin-top: 3px;
  margin-left: auto;
}


.splitNameAndInfoPanel{
  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
  }
}

.viewNotificationHover {
  position: absolute;
  right: 10.3vw;
  z-index: $z-index1;
  display: flex;
  margin-top: 4.4rem;
  padding-left: 10px;
  border-radius: $border-radius;
  cursor: pointer;
  &:hover  {
    background:$hoverButtonColor;
  }
  @media (min-width: $greaterThanTablet) and (max-width: $desktop-s) {
    right:9.1vw;
    // margin-top: 6.8rem;
  }
  @media (min-width: $greaterThanMobile) and (max-width: $lessThanTablet) {
    right:8vw;
    // margin-top: 7.3rem;
  }
  @media (min-width: $tablet) and (max-width: $tablet) {
    right:15.3vw;
    // margin-top: 7.3rem;
  }
  @media (min-width: $res624) and (max-width: $tablet) {
    right:14vw;
  }
  // @media (min-width: $desktop-l) {
  //   right:29vw;
  // }
}

.profViewNotificationHover {
  position: absolute;
  right: 28.3vw;
  z-index: $z-index1;
  display: flex;
  margin-top: 4.4vw;
  padding-left: 10px;
  border-radius: $border-radius;
  cursor: pointer;
  &:hover  {
    background:$hoverButtonColor;
  }
  @media (max-width: $tablet) {
    right:23.3vw;
    margin-top: 7.4vw;
  }
  @media (max-width: $res875) {
    right:21.3vw;
    margin-top: 8.2vw;
  }

  @media (min-width: $res875) and (max-width: $res1118) {
    right:26.3vw;
    margin-top: 7vw;
  }
}

.mobileViewNotification {
  display: flex;
}

.completeMissingInfonHover {
  position: absolute;
  z-index: $z-index1;
  display: flex;
  margin-top: 4.3rem;
  padding-left: 10px;
  border-radius: $border-radius;
  cursor: pointer;
  &:hover  {
    background:$hoverButtonColor;
  }
  @media (max-width: $tablet) {
    margin-top: 3.3rem;
  }
}

.moreEllipsis {
  padding-left: 5px;
}

.moreHoriz{
  margin-top: 7px;
}

.moreLink {
  margin-top: 4px;
}

.noWrap {
  white-space: nowrap;
}

.completeMissingInfoRight{
  right:23.1vw;
  @media (min-width: $greaterThanTablet) and (max-width: $desktop-s) {
    right:16.1vw;
  }
  @media (max-width: $tablet) {
    right:15.3vw;
  }
}

.profViewRight{
  right:28.1vw;
  @media (max-width: $desktop-s) {
    right:14.6vw;
  }
  @media (max-width: $res875) {
    margin-top: 5rem;
  }
  @media (max-width: $tablet) {
    margin-top: 3.4rem;
  }
}

.p15 {
  padding-top: 15px;
}

.p22 {
  padding-top: 22px;
}

.gap4{
  gap: 4px;
}

.res1400Right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profMissingInfoWrapper {
  @include textOverflow();
}