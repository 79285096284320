@import '~@Styles/vars';

.breaker {
  margin-bottom: 20px;
  border: 1px solid $dividerColor;
}

.underline {
  color: $black;
  text-decoration: underline;
}

.deathDateSpacing{
  margin-right: 8px;
}
  
.viewNotificationIcon {
  margin-top: 6px;
  margin-right: 5px;
}

.bottomSpacing {
  margin-bottom: 20px;
}

.topSpacing{
  margin-top: 20px;
}

.flexDisplay {
  gap: 14px;
}