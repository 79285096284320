@import '~@Styles/vars';

.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.6em;
  min-height: 100%;
  margin-top: 55px;
  .rightSideContainer {
    @media (max-width: $tablet) {
      padding-left: unset;
    }
    @media (max-width: $mobile) {
      width: 100%;
      padding-right: 9px; 
      padding-left: 9px;
    }
    @media (max-width: $desktop-s) {
      padding-bottom: 25px;
    }
    @media (min-width: $desktop-l) {
      max-width: 1129px;
    }
  }

  .secondLeftSideContainer {
    z-index: $z-index1;
    min-width: 209px;
    @media (max-width: $tablet) {
      min-width: 160px;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: 0.3em;

    .leftSide,
    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 260px 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 164px 1fr;
  }

  @media (min-width: $greaterThanTablet) and (max-width: $tabletSurface) {
    grid-template-columns: 188px 1fr;
  }

  @media (max-width: $mobile) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
    min-height: unset;
    margin-top: unset;
  }
}

.individualLayoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100%;
  .rightSideContainer {
    @media (max-width: $tablet) {
      padding-left: unset;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
    @media (max-width: $desktop-s) {
      padding-bottom: 25px;
    }
  }

  .secondLeftSideContainer {
    z-index: $z-index1;
    min-width: 209px;
    @media (max-width: $tablet) {
      min-width: 160px;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: 0.3em;

    .leftSide,
    .rightSide {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 224px 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 144px 1fr;
  }

  @media (min-width: $greaterThanTablet) and (max-width: $tabletSurface) {
    grid-template-columns: 170px 1fr;
  }

  @media (max-width: $mobile) {
    display: unset;
    grid-template-columns: unset;
    gap: unset;
    min-height: unset;
    margin-top: unset;
  }
}
