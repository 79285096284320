@import '~@Styles/vars';
@import '~@Styles/mixins';

.logoWrapper {
  display: flex;
  align-items: center;
  width: 62px;
  height: 62px;
  margin-right: 6px;
  border-radius: $border-radius-round;
  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-round;
  }
}