@import '~@Styles/vars';
.spacing {
  margin-top: 30px;
  margin-bottom: 10px;
}

.links {
  color: $electricBlue;
  text-decoration-line: underline;
  text-underline-position: below;
}

.labelSpacing {
  margin-bottom: 5px;
}
