@import '~@Styles/vars';
@import '~@Styles/mixins';

.toggleSwitchContainer {
  position: relative;
  
  .label {
    gap: 5px;
    margin-top:15px;
    font-size: $fontSize-18;
    line-height: 25px;
    @media (max-width: $mobile) {
      white-space: pre-wrap;
    }  
  }
}

.positionRelative{
  position: relative;
}
.tooltipMaxWidth {
  width: 270px;
  white-space: pre-wrap;
}
.margin{
  margin-left: 4px;
}