@import '~@Styles/vars';

.error {
  margin: 10px 0;
  color: $red;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;

  .iconPlacing {
    // margin-top: 2px;
    margin-right:6px;
  }

  .message {
    vertical-align: top;
  }
}
