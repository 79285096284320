@import 'mixins';
/* --------------
	COLORS 🎨
---------------- */
$black: #000;
$lighterBlack: #292f44;
$lightestBlack: #474D62;
$white: #fff;
$red: #E03616;
$electricRed:#e60000;
$lightestRed: #f4e4e2;
$gray: #707070;
$gray-border: #252525;
$gray-light: #a8a8a8;
$gray-lighter: #f6f6f6;
$gray-lightest: #fbfbfb;
$orange: #e27a64;
$light-orange: #dfbc8f;
$gold: #dfbc8f;
$darkOrange: #e97258;
$yellow: #f5d680;
$blueLighter: #6c9cd4;
$greenLighter: #5db592;
$greenLightest: #6FC0AB;
$shadowColor: rgba(0, 0, 0, 0.15);
$blockAccessColor: rgba(0, 0, 0, 0.6);
$breakerColor: #C4C4C4;
$electricBlue: #2E60E0;
$violet-light: #B1C0E9;
$scrollColor: rgba(0, 0, 0, 0.5);
$inputDisabledColor: #9C9C9C;
$lightDivider: #ECEAEA;

$buttonTextColor: #252525;
$authContentColor: #F2F2F2;
$cookieBackground: rgba(242,242,242,1);
$ellipsisColor: #DCECE8;
$footerColor: #4A4A4A;
$subMenuLinkColor: #D7D7D7;
$mobileDescColor: #00000060;
$mobileLoginBoxShadowColor: rgba(0,0,0,0.25);
$mustard: #FEF8E0;
$mustard40: #FBE899;
$profRegisterBackground: #519E8A;
$inProgessBackgroundColor: #FBEFDE;
$yellowishOrange: #E49723;
$fileUploadBorder: #85BBAD;
$fileUploadBackground: #EDF5F3;
$radioButtonBackground: #E4E4E4;
$statusSuccessColor: #DCF0E7;
$statusErrorColor: #F9D7D0;
$checkboxBorder: rgba(0, 0, 0, 0.5);
$menuBackgrounndColor: #F3F3F3;
$successColor: #4FB286;
$archivedColor: #97C5B9;
$infoMissingColor: #F9DBD5;
$gray1:#6A6A6A;
$gray2:#4A4A4A;
$adminBgColor: #EAEFFC;
$buttonDisabled: #DDDDDD;
$cookieDescBackground: rgba(250,250,250,1);
$buttonBorderColor: rgba(213,213,213,1);
$gray3: #8F8F8F;
$borderGray: #C2C2C2;
$mcColor: #2C2C2E;
$limeYellow : #F1E895;
$seenGray: rgba(37, 37, 37, 0.5);
$verticalSeparatorColor: #D9D9D9;
$lightGreyishBlue:#6c757d;
$darkGreyishBlue:#343a40;
$darkLimeGreen:#495057;
$warmBlue:#6366F1;
$blueCyan:#4338CA;
$pantone:#e24c4c;
$lightGray:#e9ecef;

$missingInfoBackground: rgba(224, 54, 22, 0.1);
$summaryColor: rgba(37, 37, 37, 0.05); 

$hoverButtonColor: #E2E2E2;
$accent: #ED8E79;
$green: #4caf50;
$pineGreen: #F6C600;
$pineGreenLowerOpacity: #ffe6bc;
$blue: #1c2c82;
$transparent: transparent;
$buttonGradient: linear-gradient(to right, #EAAC55 0%, #EAAC55 100%);
$secondary-button-color: #8EA6B9;
$dividerColor: #B0B0B0;
$background-color: #f8f8f8;
$table-header-color: #e9e9ec;
$table-cell-color: #e4eaec;
$invite-pending-color: #fff2da;
$invite-pending-font-color: #ffa60c;
$dropdown-gray: #dfdfe4;
$documentElement: #dfbc8f;
$sentStatusColor: #E0E0E0;
$datePickerArrowBorderBottom: #aeaeae;
$datePickerArrowBorderBottomColor: #f0f0f0;
$datePickerBorderRightPrevColor: #ccc;
$datePickerBorderRightPrevHoverColor: #ccc;
$datePickerBorderRightPrevDisHoverColor: #e6e6e6;
$datePickerBorderNextColor: #b3b3b3;
$settingsHeaderBackground: #F9F9F9;
$mixedGray: #656565;
$autoGray: #888;
$chatFile: rgba(37, 37, 37,0.05);
$autoCompleteHover: #DFDFDF;
$userInitialsColor: #002238;
$autoCompleteHover: #DFDFDF;
$spDashBackground: #e3e4eb;
$grayOpacity: #b0b0b04d;
$softCyan: #e0f7fa;
$lightBlue: #81d4fa;
$darkTeal: #004d40;
$tealEmphasis: #00796b;


$tabMask: linear-gradient(90deg, #F9F9F9 82%, rgba(249, 249, 249, 0) 95.18%);

$circleHover: rgba(0, 0, 0, 0.2);
$fileHover: rgba(0, 0, 0, 0.3);
$disabled: rgba(239,239,239,0.3);
$midnightGrey:#444444;
$shadeGrey:#777777;
$vividGambode:#f90;
$yellowGreen:#ff0;
$mediumGreen: #008a00;
$cyanBlue: #06c;
$blueMagenta: #93f;
$oliveTone:#23241f;

$tableHeaderBackgound: #f8f9fa;

/* --------------
	GRID 📝
---------------- */

$desktop-xl: 1600px;
$desktop-l: 1400px;
$desktop-1416: 1416px;
$desktop-m: 1366px;
$desktop-1323: 1323px;
$desktop-1280: 1280px;
$desktop: 1200px;
$desktop-1181: 1181px;
$desktop-1148: 1148px;
$desktop-983: 983px;
$desktop-s: 1024px;
$tablet: 768px;
$tablet-700: 700px;
$greaterThanTablet: 769px;
$lessThanTablet: 767px;
$mobile: 576px;
$min-width: 320px;
$tabletSurface: 840px;
$greaterThanMobile: 577px;
$res653: 653px;
$res624: 612px;
$res400: 400px;
$res586: 586px;
$res608: 608px;
$res659: 659px;
$res582: 582px;
$ipadAir: 820px;
$res722: 722px;
$res986: 986px;
$res875: 875px;
$res1118: 1118px;
$res1110: 1110px;
$res940: 940px;

/* --------------
	FONTS 🖨
---------------- */
$font-family: 'Assistant';
$line-height: 1.9;
$letter-spacing: 1px;
$regularFontSize: 14px;
$fontStyle: normal;

$title-font-family: 'Palatino';
$helveticaFamily: 'Helvetica';
$AssistantFamily: 'Assistant';
$MontserratFamily: 'Montserrat';

$thin: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;

$smallLineHeight: 16px;
$lowerCaseLinkLineHeight: 140%;

//Headings
$fontSize-h1: size(48px);
$fontSize-h2: size(40px);
$fontSize-h3: size(24px);
$fontSize-h4: size(16px);
$fontSize-h5: size(14px);

//Title
$fontSize-title: size(72px);

//Body
$fontSize-1:size(0.1px);
$fontSize-10: size(10px);
$fontSize-12: size(12px);
$fontSize-13: size(13px);
$fontSize-14: size(14px);
$fontSize-15: size(15px);
$fontSize-16: size(16px);
$fontSize-17: size(17px);
$fontSize-18: size(18px);
$fontSize-19: size(19px);
$fontSize-20: size(20px);
$fontSize-21: size(21px);
$fontSize-22: size(22px);
$fontSize-24: size(24px);
$fontSize-27: size(27px);
$fontSize-30: size(30px);
$fontSize-32: size(32px);
$fontSize-34: size(34px);
$fontSize-36: size(36px);
$fontSize-40: size(40px);
$fontSize-43: size(43px);
$fontSize-48: size(48px);
$fontSize-54: size(54px);
$fontSize-85:85%;
$fontSizeFull: 100%;

//DatePicker
$no-shadow: none;
$datePickerFontSize: 21px;
$date-pickerFontSize:0.8rem;
$datePickerBorderRadius:0.3rem;
$datePickerHeader:0.944rem;
$datePickerTimeHeaderFontSize: 1.44rem;

/* --------------
	OTHER ⁉️
---------------- */
$border-radius-zero: 0;
$border-radius: 2px;
$thumb-radius: 4px;
$border-radius-bigger: 8px;
$border-radius-sub-menu: 3px;
$border-radius-5: 5px;
$border-radius-toggle: 6px;
$border-radius-10: 10px;
$border-radius-card: 12px;
$border-radius-info: 32px;
$border-radius-35: 35px;
$border-radius-hyphen: 30px;
$border-radius-43: 43px;
$border-radius-27: 27px;
$border-radius-18: 18px;
$border-radius-em: 1em;
$border-radius-round: 50%;
$border-radius-comment: 0 0 25px 25px;
$shadow-card: 0 0 24px rgba(0, 0, 0, 0.1);
$case-card: 0 4px 23px 4px rgba(0, 0, 0, 0.1); 
$default-bottom-spacing: 20px;
$button-border: 2px dashed rgba(41, 47, 68, 0.2);
$secondary-button-border: 1px solid rgba(41, 47, 68, 0.35);
$input-padding: 14px 16px 17px 16px;
$select-padding: 2px 16px 8px 13px;
$input-border: 1px solid $gray-light;
$input-border-new: 0.5px solid $gray-border;
$border-color-header : rgb(228, 234, 236);
$case-shadow:rgba(0, 0, 0, 0.1);
$newsLetter:rgba(0, 0, 0, 0.8);
$scrollShadow: 0 0 1px rgba(255,255,255,.5);
$pearl-white: #DCDCDC;

$z-index-0: 0;
$zindex-hamburger: 100;
$zindex-modal: 1000;
$zindex-table: 1001;
$z-index-1: -1;
$z-index1: 1;
$z-index2: 2;
$z-index3: 3;
$z-index10: 10;
$z-index999:99999999;
$zindex-dateInput: 100;
$footer-height: 92px;

$unset: unset;

$input-control-height: 38px;
$input-address-height: 48px;

$card-height: 106px;
$size43: 43px;

@import './variables/all.scss';
