@import '~@Styles/vars';

.topSpacing{
  margin-top: 10px;
}

.topSpacing15{
 
  margin-top: 15px;
}

.formWidth {
  width:83%;

  @media (max-width: $tablet) {
    width: 95%;
  }

  @media (max-width: $mobile) {
    width: unset;
  }
}

.margintop32{
  margin-top: 32px;
}

.wrapperBlock {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 236px;
  padding: 20px;
  text-align: center;
  background-color: $fileUploadBackground;
  border: 2px dashed $fileUploadBorder;
  
  cursor: pointer;
  &.unsetMinHeight {
    @media (max-width: $mobile) {
      min-height: unset;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
   
  @media (max-width: $tablet) {
    margin-bottom: 10px;
  }
}

.handSymbol {
  cursor: pointer;
}

.fileBottomSpacing{
  margin-bottom: 8px;
}

.cautionBox {
  background: $adminBgColor;
  border: 1px solid $electricBlue;
}